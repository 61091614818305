import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Button, Col } from "react-bootstrap";
import {  useForm } from "react-hook-form";
import Api from '../../Api';
import Swal from 'sweetalert2';
//import AsyncSelect from 'react-select/async';
import globalState from '../Global';
import "react-datetime/css/react-datetime.css";
import { DateTimePicker } from "react-tempusdominus-bootstrap";

const ModalAgendaIncluir = props => {
  const form = useRef(null);
  let cod_unidade = "";
  let pk_clinica = "";
  const [title, setTitle] = useState('vazio');
  const [pacientes, setPacientes] = useState(' ');
  const [profissionais, setProfissionais] = useState(' ');
  const [cod_paciente, setPaciente] = useState(' ');
  const [cod_profissional, setProfissional] = useState(' ');
  const [end, setEnd] = useState(props.end);
  const [start, setStart] = useState(props.start);
  const [value, onChange] = useState(new Date());

  const closeOnEscapeKeyDown = e => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: "",
      title: "",
      start: "",
      end: "",
      cod_paciente: "",
      cod_profissional: ""
    },
  });

  async function submitForm() {

    if (start === undefined) {
      setStart(props.start);
    }

    if (end === undefined) {
      setEnd(props.end);
    }
    
    var titulo = "";

    if (title === 'vazio') {
      const paciente = await Api.get('pacientes/' + cod_paciente, globalState.value);
      titulo = paciente.data.tabela.nome;
    } else {
      titulo = title;
    }


    const body = {
      "cod_paciente": cod_paciente,
      "cod_profissional": cod_profissional,
      "cod_unidade": 0,
      "pk_clinica": 1,
      "day": 0,
      "title": titulo,
      "start": start,
      "end": end,
    }

    const response = await Api.post('agendas/', body, globalState.value);
    Swal.fire({
      title: 'Atenção',
      text: "Agenda atualizada com sucesso!",
      icon: 'success',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    }).then((result) => {
      if (result.isConfirmed) {
        setTitle("vazio");
        props.onClose();
      }
    })

  }
  const style = { width: '105px' }

  const getPacientes = async () => {
    const response = await Api.get('pacientes/', globalState.value);
    setPacientes(response.data);
  };

  const getProfissionais = async () => {
    const response = await Api.get('usuarios/dentistas', globalState.value);
    setProfissionais(response.data);
  };

  async function addUser() {

    (async () => {

      const { value: formValues } = await Swal.fire({
        width: 530,
        title: 'Adição rápida de paciente',
        inputAttributes: {
          maxlength: 100,
          autocapitalize: 'on'
        },
        html:
          '<input type="text" id="nome" className="swal2-input" style="width: 400px;" placeholder="Nome"><br>' +
          '<input type="text" id="telefone" className="swal2-input" style="width: 400px;"  placeholder="Telefone">',
        focusConfirm: false,
        preConfirm: () => {
          return [
            document.getElementById('nome').value,
            document.getElementById('telefone').value
          ]
        }
      })
      var nome = formValues[0];
      var telefone = formValues[1];

      const body = {
          "nome": nome,
          "sexo": "",
          "cpf": "",
          "documento": "",
          "endereco": "",
          "complemento": "",
          "bairro": "",
          "cidade": "",
          "telefone1": telefone,
          "telefone2": "",
          "celular": "",
          "foto": "/uploads/nobody.png",
          "pk_clinica": 1,
          "email": "",
          "facebook": "",
          "instagram": "",
          "data_nasc": "",
          "cep": "",
          "profissao": ""
      }

      const response = await Api.post('pacientes', body, globalState.value);
      getPacientes();

    })()

  }


  useEffect(() => {
    getPacientes();
    getProfissionais();
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  return ReactDOM.createPortal(
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div className="modal" onClick={props.onClose}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <div className="modal-header">
            <h4 className="modal-title">Inserir evento</h4>
            <button type="button" className="btn-close" aria-label="Close" onClick={props.onClose}></button>
          </div>
          <div className="modal-body">

            <form ref={form} noValidate onSubmit={handleSubmit(submitForm)}>
              {/* <Form.Group as={Col} md="20">
                <Form.Label>Id</Form.Label>
                <Form.Control
                  type="text"
                  id="id"
                  name="id"
                  placeholder="id"
                  defaultValue={props.item?.id}
                  onChange={(e) => id = e.target.value}
                />
              </Form.Group> */}
              <br></br>
              <Form.Group as={Col} md="20">
                <Form.Label>Titulo</Form.Label>
                <Form.Control
                  defaultValue=""
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Deixe em branco para nome do paciente"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>
              <br></br>
              <Form.Label>Paciente  </Form.Label>
              <Form.Group as={Col} md="20">
                <div className="input-group mb-3 ">
                  <Form.Select onChange={(e) => setPaciente(e.target.value)}>
                    <option>Selecione um paciente</option>
                    {
                      pacientes.dado?.map(person => <option key={person.id} value={person.id}>{person.nome}</option>)
                    }

                  </Form.Select>
                  <span className="input-group-append">
                    <button type="button" className="btn btn-info btn-flat" onClick={addUser}><i className="nav-icon fas  fa-bolt" /></button>
                  </span>
                </div>
              </Form.Group>
              <Form.Group as={Col} md="20">
                <Form.Label>Profissional</Form.Label>
                <Form.Select onChange={(e) => setProfissional(e.target.value)}>
                  <option>Selecione um profissional</option>
                  {
                    profissionais.usuarios?.map(prof => <option key={prof.id} value={prof.id}>{prof.first_name}</option>)
                  }
                </Form.Select>
              </Form.Group>
              <br></br>
              <Form.Group as={Col} md="20">
                <Form.Label>Inicio</Form.Label>
                <DateTimePicker
                  format="DD/MM/YYYY HH:mm:ss"
                  locale="pt-br"
                  //date={end}
                  defaultDate={props.start}
                  onChange={e => setStart(e.date.format("YYYY-MM-DD HH:mm:ss"))}
                  icons={{
                    date: "fa fa-birthday-cake",
                    time: "fa fa-hourglass-half",
                    up: "fa fa-chevron-circle-up",
                    down: "fa fa-chevron-circle-down",
                  }} />
                {/* <Form.Control
                  type="text"
                  id="start"
                  name="start"
                  placeholder="start"
                  defaultValue={props.start}
                  onChange={(e) => start = e.target.value}
                /> */}
              </Form.Group>
              <br></br>
              <Form.Group as={Col} md="20">
                <Form.Label>Fim</Form.Label>
                {/* <Form.Control
                  type="text"
                  id="end"
                  name="end"
                  placeholder="end"
                  defaultValue={props.end}
                  onChange={(e) => end = e.target.value}
                /> */}
                <DateTimePicker
                  format="DD/MM/YYYY HH:mm:ss"
                  locale="pt-br"
                  //date={end}
                  defaultDate={props.end}
                  onChange={e => setEnd(e.date.format("YYYY-MM-DD HH:mm:ss"))}
                  icons={{
                    date: "fa fa-birthday-cake",
                    time: "fa fa-hourglass-half",
                    up: "fa fa-chevron-circle-up",
                    down: "fa fa-chevron-circle-down",
                  }} />
              </Form.Group>
              <br></br>

              <div className="text-right">
                <Button style={style} variant="primary" type="submit" size="sm">
                  <i className="fas fa-check"> Salvar</i>
                </Button>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            {/* <button onClick={props.onClose} className="button">
              Close1
            </button> */}

          </div>
        </div>
      </div >
    </CSSTransition >,
    document.getElementById("root")
  );
};

export default ModalAgendaIncluir;