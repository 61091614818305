import Header from './Components/Header';
import Footer from './Components/Footer';
import Sidenav from './Components/Sidenav';
import { useNavigate } from 'react-router-dom';
import ModalNovoUsuario from "./Components/Modals/ModalNovoUsuario";
import ModalEditarUsuario from "./Components/Modals/ModalEditarUsuario";
import Api from './Api'
import EditButton from '@mui/icons-material/Edit';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import KeyIcon from '@mui/icons-material/Key';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import PlusIcon from '@mui/icons-material/Add';
import Swal from 'sweetalert2';
import { DataGrid, GridToolbar, GridColDef, GridActionsCellItem } from '@mui/x-data-grid';
import globalState from './Components/Global';

export default function Pacientes() {

    const navigate = useNavigate();
    const [item, setItem] = useState([]);
    const [showMModalNovoUsuario, setShowModalNovoUsuario] = useState(false);
    const [showMModalEditarUsuario, setShowModalEditarUsuario] = useState(false);

    const getItems = async () => {
        const response = await Api.get('usuarios', globalState.value);
        setPacientes(response.data);
    };


    useEffect(() => {
        getItems()
    }, []);

    const handleEditarUsuario = (id) => async () => {
        var response1 = await Api.get('usuarios/' + id, globalState.value);
        setItem(response1.data.tabela[0]);
        setShowModalEditarUsuario(true);
    }

    const changePassword = (id) => async () => {
        // var login = localStorage.getItem('login');
        // var token = JSON.parse(login);
        // var header = {
        //     'headers': {
        //         'Authorization': 'Bearer ' + token.access_token,
        //         'Content-Type': 'application/json'
        //     }
        // }

            (async () => {

                // const Sucesso = Swal.mixin({
                //     toast: true,
                //     position: 'center',
                //     iconColor: 'green',
                //     customClass: {
                //         popup: 'colored-toast'
                //     },
                //     showConfirmButton: false,
                //     timer: 2500,
                //     timerProgressBar: true
                // });

                const Falha = Swal.mixin({
                    toast: true,
                    position: 'center',
                    iconColor: 'falha',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    showConfirmButton: false,
                    timer: 2500,
                    timerProgressBar: true
                });
                const { value: formValues } = await Swal.fire({
                    title: 'Trocar a senha',
                    html:
                        '<input type="password" id="senha1" className="swal2-input" placeholder="senha"><br>' +
                        '<br>' +
                        '<input type="password" id="senha2" className="swal2-input" placeholder="confirmação">',
                    focusConfirm: false,
                    preConfirm: () => {
                        return [
                            document.getElementById('senha1').value,
                            document.getElementById('senha2').value
                        ]
                    }
                })

                if (formValues) {
                    var senha1 = formValues[0];
                    var senha2 = formValues[1];

                    if (senha1 !== "") {

                        if (senha1 === senha2) {
                            try {
                                var block = {
                                    "password": senha1
                                }

                                var response = await Api.put('usuarios/password/' + id, block, globalState.value);

                                if (response.status === 200) {
                                    await Sucesso.fire({
                                        icon: 'success',
                                        title: 'Senha atualizada'
                                    })
                                }
                            } catch (e) {
                                await Falha.fire({
                                    icon: 'error',
                                    title: 'Senha não foi alterada'
                                })
                            }
                        } else {
                            await Falha.fire({
                                icon: 'error',
                                title: 'Senhas digitadas são diferentes!!!'
                            })
                        }
                    }
                }



            })()

    }

    const Sucesso = Swal.mixin({
        toast: true,
        // position: 'center',
        iconColor: 'green',
        customClass: {
            popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true
    });

    const handleDeleteClick = (id) => () => {
        Swal.fire({
            title: 'Deseja excluir usuário ?',
            text: "",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.isConfirmed) {
                Api.delete('usuarios/' + id,  globalState.value);
                Sucesso.fire({
                    icon: 'success',
                    title: 'Usuário excluído'
                });
            }
            navigate(0);
        })
    };


    const [pacientes, setPacientes] = useState([]);

    const handleRowClick = (row) => {
        //navigate('/paciente', { state: { id: row.id, key: row.id } });
    }

    const columns: GridColDef[] = [
            { field: 'id', headerName: 'ID', width: 50, editable: false},
            { field: 'first_name', headerName: 'Nome', width: 100, editable: false },
            { field: 'last_name', headerName: 'Sobrenome', width: 150, editable: false },
            {
                field: 'cpf',
                headerName: 'cpf',
                width: 200,
                type: 'number',
                editable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: 'cro',
                headerName: 'cro',
                width: 100,
                type: 'number',
                editable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: 'email',
                headerName: 'email',
                width: 250,
                type: 'number',
                editable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: 'role',
                headerName: 'Perfil',
                width: 250,
                type: 'number',
                editable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: 'actions1', type: 'actions', cellClassName: 'actions', headerName: 'Opções', width: 100, renderCell: (params) => {
                    return [
                        <GridActionsCellItem
                            key={`delete-${params.row.id}`} 
                            icon={<DeleteIcon color="warning" />}
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleDeleteClick(params.row.id)}
                        />,
                        <GridActionsCellItem
                            key={`change-${params.row.id}`} 
                            icon={<KeyIcon color="error" />}
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={changePassword(params.row.id)}
                        />,
                        <GridActionsCellItem
                            key={`edit-${params.row.id}`} 
                            icon={<EditButton color="primary" />}
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleEditarUsuario(params.row.id)}
                        />

                    ];
                }
            },
        ]


    return (

        <div>
            <Header />
            <Sidenav />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-1">
                            <div className="col-sm-8">
                                <h1 className="m-0">Pacientes</h1>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        p: 1,
                    }}
                >

                    <Button variant="outlined" size="small" onClick={() => setShowModalNovoUsuario(true)} startIcon={<PlusIcon />}>
                        Novo
                    </Button>
                </Box>
                <section className="content">

                    <div style={{ height: '700px', width: '100%' }}>
                        {pacientes.usuarios !== undefined &&
                            <DataGrid
                                pageSize={100} // Valor do tamanho da página
                                pageSizeOptions={[5, 10, 20, 50, 100]} // Inclua 100 aqui
                                rowHeight={25}
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: {
                                            // Hide columns status and traderName, the other columns will remain visible
                                            status: false,
                                            traderName: false,
                                        }
                                    },
                                }}
                                localeText={globalState.ptBRLocaleText}
                                rows={pacientes?.usuarios}
                                // slots={{
                                //     toolbar: GridToolbar, EditToolbar,
                                // }}
                                onRowClick={handleRowClick}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: false,
                                        quickFilterProps: { debounceMs: 500 },
                                        printOptions: {
                                            hideFooter: true,
                                            hideToolbar: false,
                                        }
                                    }
                                }}
                                columns={columns}
                                slots={{ toolbar: GridToolbar }}
                                //pageSizeOptions={[21]}

                            />
                        }
                    </div>
                </section>
            </div>
            {/* <ModalNovoPaciente title="Novo paciente" onClose={() => { setShowModalNovoPaciente(false); navigate(0); }} show={showMModalNovoPaciente} /> */}
            <ModalNovoUsuario title="Novo paciente" onClose={() => { setShowModalNovoUsuario(false); navigate(0); }} show={showMModalNovoUsuario} />
            <ModalEditarUsuario title="Novo paciente" onClose={() => { setShowModalEditarUsuario(false); navigate(0); }} show={showMModalEditarUsuario} item={item} />

            <Footer />
        </div>
    )

}