import Form from "react-jsonschema-form-bs4";
import { Button } from "react-bootstrap";
import Api from '../../Api';
import globalState from '../Global';

// eslint-disable-next-line react/prop-types

const FormClinica = props => {
  const addData = async (formData, props) => {
    try {
      const response = await Api.post('clinicas', formData, globalState.value);

      if (response.status === 200) {
        props.Sucesso.fire({
          icon: 'success',
          title: 'Registro foi incluído!!!'
        });
        // props.msgReturn();
      }
      if (response.status === 201) {
        props.Falha.fire({
          icon: 'warning',
          title: 'Registro já existe!!!'
        });
        // props.msgReturn();
      }

    } catch (e) {
      if (e.response && e.response.status === 401) {
        console.error("Erro 401 - Não autorizado");
        props.Falha.fire({
          icon: 'error',
          title: 'Erro 401!',
          text: 'Dado já existe'
        });
      } else {
        console.error(e);
      }
    }
    props.closeModal();
  }

  const schema = {
    // title: "Inserir cidades",
    type: "object",
    properties: {
      nome: {
        type: "string",
        default: "",
        title: "Nome",
        minLength: 1
      },
      cnpj: {
        type: "string",
        default: "",
        title: "CNPJ"
      }
    }
  };

  const uiSchema = {
    "ui:rootFieldId": "formOne"
  };
  const style = { width: '100px' }
  const formData = {};

  //const log = type => console.log.bind(console, type);
  const onSubmit = ({ formData }) => addData(formData, props);
  const onError = errors => console.log("I have", errors.length, "errors to fix");


  return (
    <Form
      schema={schema}
      uiSchema={uiSchema}
      formData={formData}
      onSubmit={onSubmit}
      onError={onError}
    >
      <div>
        <div className="text-right">
          <Button style={style} variant="danger" onClick={props.closeModal} size="sm">
            <i className="fas fa-ban"></i> Cancelar
          </Button>
          <Button style={style} variant="primary" type="submit" size="sm">
            <i className="fas fa-check"></i> Salvar
          </Button>
        </div>

      </div>
    </Form>
  );
};

export default FormClinica;

  