import React, { useState, useEffect, useRef } from 'react';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Sidenav from './Components/Sidenav';
import { useLocation } from "react-router-dom";
import Api from './Api';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';
import Table from './Components/Tables/Table';
import TableTratamentos from './Components/Tables/TableTratamentos';
import TableAgenda from './Components/Tables/TableAgenda';
import TableArquivos from './Components/Tables/TableArquivos';
import TableProntuarios from './Components/Tables/TableProntuarios';
import TableFinanceiro from './Components/Tables/TableFinanceiro';
import Modal2 from "./Components/Modals/Modal2";
import ModalProntuario from "./Components/Modals/ModalProntuario";
import ModalPlanoPagamento from "./Components/Modals/ModalPlanoPagamento";
import ModalAgenda from "./Components/Modals/ModalAgenda";
import ModalItems from "./Components/Modals/ModalItems.js";
import ModalItemsTratamentos from "./Components/Modals/ModalItemsTratamentos.js";
import Dashboard from './Components/Paciente/Dashboard';
import Basico from './Components/Paciente/Basico';
import Contatos from './Components/Paciente/Contatos';
import Midias from './Components/Paciente/Midias';
import globalState from './Components/Global';

export default function Paciente() {

    const ref_prontuarios = useRef();
    const ref_financeiros = useRef();
    const navigate = useNavigate();
    const [tabela, setTabela] = useState(" ");

    async function editUser() {
        const response = await Api.put('pacientes/' + paciente.id, paciente, globalState.value);

        if (response.status === 200) {
            await Sucesso.fire({
                icon: 'success',
                title: 'Usuário atualizado'
            });
            
        } else {
            await Falha.fire({
                icon: 'error',
                title: 'Usuário não atualizado'
            });
        }
    }

    async function showModal() {
        setShowModalNovoOrc(true);
    }

    async function delUser(id) {
        (async () => {
            const { value: text } = await Swal.fire({
                title: 'Excluir usuário?',
                icon: 'question',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Sim',
                denyButtonText: 'Não',
                customClass: {
                    actions: 'my-actions',
                    cancelButton: 'order-1 right-gap',
                    confirmButton: 'order-2',
                    denyButton: 'order-3',
                }
            }).then((result) => {
                if (result.isConfirmed) {

                    Api.delete('pacientes/' + id, globalState.value);
                    Sucesso.fire({
                        icon: 'success',
                        title: 'Usuário foi excluído'
                    });
                    navigate("/pacientes");
                    navigate(0);

                } else if (result.isDenied) {
                    Falha.fire({
                        icon: 'error',
                        title: 'Usuário não excluído'
                    });
                }
            })
        })()
    }

    const getItemsOrcamento = async (id) => {
        const response = await Api.get('itens_orcamento/orcamento/' + id, globalState.value);
        setItemsOrcamento(response.data)
        const total = await Api.get('itens_orcamento/orcamento/total/' + id, globalState.value);
        const response2 = await Api.get('orcamentos/' + id, globalState.value);
        
        if (response2.data.tabela[0].valor_total !== total.data){
            const body = {
                'valor_total' : total.data.total
            }
            await  Api.put('orcamentos/' + id, body, globalState.value);
        }
        setTotal(total.data);
    }

    const getItemsPagamento = async (id) => {
        const response = await Api.get('plano_pagamento/tratamento/' + id, globalState.value);
        setItemsPlanoPagamento(response.data);
    }

    const updateTratamentos = async (id) => {
        getTratamentos(id);
        ref_tratamentos.current.update();
    }

    const getItemsTratamento = async (id) => {
        const response = await Api.get('procedimentos/tratamento/' + id, globalState.value);
        setItemsTratamento(response.data)
        const total = await Api.get('procedimentos/total/' + id, globalState.value);
        setTotalTratamento(total.data)
        const response2 = await Api.get('tratamentos/' + id, globalState.value);

        if (response2.data.tabela.valor_total !== total.data){
            const body = {
                'valor_total' : total.data.total,
                'faturar' : total.data.total
            }
            const response3 = await  Api.put('tratamentos/' + id, body,globalState.value);
        }
        setTotalTratamento(total.data);
    }

    // const handlesubmit = () => {
    //     form.current.reset(); //this will reset all the inputs in the form
    // }

    // function resetForm() {
    //     document.getElementById("form").reset();
    // }

    const ref_agendas = useRef();
    const ref_tratamentos = useRef();
    const ref_orcamentos = useRef();
    const [showModalNovoOrc, setShowModalNovoOrc] = useState(false);
    const [showModalEditOrc, setShowModalEditOrc] = useState(false);
    const [showModalEditTrat, setShowModalEditTrat] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModalAgenda, setShowModalAgenda] = useState(false);
    const [showModalProntuario, setShowModalProntuario] = useState(false);
    const [showModalPlanoPagamento, setShowModalPlanoPagamento] = useState(false);
    const [id, setID] = useState(false);
    const [idorc, setIDORC] = useState(false);
    const [idtrat, setIDTRAT] = useState(false);
    const [idpront, setIDPRONT] = useState(false);
    const [inputs, setInputs] = useState({});
    const [validated, setValidated] = useState(false);
    const [selected, setSelected] = useState('yes');
    const [item, setItems] = useState([]);
    const [itemsOrcamento, setItemsOrcamento] = useState([]);
    const [itemsPlanoPagamento, setItemsPlanoPagamento] = useState([]);
    const [itemsTratamento, setItemsTratamento] = useState([]);
    const [total, setTotal] = useState([]);
    const [totalTratamento, setTotalTratamento] = useState([]);
    const Sucesso = Swal.mixin({
        toast: true,
        position: 'center',
        iconColor: 'green',
        customClass: {
            popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true
    });

    const Falha = Swal.mixin({
        toast: true,
        position: 'center',
        iconColor: 'falha',
        customClass: {
            popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true
    });

    const MySwal = withReactContent(Swal);
    const form = useRef(null);

    const {
        // control,
        handleSubmit,
        // formState: {  },
    } = useForm({
    });

    const getPaciente = async (id) => {
        const response = await Api.get('pacientes/' + id,globalState.value);
        setPaciente(response.data.tabela);
    };

    const savePaciente = async (id) => {
        const response = await Api.put('pacientes/' + id, paciente,globalState.value);

        if (response.status === 200) {
            Swal.fire({
                title: 'Atenção',
                text: "Paciente atualizado com sucesso!",
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
        } else {
            Swal.fire({
                title: 'Atenção',
                text: "Agenda não foi atualizada!",
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
        }
    };

    const getOrcamentos = async (cod_paciente) => {
        const response = await Api.get('orcamentos/paciente/' + cod_paciente,globalState.value);
        setOrcamentos(response.data.tabela);
    };

    const getTratamentos = async (cod_paciente) => {
        const response = await Api.get('tratamentos/paciente/' + cod_paciente,globalState.value);
        setTratamentos(response.data.tabela);
    };

    const getAgendas = async (cod_paciente) => {
        const response = await Api.get('agendas/user/' + cod_paciente,globalState.value);
        setAgendas(response.data.tabela);
        ref_tratamentos.current.update();
    };

    const getArquivos = async (cod_paciente) => {
        const response = await Api.get('imagenstratamento/',globalState.value);
        setArquivos(response.data.tabela);
    };

    const getProntuarios = async (cod_paciente) => {
        const response = await Api.get('prontuario/items/' + cod_paciente,globalState.value);
        setProntuarios(response.data.tabela);
    };

    const [saved, setSaved] = useState(true);

    useEffect(() => {
        getPaciente(location.state.id);
        getAgendas(location.state.id);
        getOrcamentos(location.state.id);
        getTratamentos(location.state.id);
        getProntuarios(location.state.id);
    }, []);

    let location = useLocation();
    const [paciente, setPaciente] = useState([]);
    const [orcamentos, setOrcamentos] = useState([]);
    const [tratamentos, setTratamentos] = useState([]);
    const [agendas, setAgendas] = useState([]);
    const [arquivos, setArquivos] = useState([]);
    const [prontuarios, setProntuarios] = useState([]);
    const [totalTratamentos, setTotalTratamentos] = useState([]);
    const [totalOrcamentos, setTotalOrcamentos] = useState([]);

    return (

        <div>

            <Header />
            <Sidenav />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-1">
                            <div className="col-sm-8">
                                <h1 className="m-0">Paciente</h1>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <div className='row'>
                    <div className='row'>

                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-5">
                        <div className="card card-primary card-tabs">
                            <div className="card-header p-0 pt-1">
                                <ul className="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="custom-tabs-one-dashboard-tab" data-toggle="pill" href="#custom-tabs-one-dashboard" role="tab" aria-controls="custom-tabs-one-dashboard" aria-selected="true">Dashboard</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="custom-tabs-one-home-tab" data-toggle="pill" href="#custom-tabs-one-home" role="tab" aria-controls="custom-tabs-one-home" aria-selected="false">Basico</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="custom-tabs-one-profile-tab" data-toggle="pill" href="#custom-tabs-one-profile" role="tab" aria-controls="custom-tabs-one-profile" aria-selected="false">Contatos</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="custom-tabs-one-messages-tab" data-toggle="pill" href="#custom-tabs-one-messages" role="tab" aria-controls="custom-tabs-one-messages" aria-selected="false">Midias sociais</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="card-body">
                                <form noValidate onSubmit={handleSubmit()}>
                                    <div className="tab-content" id="custom-tabs-one-tabContent">
                                        <div className="tab-pane fade show active" id="custom-tabs-one-dashboard" role="tabpanel" aria-labelledby="custom-tabs-one-dashboard-tab">
                                            <div>
                                                <Dashboard paciente={paciente} tratamentos={tratamentos} orcamentos={orcamentos} />
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="custom-tabs-one-home" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
                                            <Basico paciente={paciente} tratamentos={tratamentos} delUser={delUser} setPaciente={setPaciente} savePaciente={savePaciente} />
                                        </div>
                                        <div className="tab-pane fade" id="custom-tabs-one-profile" role="tabpanel" aria-labelledby="custom-tabs-one-profile-tab">
                                            <Contatos paciente={paciente} tratamentos={tratamentos} delUser={delUser} setPaciente={setPaciente} savePaciente={savePaciente} />
                                        </div>
                                        <div className="tab-pane fade" id="custom-tabs-one-messages" role="tabpanel" aria-labelledby="custom-tabs-one-messages-tab">
                                            <Midias paciente={paciente} tratamentos={tratamentos} delUser={delUser} setPaciente={setPaciente} savePaciente={savePaciente} />
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-7">
                        <div className="card card-primary card-tabs">
                            <div className="card-header p-0 pt-1">
                                <ul className="nav nav-tabs" id="custom-tabs-two-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="custom-tabs-two-home-tab" data-toggle="pill" href="#custom-tabs-two-home" role="tab" aria-controls="custom-tabs-two-home" aria-selected="true">Orçamentos</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="custom-tabs-two-trat-tab" data-toggle="pill" href="#custom-tabs-two-trat" role="tab" aria-controls="custom-tabs-two-trat" aria-selected="false">Tratamentos</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="custom-tabs-two-fin-tab" data-toggle="pill" href="#custom-tabs-two-fin" role="tab" aria-controls="custom-tabs-two-fin" aria-selected="false">Financeiro</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="custom-tabs-two-file-tab" data-toggle="pill" href="#custom-tabs-two-file" role="tab" aria-controls="custom-tabs-two-file" aria-selected="false">Arquivos</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="custom-tabs-two-agenda-tab" data-toggle="pill" href="#custom-tabs-two-agenda" role="tab" aria-controls="custom-tabs-two-agenda" aria-selected="false">Agenda</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="custom-tabs-two-pronto-tab" data-toggle="pill" href="#custom-tabs-two-pronto" role="tab" aria-controls="custom-tabs-two-pronto" aria-selected="false">Prontuários</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="card-body">
                                <div className="tab-content" id="custom-tabs-two-tabContent">
                                    <div className="tab-pane fade show active" id="custom-tabs-two-home" role="tabpanel" aria-labelledby="custom-tabs-two-home-tab">
                                        <div className="table-responsive">
                                            <Table
                                                //                                                data={orcamentos}
                                                //                                               column={column_orcamento}
                                                setTabela="orcamentos"
                                                setShowModalEditOrc={setShowModalEditOrc}
                                                setID={setID}
                                                setIDORC={setIDORC}
                                                ref={ref_orcamentos}
                                                getOrcamentos={getOrcamentos}
                                                cod_paciente={location.state.id}
                                                total={total}
                                                setItemsOrcamento={setItemsOrcamento}
                                                getTratamentos={getTratamentos}
                                                getItemsOrcamento={getItemsOrcamento}
                                                itemsOrcamento={itemsOrcamento}
                                                updateTratamentos={updateTratamentos}
                                            />
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="custom-tabs-two-trat" role="tabpanel" aria-labelledby="custom-tabs-two-trat-tab">
                                        <div className="table-responsive">
                                            <TableTratamentos
                                                data={tratamentos}
                                                //   
                                                ref={ref_tratamentos}                                             
                                                //column={column_tratamento}
                                                getTratamentos={getTratamentos}
                                                cod_paciente={location.state.id}
                                                //setShowModal2={setShowModal2}
                                                setShowModalEditTrat={setShowModalEditTrat}
                                                setIDTRAT={setIDTRAT}
                                                total={total}
                                                setItemsTratamento={setItemsTratamento}
                                                getItemsTratamento={getItemsTratamento}
                                            />

                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="custom-tabs-two-fin" role="tabpanel" aria-labelledby="custom-tabs-two-fin-tab">
                                        <div className="table-responsive">
                                            <TableFinanceiro
                                                data={tratamentos}
                                                //column={column_tratamento} 
                                                getTratamentos={getTratamentos}
                                                setShowModalPlanoPagamento={setShowModalPlanoPagamento}
                                                ref={ref_financeiros}
                                                setIDTRAT={setIDTRAT}
                                                getItemsTratamento={getItemsTratamento}
                                                getItemsPagamento={getItemsPagamento}
                                                total={total}
                                                setTotal={setTotal}
                                                cod_paciente={location.state.id} />

                                        </div>
                                    </div>
                                    {/* <div className="tab-pane fade" id="custom-tabs-two-file" role="tabpanel" aria-labelledby="custom-tabs-two-file-tab">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-bordered table-hover table-sm">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center small font-weight-bold">Arquivo</th>
                                                        <th className="text-center small font-weight-bold">Descricao</th>
                                                        <th className="text-center small font-weight-bold">Operação</th>
                                                    </tr>
                                                </thead>
                                                <tbody is="dmx-repeat" dmx-generator="bs4table" id="tableRepeat2">
                                                    <tr>
                                                        <td className="small">
                                                            <a target="_blank" is="dmx-link" href="/uploads/1/40/Documento_sem_titulo_1.pdf">Documento1.pdf</a>
                                                        </td>
                                                        <td className="text-center small"></td>
                                                        <td className="text-center small">
                                                            <button id="btn3" className="btn btn-sm btn-danger small" is="dmx-button" value="" type="button"><i className="fa fa-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="small">
                                                            <a target="_blank" is="dmx-link" href="/uploads/1/40/Documento_sem_titulo_1.pdf">Documento2.pdf</a>
                                                        </td>
                                                        <td className="text-center small"></td>
                                                        <td className="text-center small">
                                                            <button id="btn3" className="btn btn-sm btn-danger small" is="dmx-button" value="" type="button"><i className="fa fa-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> */}
                                    <div className="tab-pane fade" id="custom-tabs-two-file" role="tabpanel" aria-labelledby="custom-tabs-two-file-tab">
                                        <div className="table-responsive">
                                            <TableArquivos
                                                arquivos={arquivos}
                                                //column={column_tratamento} 
                                                //ref={ref_agendas}
                                                //getAgendas={getAgendas} 
                                                //setAgendas={setAgendas}
                                                //setShowModalAgenda={setShowModalAgenda}
                                                cod_paciente={location.state.id} />

                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="custom-tabs-two-agenda" role="tabpanel" aria-labelledby="custom-tabs-two-agenda-tab">
                                        <div className="table-responsive">
                                            <TableAgenda
                                                agendas={agendas}
                                                //column={column_tratamento} 
                                                ref={ref_agendas}
                                                //getAgendas={getAgendas} 
                                                //setAgendas={setAgendas}
                                                setShowModalAgenda={setShowModalAgenda}
                                                cod_paciente={location.state.id} />

                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="custom-tabs-two-pronto" role="tabpanel" aria-labelledby="custom-tabs-two-pronto-tab">
                                        {/* <button id="btn3" className="btn btn-sm btn-danger small" is="dmx-button" value="" type="button" onClick={() => setShowModalProntuario(true)}><i className="fa fa-trash"></i>
                                    </button> */}
                                        <TableProntuarios
                                            setTabela={setTabela}
                                            ref={ref_prontuarios}
                                            setShowModalProntuario={setShowModalProntuario}
                                            getProntuarios={getProntuarios}
                                            cod_paciente={location.state.id}
                                            setIDPRONT={setIDPRONT}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* /.content */}
            </div>
            {/* <ModalNovoOrc title="ModalNovoOrc" onClose={() => setShowModalNovoOrc(false)} show={showModalNovoOrc} id={id} /> */}
            <ModalItems title="My ModalEditOrc" onClose={() => { setShowModalEditOrc(false); ref_orcamentos.current.update(); } } show={showModalEditOrc} id={id} total={total} idorc={idorc} itemsOrcamento={itemsOrcamento} setIDORC={setIDORC} getItemsOrcamento={getItemsOrcamento} />

            <ModalItemsTratamentos title="My ModalEditOrc" onClose={() => { setShowModalEditTrat(false) ; ref_tratamentos.current.update(); ref_financeiros.current.update();}} show={showModalEditTrat} id={id} totalTratamento={totalTratamento} idtrat={idtrat} itemsTratamento={itemsTratamento} setIDTRAT={setIDTRAT} getItemsTratamento={getItemsTratamento} />

            <Modal2 title="My Modal2" onClose={() => setShowModal2(false)} show={showModal2}>
            </Modal2>

            <ModalAgenda
                title="Agenda" onClose={() => { setShowModalAgenda(false); ref_agendas.current.update(); }} show={showModalAgenda} cod_paciente={location.state.id} >
            </ModalAgenda>

            <ModalProntuario title="My Modal2" onClose={() => { setShowModalProntuario(false) }} show={showModalProntuario}  >

            </ModalProntuario>

            <ModalPlanoPagamento title="My Modal2" onClose={() => { setShowModalPlanoPagamento(false);ref_tratamentos.current.update(); ref_financeiros.current.update(); }} show={showModalPlanoPagamento} itemsPlanoPagamento={itemsPlanoPagamento} getItemsPagamento={getItemsPagamento} idtrat={idtrat} cod_paciente={paciente.nome} setIDTRAT={setIDTRAT} total={total}> 
                
            </ModalPlanoPagamento>

            <Footer />

        </div>
    )
}

Paciente.displayName = "Paciente";