import React from 'react'
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Api from '../Api'



export default function Child() {
    const navigate = useNavigate();
    const [login, setLogin] = useState('');
    const [profile, setProfile] = useState('');
    const [user, setUser] = useState('');
    async function editUser() {
        var login = localStorage.getItem('login');
        var token = JSON.parse(login);
        var id = token.id;
        setShowModalEditUser(true);
        //EditItemsUser(id);
    }

    const [items, setItems] = useState([' ']);
    const [showModalEditUser, setShowModalEditUser] = useState(false);

    useEffect(() => {
        const access_token = localStorage.getItem('login');
        setLogin(access_token);

        if (access_token !== null) {
            var token = JSON.parse(access_token);
            setProfile(token.level);
            setUser(token.user);
        }

    }, [])

    const Sucesso = Swal.mixin({
        toast: true,
        position: 'center',
        iconColor: 'green',
        customClass: {
            popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true
    });

    const Falha = Swal.mixin({
        toast: true,
        position: 'center',
        iconColor: 'falha',
        customClass: {
            popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true
    });


    async function changePassword() {
        var login = localStorage.getItem('login');
        var token = JSON.parse(login);


        //console.log("header", header );

        (async () => {

            const header = {
                'headers': {
                    'Authorization': 'Bearer ' + token.access_token,
                    'Content-Type': 'application/json'
                }
            }

            const Sucesso = Swal.mixin({
                toast: true,
                position: 'center',
                iconColor: 'green',
                customClass: {
                    popup: 'colored-toast'
                },
                showConfirmButton: false,
                timer: 2500,
                timerProgressBar: true
            });

            const Falha = Swal.mixin({
                toast: true,
                position: 'center',
                iconColor: 'falha',
                customClass: {
                    popup: 'colored-toast'
                },
                showConfirmButton: false,
                timer: 2500,
                timerProgressBar: true
            });
            const { value: formValues } = await Swal.fire({
                title: 'Trocar a senha',
                html:
                    '<input type="password" id="senha1" className="swal2-input" placeholder="senha"><br>' +
                    '<br>' +
                    '<input type="password" id="senha2" className="swal2-input" placeholder="confirmação">',
                focusConfirm: false,
                preConfirm: () => {
                    return [
                        document.getElementById('senha1').value,
                        document.getElementById('senha2').value
                    ]
                }
            })

            if (formValues) {
                var senha1 = formValues[0];
                var senha2 = formValues[1];

                if (senha1 !== "") {

                    if (senha1 === senha2) {
                        try {
                            var block = {
                                "password": senha1
                            }

                            var response = await Api.put('usuarios/password/' + token.id, block, header);

                            if (response.status === 200) {
                                await Sucesso.fire({
                                    icon: 'success',
                                    title: 'Senha atualizada'
                                })
                            }
                        } catch (e) {
                            await Falha.fire({
                                icon: 'error',
                                title: 'Senha não foi alterada'
                            })
                        }
                    } else {
                        await Falha.fire({
                            icon: 'error',
                            title: 'Senhas digitadas são diferentes!!!'
                        })
                    }
                }
            }



        })()

    }

    async function doLogout() {
        Swal.fire({
            title: 'Desaja sair da plataforma?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('login');
                navigate("/");
                navigate(0);

                Swal.fire({
                    title: "Desconectado",
                    text: "Faça o login novamente",
                    icon: 'success',
                    timer: 2000,
                    timerProgressBar: true,
                    showConfirmButton: true,
                    didOpen: () => {
                        Swal.showLoading()
                        const b = Swal.getHtmlContainer().querySelector('b')
                        setInterval(() => {
                            b.textContent = Swal.getTimerLeft()
                        }, 100)
                    }
                })

            }
        })
    }
    return (
        <div>
            {/* Main Sidebar Container */}
            <aside className="main-sidebar sidebar-dark-primary elevation-4 ">
                {/* Brand Logo */}
                <Link to="" className="brand-link text-left">
                    <img src="dist/img/AdminLTELogo.png" alt="Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                    <span className="brand-text font-weight-light text-left">Condent</span>
                </Link>
                {/* Sidebar */}
                <div className="sidebar">
                    {/* Sidebar user panel (optional) */}
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                        {/* <div className="image">
                            <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
                        </div> */}
                        {
                            !login
                                ? (
                                    <div className="info text-danger fw-bold">
                                        Usuário : Não conectado
                                    </div>
                                ) : (
                                    <div className="info text-danger fw-bold">
                                        Usuário : {user}
                                    </div>
                                )
                        }
                    </div>
                    <nav className="mt-2">
                        {
                            !login
                                ? (
                                    <ul className="nav nav-pills nav-sidebar flex-column text-left" data-widget="treeview" role="menu" data-accordion="false">
                                        {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}

                                        <li key="inicio" className="nav-item">
                                            <Link to="/" className="nav-link">
                                                <i className="nav-icon  fa fa-lock" />
                                                <p>
                                                    Login
                                                </p>
                                            </Link>
                                        </li>
                                    </ul>
                                ) : (
                                    <>
                                        <ul className="nav nav-pills nav-sidebar flex-column text-left" data-widget="treeview" role="menu" data-accordion="false">
                                            <li key="inicio" className="nav-item">
                                                <Link to="/Dashboard" className="nav-link text-white fw-bold">
                                                    <i className="nav-icon fas  fa-bookmark" />
                                                    <p>
                                                        Inicio
                                                    </p>
                                                </Link>
                                            </li>
                                            <li key="agenda" className="nav-item">
                                                <Link to="/Agenda" className="nav-link text-white fw-bold">
                                                    <i className="nav-icon fas  fa-calendar" />
                                                    <p>
                                                        Agenda
                                                    </p>
                                                </Link>
                                            </li>
                                            <li key="pacientes" className="nav-item">
                                                <Link to="/Pacientes" className="nav-link text-white fw-bold">
                                                    <i className="nav-icon fas  fa-user" />
                                                    <p>
                                                        Pacientes
                                                    </p>
                                                </Link>
                                            </li>
                                            <li key="clinica" className="nav-item">
                                                <Link to="/Clinica" className="nav-link text-white fw-bold">
                                                    <i className="nav-icon far fa-building" />
                                                    <p>
                                                        Clinica
                                                    </p>
                                                </Link>
                                            </li>
                                            <li key="cadastros" className="nav-item">
                                                <Link to="/Cadastros" className="nav-link text-white fw-bold">
                                                    <i className="nav-icon fas  fa-archive" />
                                                    <p>
                                                        Cadastros
                                                    </p>
                                                </Link>
                                            </li>
                                            <li key="adm" className="nav-item">
                                                <Link to="/Administracao" className="nav-link text-white fw-bold">
                                                    <i className="nav-icon fas  fa-users" />
                                                    <p>
                                                        Administração
                                                    </p>
                                                </Link>
                                            </li>
                                            {/*
                                            <li key="conf" className="nav-item">
                                                <Link to="/Configuracao" className="nav-link" onClick={editUser}>
                                                    <i className="nav-icon fas  fa-microchip" />
                                                    <p>
                                                        Configuração
                                                    </p>
                                                </Link>
                                            </li> */}
                                            {/* <li key="ajuda" className="nav-item">
                                                <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button" onClick={editUser}>
                                                    <i className="nav-icon fas  fa-cog" />
                                                    <p>
                                                        Configuração
                                                    </p>
                                                </a>
                                            </li> */}
                                            {/* <li key="ajuda" className="nav-item">
                                                <Link to="/Ajuda" className="nav-link">
                                                    <i className="nav-icon fas  fa-question" />
                                                    <p>
                                                        Ajuda
                                                    </p>
                                                </Link>
                                            </li> */}
                                            <li key="changePassword" className="nav-item">
                                                <button
                                                    className="nav-link text-white fw-bold text-left w-100"
                                                    data-widget="control-sidebar"
                                                    data-slide="true"
                                                    type="button"
                                                    onClick={changePassword}
                                                >
                                                    <i className="nav-icon fas fa-key" />
                                                    <p>Senha</p>
                                                </button>
                                            </li>
                                            <li key="doLogout" className="nav-item">
                                                <button
                                                    className="nav-link text-left text-white fw-bold w-100"
                                                    data-widget="control-sidebar"
                                                    data-slide="true"
                                                    type="button"
                                                    onClick={doLogout}
                                                >
                                                    <i className="nav-icon fa fa-unlock-alt" />
                                                    <p>Sair</p>
                                                </button>
                                            </li>
                                        </ul>
                                    </>
                                )
                        }
                    </nav>
                    {/* /.sidebar-menu */}
                </div>
                {/* /.sidebar */}
            </aside>
        </div>
    )
}
