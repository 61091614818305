import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Api from '../../Api';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { useForm } from "react-hook-form";
import globalState from '../Global';

// eslint-disable-next-line react/prop-types

const Basico = (props) => {
    const [data, setData] = useState(props.paciente);
    const sexos = [
        {
            value: 'M',
            label: 'Masculino',
        },
        {
            value: 'F',
            label: 'Feminino',
        }
    ];

    const getItemsCidades = async (id) => {
        const response = await Api.get('cidades/', globalState.value);
        setItemsCidades(response.data);
    }
    const getItemsEstados = async (id) => {
        const response = await Api.get('estados/', globalState.value);
        setItemsEstados(response.data);
    }
    const [itemsCidades, setItemsCidades] = useState([]);
    const [itemsEstados, setItemsEstados] = useState([]);

    useEffect(() => {
        getItemsCidades();
        getItemsEstados();
    
    }, []);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        nome: "",
        sexo: "",
        cpf: "",
        documento: "",
        endereco: "",
        complemento: "",
        bairro: "",
        cidade: "",
        estado: "",
        telefone1: "",
        telefone2: "",
        celular: "",
        foto: "",
        pk_clinica: "",
        email: "",
        facebook: "",
        instagram: "",
        data_nasc: "",
        data_cadastro: "",
        cep: "",
        profissao: ""
    });

    // const [text, setText] = useState("");

    // const [saved, setSaved] = useState(true);

    

    return (
        <div>
            <div className="card-body">
                <Box
                    // sx={{
                    //     display: 'flex',
                    //     alignItems: 'center',
                    //     '& > :not(style)': { m: 2 },
                    // }}
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                >

                    <div className="card-body">
                        <div className="row">
                            <div>

                                <FormControl fullWidth sx={{ m: 1 }} variant="standard"></FormControl>
                                <TextField
                                    style={{ width: '98%' }}
                                    multiline={true}
                                    label="Nome"
                                    id="nome"
                                    name="nome"
                                    defaultValue={props.paciente.nome}
                                    size="small"
                                    onChange={(e) => props.setPaciente({ ...props.paciente, nome: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div>
                                <TextField
                                    multiline={true}
                                    label="CPF"
                                    id="cpf"
                                    name="cpf"
                                    fullWidth
                                    defaultValue={props.paciente.cpf}
                                    size="small"
                                    onChange={(e) => props.setPaciente({ ...props.paciente, cpf: e.target.value })}
                                />
                                <TextField
                                    multiline={true}
                                    label="Documento"
                                    id="documento"
                                    name="documento"
                                    fullWidth
                                    defaultValue={props.paciente.documento}
                                    size="small"
                                    onChange={(e) => props.setPaciente({ ...props.paciente, documento: e.target.value })}
                                />
                                <TextField
                                    //multiline={true}
                                    label="Sexo"
                                    select
                                    id="sexo"
                                    style={{ width: '25%' }}
                                    name="sexo"
                                    fullWidth
                                    defaultValue={props.paciente.sexo}
                                    size="small"
                                    value={props.paciente.sexo || ''}
                                    onChange={(e) => props.setPaciente({ ...props.paciente, sexo: e.target.value })}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    >
                                    {sexos.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}

                                </TextField>
                            </div>
                        </div>
                        <div className="row">
                            <div>
                                <TextField
                                    multiline={true}
                                    style={{ width: '55%' }}
                                    label="Endereço"
                                    id="endereco"
                                    name="endereco"
                                    fullWidth
                                    defaultValue={props.paciente.endereco}
                                    size="small"
                                    onChange={(e) => props.setPaciente({ ...props.paciente, endereco: e.target.value })}
                                />
                                <TextField
                                    multiline={true}
                                    style={{ width: '30%' }}
                                    label="Complemento"
                                    id="complemento"
                                    name="complemento"
                                    fullWidth
                                    defaultValue={props.paciente.complemento}
                                    size="small"
                                    onChange={(e) => props.setPaciente({ ...props.paciente, complemento: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div>
                                <TextField
                                    multiline={true}
                                    label="Bairro"
                                    id="bairro"
                                    name="bairro"
                                    fullWidth
                                    defaultValue={props.paciente.bairro}
                                    size="small"
                                    onChange={(e) => props.setPaciente({ ...props.paciente, bairro: e.target.value })}
                                />
                                <TextField
                                    multiline={true}
                                    label="CEP"
                                    id="cep"
                                    name="cep"
                                    fullWidth
                                    defaultValue={props.paciente.cep}
                                    size="small"
                                    onChange={(e) => props.setPaciente({ ...props.paciente, cep: e.target.value })}
                                />
                                <TextField
                                    //multiline={true}
                                    label="Estado"
                                    select
                                    id="estado"
                                    style={{ width: '45%' }}
                                    name="estado"
                                    fullWidth
                                    defaultValue={props.paciente.estado}
                                    size="small"
                                    value={props.paciente.estado || ''}
                                    onChange={(e) => props.setPaciente({ ...props.paciente, estado: e.target.value })}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    >
                                    {itemsEstados?.map(estado => <option key={estado.nome} value={estado.nome}>{estado.nome}</option>)}
                                </TextField>
                                <TextField
                                    //multiline={true}
                                    label="Cidade"
                                    select
                                    id="cidade"
                                    style={{ width: '45%' }}
                                    name="cidade"
                                    fullWidth
                                    defaultValue={props.paciente.cidade}
                                    size="small"
                                    value={props.paciente.cidade || ''}
                                    onChange={(e) => props.setPaciente({ ...props.paciente, cidade: e.target.value })}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    >
                                    {itemsCidades?.map(cidade => <option key={cidade.nome}value={cidade.nome}>{cidade.nome}</option>)}
                                </TextField>
                            </div>
                        </div>
                        <div className="row">
                            <div>
                                <TextField
                                    multiline={true}
                                    label="Data nasc"
                                    id="data_nasc"
                                    name="data_nasc"
                                    defaultValue={props.paciente.data_nasc}
                                    fullWidth
                                    size="small"
                                    onChange={(e) => props.setPaciente({ ...props.paciente, data_nasc: e.target.value })}
                                />
                                <TextField
                                    multiline={true}
                                    label="Data cadastro"
                                    id="data_cadastro"
                                    name="data_cadastro"
                                    defaultValue={props.paciente.data_cadastro}
                                    fullWidth
                                    size="small"
                                    onChange={(e) => props.setPaciente({ ...props.paciente, data_cadastro: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn btn-danger" onClick={() => props.delUser(props.paciente.id)}>Excluir</button>

                            <button className="btn btn-primary" onClick={() => props.savePaciente(props.paciente.id)}>Salvar</button>
                        </div>
                    </div>
                </Box>
            </div>

        </div>

    )
};

export default Basico;