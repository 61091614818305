import Form from "react-jsonschema-form-bs4";
import { Button } from "react-bootstrap";
import Api from '../../Api';
import LayoutGridField from "react-jsonschema-form-layout-2";
import globalState from '../Global';

// eslint-disable-next-line react/prop-types

const FormFornecedor = props => {
  // const addData = async (formData, props) => {
  //   try {
  //     const response = await Api.post('fornecedores', formData, globalState.value);

  //     console.log(response);

  //     if (response.status === 200) {
  //       props.Sucesso.fire({
  //         icon: 'success',
  //         title: 'Registro foi incluído!!!'
  //       });
  //       // props.msgReturn();
  //     }
  //     if (response.status === 201) {
  //       props.Falha.fire({
  //         icon: 'warning',
  //         title: 'Registro já existe!!!'
  //       });
  //       // props.msgReturn();
  //     }

  //   } catch (e) {
  //     console.error(e)
  //   }
  //   props.closeModal();
  // }
  const addData = async (formData, props) => {
    try {
      const response = await Api.post('fornecedores', formData, globalState.value);
  
      console.log(response);
  
      if (response.status === 200) {
        props.Sucesso.fire({
          icon: 'success',
          title: 'Registro foi incluído!!!'
        });
      }
      if (response.status === 201) {
        props.Falha.fire({
          icon: 'warning',
          title: 'Registro já existe!!!'
        });
      }
    } catch (e) {
      if (e.response && e.response.status === 401) {
        console.error("Erro 401 - Não autorizado");
        props.Falha.fire({
          icon: 'error',
          title: 'Erro 401!',
          text: 'Dado já existe'
        });
      } else {
        console.error(e);
      }
    }
    props.closeModal();
  };

  const fields = {
    layout: LayoutGridField
  };

  const schema = {
    // title: "Inserir cidades",
    type: "object",
    //required: ["fornecedor, razao, cnpj"],
    properties: {
      fornecedor: {
        type: "string",
        default: "",
        title: "Fornecedor",
      },
      razao: {
        type: "string",
        default: "",
        title: "Razão Social"
      },
      cnpj: {
        type: "string",
        default: "",
        title: "CNPJ"
      },
      ie: {
        type: "string",
        default: "",
        title: "IE"
      },
      bairro: {
        type: "string",
        default: "",
        title: "Bairro"
      },
      cidade: {
        type: "string",
        default: "",
        title: "Cidade"
      },
      estado: {
        type: "string",
        default: "",
        title: "Estado"
      },
      endereco: {
        type: "string",
        default: "",
        title: "Endereço"
      },
      whatsapp: {
        type: "string",
        default: "",
        title: "Whatsapp"
      },
      email: {
        type: "string",
        default: "",
        title: "Email"
      },
      cep: {
        type: "string",
        default: "",
        title: "CEP"
      },
      observacoes: {
        type: "string",
        default: "",
        title: "Observações"
      },
    }
  };

  const uiSchema = {
    "ui:field": "layout",
    "ui:layout": [
      {
        fornecedor: { md: 12 }
      },
      {
        razao: { md: 12 }
      },
      {  
        cnpj: { md: 6},
        ie: { md: 6 }
      },
      {  
        endereco: { md: 6},
        bairro: { md: 6 }
      },
      {  
        cidade: { md: 4},
        estado: { md: 4},
        cep: { md: 4 },
      },
      {  
        whatsapp: { md: 6},
        email: { md: 6}
      },
      {  
        observacoes: { md: 12}
      },
      
    ]
  };

  
  const style = { width: '100px' }
  const formData = {};

  //const log = type => console.log.bind(console, type);
  const onSubmit = ({ formData }) => addData(formData, props);
  const onError = errors => console.log("I have", errors.length, "errors to fix");


  return (
    <Form
      schema={schema}
      uiSchema={uiSchema}
      formData={formData}
      onSubmit={onSubmit}
      fields={fields}
      onError={onError}
    >
      <div>
        <div className="text-right">
          <Button style={style} variant="danger" onClick={props.closeModal} size="sm">
            <i className="fas fa-ban"></i> Cancelar
          </Button>
          <Button style={style} variant="primary" type="submit" size="sm">
            <i className="fas fa-check"></i> Salvar
          </Button>
        </div>

      </div>
    </Form>
  );
};

export default FormFornecedor;

  