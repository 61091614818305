import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

// eslint-disable-next-line react/prop-types

const Midias = (props) => {
    //export default function Midias(props) {
    const [saved, setSaved] = useState(true);

    return (
        <div>
            <div className="card-body">
                <Box
                    // sx={{
                    //     display: 'flex',
                    //     alignItems: 'center',
                    //     '& > :not(style)': { m: 2 },
                    // }}
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                >
                    <div className="row">
                        <div>
                            <TextField
                                 style={{ width: '98%' }}
                                multiline={true}
                                label="email"
                                id="email"
                                name="email"
                                defaultValue={props.paciente.email}
                                fullWidth
                                size="small"
                                onChange={(e) => props.setPaciente({ ...props.paciente, email: e.target.value })}
                            />
                        </div>
                        <div>
                            <TextField
                                multiline={true}
                                style={{ width: '98%' }}
                                label="facebook"
                                id="facebook"
                                name="facebook"
                                fullWidth
                                defaultValue={props.paciente.facebook}
                                size="small"
                                onChange={(e) => props.setPaciente({ ...props.paciente, facebook: e.target.value })}
                            />
                            </div>
                        <div>
                            <TextField
                                multiline={true}
                                style={{ width: '98%' }}
                                label="instagram"
                                id="instagram"
                                name="instagram"
                                fullWidth
                                defaultValue={props.paciente.instagram}
                                size="small"
                                onChange={(e) => props.setPaciente({ ...props.paciente, instagram: e.target.value })}
                            />
                        </div>
                    </div>
                </Box>
            </div>
            <div className="card-footer">
                <button type="submit" className="btn btn-danger" onClick={() => props.delUser(props.paciente.id)}>Excluir</button>

                <button className="btn btn-primary" onClick={() => props.savePaciente(props.paciente.id)}>Salvar</button>
            </div>
        </div>
    )
};

export default Midias;