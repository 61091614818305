import Form from "react-jsonschema-form-bs4";
import { Button } from "react-bootstrap";
import Api from '../../Api';
import LayoutGridField from "react-jsonschema-form-layout-2";
import globalState from '../Global';

// eslint-disable-next-line react/prop-types

const FormProtese= props => {
  const addData = async (formData, props) => {
    try {
      const response = await Api.post('proteses', formData, globalState.value);

      if (response.status === 200) {
        props.Sucesso.fire({
          icon: 'success',
          title: 'Registro foi incluído!!!'
        });
        // props.msgReturn();
      }
      if (response.status === 201) {
        props.Falha.fire({
          icon: 'warning',
          title: 'Registro já existe!!!'
        });
        // props.msgReturn();
      }

    } catch (e) {
      if (e.response && e.response.status === 401) {
        console.error("Erro 401 - Não autorizado");
        props.Falha.fire({
          icon: 'error',
          title: 'Erro 401!',
          text: 'Dado já existe'
        });
      } else {
        console.error(e);
      }
    }
    props.closeModal();
  }
  
  const fields = {
    layout: LayoutGridField
  };

  const uiSchema = {
    "ui:field": "layout",
    "ui:layout": [
      {
        paciente: { md: 12 }
      },
      {
        solicitante: { md: 12 }
      },
      {
        protetico: { md: 12 }
      },
      {  
        tabela_serv: { md: 6},
        regiao: { md: 6 }
      },
      {
        servico: { md: 12 }
      },
      {
        quantidade: { md: 6 },
        valor: { md: 6 },
      },
      { 
        cor: { md: 6 }, 
        entrega: { md: 6}
      },
      {  
        detalhes: { md: 12 }
      },
    ]
  };

  const schema = {
    // title: "Inserir cidades",
    type: "object",
    properties: {
      paciente: {
        type: "string",
        default: "",
        title: "Paciente",
        minLength: 1
      },
      solicitante: {
        type: "string",
        default: "",
        title: "Solicitante"
      },
      protetico: {
        type: "string",
        default: "",
        title: "Protetico"
      },
      tabela_serv: {
        type: "string",
        default: "",
        title: "Tabela serviço"
      },
      servico: {
        type: "string",
        default: "",
        title: "Serviço"
      },
      regiao: {
        type: "string",
        default: "",
        title: "Região"
      },
      quantidade: {
        type: "integer",
        default: "",
        title: "Quantidade"
      },
      cor: {
        type: "string",
        default: "",
        title: "Cor"
      },
      valor: {
        type: "string",
        default: "",
        title: "Valor"
      },
      entrega: {
        type: "string",
        default: "",
        title: "Entrega"
      },
      detalhes: {
        type: "string",
        default: "",
        title: "Detalhes"
      }
    }
  };

  // const uiSchema = {
  //   "ui:rootFieldId": "formOne"
  // };

  const style = { width: '100px' }
  const formData = {};

  //const log = type => console.log.bind(console, type);
  const onSubmit = ({ formData }) => addData(formData, props);
  const onError = errors => console.log("I have", errors.length, "errors to fix");


  return (
    <Form
      schema={schema}
      uiSchema={uiSchema}
      fields={fields}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      formData={formData}
      onSubmit={onSubmit}
      onError={onError}
    >
      <div>
        <div className="text-right">
          <Button style={style} variant="danger" onClick={props.closeModal} size="sm">
            <i className="fas fa-ban"></i> Cancelar
          </Button>
          <Button style={style} variant="primary" type="submit" size="sm">
            <i className="fas fa-check"></i> Salvar
          </Button>
        </div>

      </div>
    </Form>
  );
};

export default FormProtese;