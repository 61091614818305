
import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import '../../css/styles.css';
import '../../css/prog-track.css';
import Api from '../../Api';
import Swal from 'sweetalert2';
import StepZilla from "react-stepzilla";
import StepUm from './stepUm';
import StepDois from './stepDois';
import StepTres from './stepTres';
import StepQuatro from './stepQuatro';
import StepCinco from './stepCinco';
import StepSeis from './stepSeis';
import globalState from '../Global';

const ModalNovoPaciente = props => {
  const closeOnEscapeKeyDown = e => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };
  const Sucesso = Swal.mixin({
    toast: true,
    position: 'center',
    iconColor: 'green',
    customClass: {
      popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  });

  const style = { width: '85px', color: 'red' }

  const Falha = Swal.mixin({
    toast: true,
    position: 'center',
    iconColor: 'falha',
    customClass: {
      popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  });
  const [paciente, setPaciente] = useState([]);
  
  const [file, setFile] = useState('');

  const savePaciente = async () => {

    var login = localStorage.getItem('login');
    var token = JSON.parse(login);

    let formdata = new FormData();

    if (file === "") {
      setPaciente(paciente.foto = "nobody.png");
    } else {
      formdata.append('file', file);

      var headers = {
        'headers': {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + token.access_token,
        }
      }
      console.log("headers->", headers)
      var result = await Api.post('/upload-single', formdata, headers);
      setPaciente(paciente.foto = result.data.file);
    }

    
    setPaciente(paciente.data_cadastro = "");

    const response = await Api.post('pacientes', paciente, globalState.value);

    if (response.status === 200) {
      await Sucesso.fire({
        icon: 'success',
        title: 'Usuário incluído'
      });
      await props.onClose();
    } else {
      await Falha.fire({
        icon: 'error',
        title: 'Usuário não incluído'
      });
    }
  }

  const steps =
    [
      {name: '1', component: <StepUm paciente={paciente} setPaciente={setPaciente}/>},
      {name: '2', component: <StepDois paciente={paciente} setPaciente={setPaciente}/>},
      {name: '3', component: <StepTres paciente={paciente} setPaciente={setPaciente}/>},
      {name: '4', component: <StepQuatro paciente={paciente} setPaciente={setPaciente}/>},
      {name: '5', component: <StepCinco paciente={paciente} setPaciente={setPaciente} file={file} setFile={setFile}/>},
      {name: 'Salvar', component: <StepSeis paciente={paciente} setPaciente={setPaciente} savePaciente={savePaciente} file={file}/>}
    ]

  useEffect(() => {
    setPaciente({ nome: "", foto: "" })
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  return ReactDOM.createPortal(
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div className="modal" onClick={props.onClose}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <div className="modal-header">
            <h4 className="modal-title">Novo paciente</h4>
          </div>
          <div>
          <div>
              {/*
              <MultiStep steps={true} activeStep={0} showTitles={true} showNavigation={true} prevButton={{ style:{ background: 'blue' }, title: '< Anterior' }} nextButton={{ style:{ background: 'green' },title: 'Próximo >' }}>
                <StepUm title='1' paciente={paciente} setPaciente={setPaciente} />
                <StepDois title='2' paciente={paciente} setPaciente={setPaciente} />
                <StepTres title='3' paciente={paciente} setPaciente={setPaciente} />
                <StepQuatro title='4' paciente={paciente} setPaciente={setPaciente} />
                <StepCinco title='5' paciente={paciente} setPaciente={setPaciente} file={file} setFile={setFile} />
                <StepSeis title='6' paciente={paciente} setPaciente={setPaciente} savePaciente={savePaciente} file={file} />
              </MultiStep> */}
              <div className="step-progress">
              <StepZilla steps={steps} nextButtonText={"Próximo"} backButtonText={"Anterior"} nextButtonCls={'btn btn-prev btn-success btn-sm pull-right'} backButtonCls={'btn btn-prev btn-primary btn-sm pull-right'}/>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-danger" onClick={props.onClose}>Fechar</button>
            <button className="btn btn-primary" onClick={() => savePaciente()}>Salvar</button>
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById("root")
  );
};

export default ModalNovoPaciente;