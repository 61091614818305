
import React, { useState, useEffect, useRef, Component } from 'react';
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

import "../../css/prog-track.css";
import Api from '../../Api';
import Swal from 'sweetalert2';
import { Controller, useForm } from "react-hook-form";
import '../../css/styles.css';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { css } from '@emotion/react';
import globalState from '../Global';

const ModalNovoPaciente = props => {


  let cpf = props.item?.cpf;
  let email = props.item?.email;
  let celphone = props.item?.celphone;
  let first_name = props.item?.first_name;
  let last_name = props.item?.last_name;
  let password = props.item?.password;
  let role = props.item?.role;
  let last_login = props.item?.last_login;
  let pk_clinica = props.item?.pk_clinica;
  let facebook = props.item?.facebook;
  let instagram = props.item?.instagram;
  let tratamento = props.item?.tratamento;
  let cro = props.item?.cro;
  let foto = props.item?.foto;
  let level = props.item?.level;
  let domain = props.item?.domain;
  let username = props.item?.username;

  const form = useRef(null);

  
  const closeOnEscapeKeyDown = e => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };
  const Sucesso = Swal.mixin({
    toast: true,
    position: 'center',
    iconColor: 'green',
    customClass: {
      popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  });

  const style = { width: '85px', color: 'red' }

  const Falha = Swal.mixin({
    toast: true,
    position: 'center',
    iconColor: 'falha',
    customClass: {
      popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  });
  const [paciente, setPaciente] = useState([]);
  const [file, setFile] = useState('');
  const [data, setData] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      mail: "",
      celphone: "",
      first_name: "",
      last_name: "",
      password: "",
      role: "",
      last_login: "",
      pk_clinica: "",
      facebook: "",
      instagram: "",
      tratamento: "",
      cpf: "",
      cro: "",
      image: "",
      level: '0',
      domain: "",
      username: ""
    },
  });


  const savePaciente = async () => {
    const block = {
      "email": email,
      "first_name": first_name,
      "last_name": last_name,
      "password": password,
      "role": role,
      "last_login": last_login,
      "pk_clinica": pk_clinica,
      "facebook": facebook,
      "instagram": instagram,
      "tratamento": tratamento,
      "cpf": cpf,
      "celphone": celphone,
      "cro": cro,
		  "image": foto,
	    "level": "0", 
      "domain": domain,
	    "username": email
    }

    const response = await Api.put('usuarios/' + props.item?.id, block, globalState.value);
    if (response.status === 200) {
      await Sucesso.fire({
        icon: 'success',
        title: 'Usuário atualizado'
      });
      await props.onClose();
    } else {
      await Falha.fire({
        icon: 'error',
        title: 'Usuário não atualizado'
      });
    }

  }



  useEffect(() => {

  }, []);

  return ReactDOM.createPortal(
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div className="modal" onClick={props.onClose}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <div className="modal-header">
            <h4 className="modal-title">Editar usuário</h4>
          </div>
          <div>
            <div>
              <div className="card-body">
                <Box
                  // sx={{
                  //     display: 'flex',
                  //     alignItems: 'center',
                  //     '& > :not(style)': { m: 2 },
                  // }}
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '20ch' },
                  }}
                >

                  <div className="card-body">
                    <form ref={form} onSubmit={handleSubmit}>
                      {/* <form onSubmit={handleSubmit}> */}

                      <div className="row">

                        <div>
                          <TextField
                            multiline={true}
                            label="Email"
                            style={{ width: '45%' }}
                            id="email"
                            name="email"
                            fullWidth
                            defaultValue={props.item?.email}
                            size="small"
                            // onKeyDown=""
                            //value={formData.email}
                            onChange={(e) => email = e.target.value}
                          />
                          <TextField
                            multiline={true}
                            label="Celular"
                            style={{ width: '45%' }}
                            id="celphone"
                            //value={cpf}
                            name="celphone"
                            fullWidth
                            size="small"
                            // onKeyDown=""
                            defaultValue={props.item?.celphone}
                            onChange={(e) => celphone = e.target.value}
                          />

                        </div>
                      </div>
                      <div className="row">
                        <div>
                          <TextField
                            multiline={true}
                            label="foto"
                            style={{ width: '45%' }}
                            id="image"
                            //value={cpf}
                            name="image"
                            fullWidth
                            size="small"
                            // onKeyDown=""
                            defaultValue={props.item?.image}
                            onChange={(e) => foto = e.target.value}

                          />
                          <TextField
                            multiline={true}
                            label="dominio"
                            style={{ width: '45%' }}
                            id="domain"
                            name="domain"
                            fullWidth
                            defaultValue={props.item?.domain}
                            size="small"
                            onChange={(e) => domain= e.target.value}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div>
                          <TextField
                            multiline={true}
                            label="Nome"
                            style={{ width: '45%' }}
                            id="first_name"
                            //value={cpf}
                            name="first_name"
                            fullWidth
                            defaultValue={props.item?.first_name}
                            size="small"
                            // onKeyDown=""
                            onChange={(e) => first_name = e.target.value}
                          />
                          <TextField
                            multiline={true}
                            label="Sobrenome"
                            style={{ width: '45%' }}
                            id="last_name"
                            name="last_name"
                            fullWidth
                            defaultValue={props.item?.last_name}
                            size="small"
                            onChange={(e) => last_name = e.target.value}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div>
                          <TextField
                            multiline={true}
                            label="CPF"
                            id="cpf"
                            //value={cpf}
                            name="cpf"
                            style={{ width: '45%' }}
                            fullWidth
                            defaultValue={props.item?.cpf}
                            size="small"
                            // onKeyDown=""
                            onChange={(e) => cpf = e.target.value}
                          />
                          <TextField
                            multiline={true}
                            label="CRO"
                            id="cro"
                            name="cro"
                            fullWidth
                            style={{ width: '45%' }}
                            size="small"
                            defaultValue={props.item?.cro}
                            onChange={(e) => cro = e.target.value}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div>
                          <TextField
                            multiline={true}
                            label="Cargo"
                            id="role"
                            //value={cpf}
                            name="role"
                            style={{ width: '45%' }}
                            fullWidth
                            size="small"
                            //onKeyDown=""
                            defaultValue={props.item?.role}
                            onChange={(e) => role = e.target.value}
                          />
                          <TextField
                            multiline={true}
                            label="Tratamento"
                            id="tratmanento"
                            name="tratamento"
                            fullWidth
                            style={{ width: '45%' }}
                            size="small"
                            defaultValue={props.item?.tratamento}
                            onChange={(e) => tratamento = e.target.value}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div>
                          <TextField
                            multiline={true}
                            label="Facebook"
                            id="facebook"
                            //value={cpf}
                            name="facebook"
                            style={{ width: '45%' }}
                            fullWidth
                            size="small"
                            //onKeyDown=""
                            defaultValue={props.item?.facebook}
                            onChange={(e) => facebook = e.target.value}
                          />
                          <TextField
                            multiline={true}
                            label="Instagram"
                            id="instagram"
                            name="instagram"
                            fullWidth
                            style={{ width: '45%' }}
                            size="small"
                            defaultValue={props.item?.instagram}
                            onChange={(e) => instagram = e.target.value}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </Box>
              </div >
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-danger" onClick={props.onClose}>Fechar</button>
            <button className="btn btn-primary" type="submit" onClick={() => savePaciente()}>Salvar</button>
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById("root")
  );
};

export default ModalNovoPaciente;