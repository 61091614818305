import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Swal from 'sweetalert2';
import { DataGrid, GridCellModes,GridActionsCellItem } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import Button from '@mui/material/Button';
import PlusIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Api from '../../Api';
import PrintIcon from '@mui/icons-material/PrintOutlined';
import globalState from '../Global';

const useFakeMutation = () => {
  return React.useCallback(
    (user) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          if (user.name?.trim() === '') {
            reject(new Error("Error while saving user: name can't be empty."));
          } else {
            resolve({ ...user, name: user.name?.toUpperCase() });
          }
        }, 200);
      }),
    [],
  );
};

const Mensagem = Swal.mixin({
  toast: true,
  // position: 'center',
  iconColor: 'green',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true
});

const Table = forwardRef((props, ref) => {
  var baseURL = process.env.REACT_APP_baseURL + "/print/agenda/" + props.cod_paciente
  const [agendas, setAgendas] = useState([]);

  const getAgendas = async (cod_paciente) => {
    const response = await Api.get('agendas/user/' + cod_paciente,globalState.value);
    setAgendas(response.data.tabela);
};

// const Table = forwardRef({ getAgendas, setShowModalAgenda, cod_paciente, ref, agendas, setAgendas }) => {
  
  
  useImperativeHandle(ref, () => ({
    update() {
      getAgendas(props.cod_paciente);
    }
  }));

  useEffect(() => {
    getAgendas(props.cod_paciente);
}, []);
  
  const handleViewEdit = async (row) => {
    const response = await Api.get('prontuario_tratamento/items/' + row, globalState.value);

    var data = moment.utc()
        .utcOffset('-03:00')
        .format('DD/MM/YYYY');

    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputLabel: 'Mensagem',
      inputPlaceholder: 'Digite aqui sua mensagem',
      inputValue: response.data.tabela[0].descricao,
      width: 600,
      height: 400,
      cancelButtonText: 'Cancela',
      confirmButtonText: 'Salvar',
      inputAttributes: {
        'aria-label': 'Digite aqui sua mensagem'
      },
      showCancelButton: true
    })


    if (text) {
      let block = {
        "descricao": text,
        "cod_tratamento": row,
        "data": data
      };
      const response1 = await Api.put('prontuario_tratamento/' + response.data.tabela[0].id, block, globalState.value);
    }
  };

  const handleDeleteClick = (id) => () => {

    Swal.fire({
      title: 'Deseja excluir o agendamento?',
      text: "",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        var teste = agendas.filter((row) => row.id !== id);
        var response = Api.delete('agendas/' + id, globalState.value);
        setAgendas(teste);
        Sucesso.fire({
          icon: 'success',
          title: 'Agendamento excluído'
        });
      }

    })
  };

  const columns = [
    // { field: 'id', headerName: 'Cód', width: 20,  editable: false },
    { field: 'start', headerName: 'Inicio', width: 180, editable: false, resizable: false, valueFormatter: params => 
    moment(params?.value).format("DD/MM/YYYY hh:mm"),},
    { field: 'end', headerName: 'Fim', width: 180, editable: false, resizable: false, valueFormatter: params => 
    moment(params?.value).format("DD/MM/YYYY hh:mm"),},
    {
      field: 'actions2', headerName: 'Opções', width: '70px', renderCell: (params) => {
        return [
          <GridActionsCellItem
              key={`delete-${params.row.id}`} 
              label=""
              icon={<DeleteIcon color="warning" />}
              sx={{
                  color: 'primary.main',
              }}
              onClick={handleDeleteClick(params.row.id)}
          />,
      ];
        // return (
          
        //   <IconButton color="normal"
        //     onClick={handleDeleteClick(params.row.id)}
        //     variant="contained"
        //   >
        //     {/* {cellMode === 'edit' ? 'Salvar' : 'Editar'} */}
        //     <DeleteIcon fontSize="small" />
        //   </IconButton>
        // );
      }
    },
    ];


  const mutateRow = useFakeMutation();
  const [rows, setRows] = React.useState();
  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const Sucesso = Swal.mixin({
    toast: true,
    // position: 'center',
    iconColor: 'green',
    customClass: {
      popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true
  });

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      // Make the HTTP request to save in the backend
      const response_row = await Api.put('tratamentos/' + newRow.id, newRow, globalState.value);
      const response = await mutateRow(newRow);
      setSnackbar({ children: 'Tratamento atualizado', severity: 'success' });
      return response;
    },
    [mutateRow],
  );

const handleProcessRowUpdateError = React.useCallback((error) => {
  setSnackbar({ children: "ocorreu um erro", severity: 'error' });
}, []);

const EditToolbar = (props) => {

  const { selectedCellParams, cellMode, cellModesModel, setCellModesModel } = props;
  const handleClick = () => {

  };


  const handleSaveOrEdit = () => {
    if (!selectedCellParams) {
      return;
    }
    const { id, field } = selectedCellParams;
    if (cellMode === 'edit') {
      setCellModesModel({
        ...cellModesModel,
        [id]: { ...cellModesModel[id], [field]: { mode: GridCellModes.View } },
      });
    } else {
      setCellModesModel({
        ...cellModesModel,
        [id]: { ...cellModesModel[id], [field]: { mode: GridCellModes.Edit } },
      });
    }
  };

  const handleCancel = () => {
    if (!selectedCellParams) {
      return;
    }
    const { id, field } = selectedCellParams;
    setCellModesModel({
      ...cellModesModel,
      [id]: {
        ...cellModesModel[id],
        [field]: { mode: GridCellModes.View, ignoreModifications: true },
      },
    });
  };

  const handleMouseDown = (event) => {
    // Keep the focus in the cell
    event.preventDefault();
  };

  const handleAddRow = async () => {
    props.setShowModalAgenda(true);
  };

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        p: 1,
      }}
    >

      <Button variant="outlined" size="small" onClick={handleAddRow}
          onMouseDown={handleMouseDown} startIcon={<PlusIcon />}>
          Novo
        </Button>
      <Button variant="outlined" size="small" href={baseURL}
          onMouseDown={handleMouseDown} startIcon={<PrintIcon />}>
          Lista
        </Button>

      
    </Box>

    
  );
}

return (
  <div style={{ height: '450px', width: '100%' }}>
    <DataGrid
      autoHeight
      rowHeight={25}
      localeText={globalState.ptBRLocaleText}
      rows={agendas || []}
      slots={{
        toolbar: EditToolbar,
      }}
      initialState={{
        columns: {
          columnVisibilityModel: {
            // Hide columns status and traderName, the other columns will remain visible
            status: false,
            traderName: false,
          },
          // getAgendas,
          //props.cod_paciente,
        },
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          setShowModalAgenda: props.setShowModalAgenda,
          quickFilterProps: { debounceMs: 500 },
        },
        // getAgendas,
        //props.cod_paciente
      }}
      columns={columns}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={handleProcessRowUpdateError}
    />
    {!!snackbar && (
      <Snackbar
        open
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
      >
        <Alert {...snackbar} onClose={handleCloseSnackbar} />
      </Snackbar>
    )}
  </div>
);

//}

});

export default Table;

Table.displayName = "Table";
