
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { ptBR } from '@mui/material/locale';
import { DataGrid, GridCellModes } from '@mui/x-data-grid';
import {
} from '@mui/x-data-grid-generator';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import PlusIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import EditButton from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Button from '@mui/material/Button';
import Api from '../../Api';
import globalState from '../Global';

const useFakeMutation = () => {
  return React.useCallback(
    (user) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          if (user.name?.trim() === '') {
            reject(new Error("Error while saving user: name can't be empty."));
          } else {
            resolve({ ...user, name: user.name?.toUpperCase() });
          }
        }, 200);
      }),
    [],
  );
};

const Mensagem = Swal.mixin({
  toast: true,
  // position: 'center',
  iconColor: 'green',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true
});

const TableProntuarios = ({ data, setShowModalProntuario, setIDPRONT, getProntuarios, cod_paciente }) => {

  const [prontuarios, setProntuarios] = useState([]);

  const getLocalProntuarios = async (cod) => {
    const response = await Api.get('prontuario/items/' + cod, globalState.value);
    setProntuarios(response.data.tabela);
  };

  useEffect(() => {
    getLocalProntuarios(cod_paciente);
  }, []);


  const handleViewEdit = async (row) => {
    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputLabel: 'Editar Prontuário',
      inputPlaceholder: 'Digite aqui sua mensagem',
      inputValue: row.descricao,
      width: 800,
      cancelButtonText: 'Cancela',
      confirmButtonText: 'Salvar',
      inputAttributes: {
        'aria-label': 'Digite aqui sua mensagem',
        style: "height: 300px;"
      },
      showCancelButton: true
    })

    if (text) {
      let block = {
        "descricao": text,
        "cod_paciente": row.cod_paciente,
        "data": row.data
      };

      const response = await Api.put('prontuario/' + row.id, block, globalState.value);
      getLocalProntuarios(row.cod_paciente);
    }
  };

  
  const handleDeleteClick = (id) => () => {
    Swal.fire({
      title: 'Deseja excluir o prontuário?',
      text: "",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        var teste = prontuarios.filter((row) => row.id !== id);
        var response = Api.delete('prontuario/' + id, globalState.value);
        setProntuarios(teste);
        Sucesso.fire({
          icon: 'success',
          title: 'Prontuario excluído'
        });
      }

    })
  };


  const columns = [
    { field: 'id', headerName: 'Cód', width: 20, editable: false },
    {
      field: 'data',
      headerName: 'Data',
      width: 200,
      type: 'number',
      editable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'actions0', headerName: 'Editar', width: 70, renderCell: (params) => {
        return (

          <IconButton color="success"
            onClick={() => { handleViewEdit(params.row) }}

            variant="contained"
          >
            {/* {cellMode === 'edit' ? 'Salvar' : 'Editar'} */}
            <EditButton fontSize="small" />
          </IconButton>

        );
      }
    },
    {
      field: 'actions1', headerName: 'Excluir', width: 70, renderCell: (params) => {
        return (

          <IconButton color="error"
            onClick={handleDeleteClick(params.row.id)}
            variant="contained"
          >
            {/* {cellMode === 'edit' ? 'Salvar' : 'Editar'} */}
            <DeleteIcon fontSize="small" />
          </IconButton>

        );
      }
    }
  ];

  const mutateRow = useFakeMutation();
  const [rows, setRows] = React.useState();
  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const Sucesso = Swal.mixin({
    toast: true,
    // position: 'center',
    iconColor: 'green',
    customClass: {
      popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true
  });

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      // Make the HTTP request to save in the backend
      const response = await mutateRow(newRow);
      setSnackbar({ children: 'User successfully saved', severity: 'success' });
      return response;
    },
    [mutateRow],
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const EditToolbar = (props) => {

    const { selectedCellParams, cellMode, cellModesModel, setCellModesModel } = props;
    const handleClick = () => {

    };


    const handleSaveOrEdit = () => {
      if (!selectedCellParams) {
        return;
      }
      const { id, field } = selectedCellParams;
      if (cellMode === 'edit') {
        setCellModesModel({
          ...cellModesModel,
          [id]: { ...cellModesModel[id], [field]: { mode: GridCellModes.View } },
        });
      } else {
        setCellModesModel({
          ...cellModesModel,
          [id]: { ...cellModesModel[id], [field]: { mode: GridCellModes.Edit } },
        });
      }
    };

    const handleCancel = () => {
      if (!selectedCellParams) {
        return;
      }
      const { id, field } = selectedCellParams;
      setCellModesModel({
        ...cellModesModel,
        [id]: {
          ...cellModesModel[id],
          [field]: { mode: GridCellModes.View, ignoreModifications: true },
        },
      });
    };

    const handleMouseDown = (event) => {
      // Keep the focus in the cell
      event.preventDefault();
    };


    const handleAddRow = async () => {
      var data = moment.utc()
        .utcOffset('-03:00')
        .format('DD/MM/YYYY');

      const { value: text } = await Swal.fire({
        input: 'textarea',
        inputLabel: 'Novo Prontuário',
        inputPlaceholder: 'Digite aqui sua mensagem',
        width: 800,
        height: 400,
        cancelButtonText: 'Cancela',
        confirmButtonText: 'Salvar',
        inputAttributes: {
          'aria-label': 'Digite aqui sua mensagem',
          style: "height: 300px;"
        },
        showCancelButton: true
      })

      if (text) {
        let block = {
          "descricao": text,
          "cod_paciente": cod_paciente,
          "data": data
        };

        const response = await Api.post('prontuario/', block, globalState.value);

        if (response.status === 200) {
          getLocalProntuarios(cod_paciente);
          Sucesso.fire({
            icon: 'success',
            title: 'Prontuario criado'
          });
        }
        if (response.status === 201) {
          Sucesso.fire({
            icon: 'warning',
            title: 'Registro já existe!!!'
          });
        }
      }
    };

    

    return (
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          p: 1,
        }}
      >

        <Button variant="outlined" size="small" onClick={() => { handleAddRow() }}
          onMouseDown={handleMouseDown} startIcon={<PlusIcon />}>
          Novo
        </Button>
      </Box>
    );
  }

  return (
    <div style={{ 'height': '400px', 'width': '100%' }}>
      <DataGrid
        rowHeight={25}
        pageSize={5}
        pageSizeOptions={[5, 10, 20]}
        pagination
        autoHeight
        localeText={globalState.ptBRLocaleText}
        rows={prontuarios || []}
        slots={{
          toolbar: EditToolbar,
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible
              status: false,
              traderName: false,
            },
            getLocalProntuarios,
            cod_paciente,
          },
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
          getLocalProntuarios,
          cod_paciente
        }}
        columns={columns}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
}


export default TableProntuarios;

TableProntuarios.displayName = "TableProntuarios";