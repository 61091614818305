import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

// eslint-disable-next-line react/prop-types

const StepTres = (props) => {

  const [data, setData] = useState("");

  useEffect(() => {
    if (props.paciente !== ""){
      setData(props.paciente);
    } 
  }, [])

  return (
    <div className="card-body">
      <div className="card-body">
        <Box
          // sx={{
          //     display: 'flex',
          //     alignItems: 'center',
          //     '& > :not(style)': { m: 2 },
          // }}
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
        >

          <div className="card-body">
            <div className="row">
              <div>

                <FormControl fullWidth sx={{ m: 1 }} variant="standard"></FormControl>
                <TextField
                  style={{ width: '98%' }}
                  multiline={true}
                  label="Celular"
                  id="celular"
                  name="celular"
                  focused
                  defaultValue={data.celular}
                  onChange={(e) => props.setPaciente({ ...props.paciente, celular: e.target.value })}
                  size="small"
                />
              </div>
            </div>
            <div className="row">
              <div>
                <TextField
                  multiline={true}
                  style={{ width: '98%' }}
                  label="Telefone 1"
                  id="telefone1"
                  focused
                  name="telefone1"
                  fullWidth
                  defaultValue={data.telefone1}
                  onChange={(e) => props.setPaciente({ ...props.paciente, telefone1: e.target.value })}
                  size="small"
                />
              </div>


            </div>
            <div className="row">
              <div>
                <TextField
                  multiline={true}
                  style={{ width: '98%' }}
                  label="Telefone2"
                  id="telefone2"
                  focused
                  name="telefone2"
                  fullWidth
                  defaultValue={data.telefone2}
                  onChange={(e) => props.setPaciente({ ...props.paciente, telefone2: e.target.value })}
                  size="small"
                />
              </div>


            </div>
          </div>
        </Box>
      </div>
    </div >
  )
}

export default StepTres;