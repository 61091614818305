import React from 'react'

export default function Footer(){ 
    return (
        <div>
            {/* /.content-wrapper */}
            <footer className="main-footer text-center">
            </footer>
        </div>
    )
}