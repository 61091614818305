import React  from 'react';
import Swal from 'sweetalert2';
import Api from '../../Api';
import { useNavigate } from 'react-router-dom';
import globalState from '../Global';

// eslint-disable-next-line react/prop-types

export default function Dashboard(props) {
    const navigate = useNavigate();
    var login = localStorage.getItem('login');
    var token = JSON.parse(login);

    var baseURL = process.env.REACT_APP_baseURL + "/files/"

    const FileUpload = async () => {

        let formdata = new FormData();

        const { value: file } = await Swal.fire({
            title: 'Selecionar imagem',
            input: 'file',
            inputAttributes: {
                'accept': 'image/*',
                'aria-label': 'Carregue o seu arquivo de imagem'
            }
        })

        if (file) {
            formdata.append('file', file);

            var headers = {
                'headers': {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': 'Bearer ' + token.access_token,
                }
              }


            var result = await Api.post('/upload-single', formdata, headers);

            const reader = new FileReader()
            reader.onload = (e) => {
                Swal.fire({
                    title: 'Atenção',
                    text: "Paciente atualizado com sucesso!",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'OK'
                })
            }
            props.paciente.foto = result.data.file;
            const response = await Api.put('pacientes/' + props.paciente.id, props.paciente, globalState.value);
            reader.readAsDataURL(file)
            navigate('/paciente', { state: { id: props.paciente.id, key: props.paciente.id } });
        }

    }

    return (
        <div className="card-body">
            <div className="row">

                <div className="widget-user-header bg-warning">
                    <div className="widget-user-image">
                        <img className="img-circle elevation-2" src={baseURL + props.paciente?.foto} alt="Paciente" onClick={() => FileUpload()}></img>
                    </div>
                    <h3 className="widget-user-username">{props.paciente?.nome}</h3>
                    <h5 className="widget-user-desc">Celular : {props.paciente?.celular}</h5>
                    <h5 className="widget-user-desc">Email : {props.paciente?.email}</h5>
                </div>
                <div className="card-footer p-0">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <div className="nav-link">
                                Tratamentos <span className="float-right badge bg-primary">{props.tratamentos.length}</span>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className="nav-link">
                                Orçamentos <span className="float-right badge bg-info">{props.orcamentos.length}</span>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className="nav-link">      
                                Arquivos<span className="float-right badge bg-success">{props.arquivosCount}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}