import React, { useState, forwardRef, useEffect, useImperativeHandle } from 'react';
import Api from '../../Api';
import Swal from 'sweetalert2';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import globalState from '../Global';
import { ptBR } from '@mui/material/locale'
import { createTheme } from '@mui/material/styles';
import {
    DataGrid,
    GridActionsCellItem,
} from '@mui/x-data-grid';
import {
} from '@mui/x-data-grid-generator';



const ContasPagar = forwardRef((props, ref) => {
    const theme = createTheme(
        {
          palette: {
            mode: 'light',
          },
        },
        ptBR // Aplica a localização pt-BR ao tema do MUI
      );

    const useFakeMutation = () => {
        return React.useCallback(
            (user) =>
                new Promise((resolve, reject) => {
                    setTimeout(() => {
                        if (user.name?.trim() === '') {
                            reject(new Error("Error while saving user: name can't be empty."));
                        } else {
                            resolve({ ...user, name: user.name?.toUpperCase() });
                        }
                    }, 200);
                }),
            [],
        );
    };

    useImperativeHandle(ref, () => ({
        update() {
            getTabela(true);
        }
    }));

    const Sucesso = Swal.mixin({
        toast: true,
        // position: 'center',
        iconColor: 'green',
        customClass: {
            popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true
    });

    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

    const handleUpdateClick = (id) => () => {
    }

    const handleAddClick = () => () => {
    }

    const processRowUpdate = React.useCallback(

        async (newRow) => {
            const response_row = await Api.put('contas_pagar/' + newRow.id, newRow, globalState.value);
            const response = await mutateRow(newRow);
            setSnackbar({ children: 'Item atualizado', severity: 'success' });
            return response;
        },
        //[mutateRow],
    );

    const handleProcessRowUpdateError = React.useCallback((error) => {
    }, []);

    const handleDeleteClick = (id) => () => {
        Swal.fire({
            title: 'Deseja excluir o item?',
            text: "",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.isConfirmed) {
                var teste = contasPagar.filter((row) => row.id !== id);
                var response = Api.delete('contas_pagar/' + id, globalState.value);
                Sucesso.fire({
                    icon: 'success',
                    title: 'Item excluído'
                });
                setContas(teste);
            }

        })
    };

    const mutateRow = useFakeMutation();
    const [rows, setRows] = React.useState();
    const [snackbar, setSnackbar] = React.useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);


    const column = [
        { field: 'id', headerName: 'Código', width: 100, editable: true },
        { field: 'fornecedor', headerName: 'Fornecedor', width: 180, editable: true },
        { field: 'documento', headerName: 'Documento', width: 180, editable: true },
        { field: 'vencimento', headerName: 'vencimento', width: 100, editable: true },
        {
            field: 'valor',
            headerName: 'Valor',
            type: 'number',
            width: 180,
            editable: false,
            align: 'left',
            headerAlign: 'center',
            valueFormatter: ({ value }) => {
              if (!value) {
                return value;
              }
              return currencyFormatter.format(value);
            },
          },
        {
            field: 'estado_pagamento',
            headerName: 'Estado',
            type: 'singleSelect',
            width: 150,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            valueOptions: ({ row }) => {
              const options = [];
              if (['Criado'].includes(row.estado_pagamento)) {
                options.push('Criado', 'Pago');
              }
              if (['Pago'].includes(row.estado_pagamento)) {
                options.push('Criado','Pago');
              }
              return options;
            },
          },
        {
            field: 'actions1', type: 'actions', cellClassName: 'actions', headerName: 'Opções', width: 100, renderCell: (params) => {
                return [
                    <GridActionsCellItem
                    key={`delete-${params.row.id}`} 
                        icon={<DeleteIcon color="warning" />}
                        sx={{
                            color: 'primary.main',
                        }}
                        onClick={handleDeleteClick(params.row.id)}
                    />
                ];
            }
        },
    ]
    //const mutateRow = useFakeMutation();

    const getTabela = async (test) => {
        if (test === true) {
            try {
                const response = await Api.get('contas_pagar/', globalState.value);
                setContas(response.data.dado);
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        getTabela(true);
        const updateHeight = () => {
            const windowHeight = window.innerHeight;
            setHeight(windowHeight - 200);  // Subtrai algum valor para ajustes, se necessário
        };

        updateHeight();  // Atualiza a altura no carregamento

        window.addEventListener('resize', updateHeight);  // Atualiza a altura na mudança de tamanho da janela
        return () => window.removeEventListener('resize', updateHeight);
    }, []);

    const [contasPagar, setContas] = useState([]);
    const [height, setHeight] = useState(550);  // altura inicial

    return (
        <div style={{ height: 550, width: '100%' }}>

            <DataGrid
                localeText={globalState.ptBRLocaleText}
                rows={contasPagar || []} 
                columns={column}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
            />
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </div>
    )

});

export default ContasPagar;

ContasPagar.displayName = "ContasPagar";
