import React, { useState, forwardRef, useEffect, useImperativeHandle } from 'react';
import Api from '../../Api';
import Swal from 'sweetalert2';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import globalState from '../Global';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

const ContasReceber = forwardRef((props, ref) => {

    const useFakeMutation = () => {
        return React.useCallback(
            (user) => new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (user.name?.trim() === '') {
                        reject(new Error("Error while saving user: name can't be empty."));
                    } else {
                        resolve({ ...user, name: user.name?.toUpperCase() });
                    }
                }, 200);
            }),
            [],
        );
    };

    useImperativeHandle(ref, () => ({
        update() {
            getTabela(true);
        }
    }));

    const Sucesso = Swal.mixin({
        toast: true,
        iconColor: 'green',
        customClass: {
            popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true
    });

    const [ready, isReady] = useState(false);

    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const processRowUpdate = React.useCallback(
        async (newRow) => {
            await Api.put('contas_receber/' + newRow.id, newRow, globalState.value);
            const response = await mutateRow(newRow);
            setSnackbar({ children: 'Item atualizado', severity: 'success' });
            return response;
        },
    );

    const handleProcessRowUpdateError = React.useCallback((error) => {}, []);

    const handleDeleteClick = (id) => () => {
        Swal.fire({
            title: 'Deseja excluir o item?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.isConfirmed) {
                const updatedContas = contasReceber.filter((row) => row.id !== id);
                Api.delete('contas_receber/' + id, globalState.value);
                Sucesso.fire({ icon: 'success', title: 'Item excluído' });
                setContas(updatedContas);
            }
        })
    };
    const [height, setHeight] = useState(550);  // altura inicial
    const mutateRow = useFakeMutation();
    const [snackbar, setSnackbar] = React.useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    const column = [
        { field: 'pk_plano_pagamento', headerName: 'Tratamento', width: 100, editable: true }, 
        { field: 'historico', headerName: 'Histórico', width: 550, editable: true },
        { field: 'data', headerName: 'Data lançamento', width: 150 },
        { 
            field: 'valor', 
            headerName: 'Valor', 
            type: 'number', 
            width: 150, 
            valueFormatter: ({ value }) => value ? currencyFormatter.format(value) : value 
        },
        { field: 'estado', headerName: 'Estado', width: 150, type: 'singleSelect', align: 'center' },
        { 
            field: 'actions1', 
            type: 'actions', 
            headerName: 'Opções', 
            width: 100, 
            renderCell: (params) => [
                <GridActionsCellItem
                    key={`delete-${params.row.id}`} 
                    icon={<DeleteIcon color="warning" />}
                    onClick={handleDeleteClick(params.row.id)}
                />
            ]
        },
    ];

    const getTabela = async (reload) => {
        if (reload) {
            try {
                const response = await Api.get('contas_receber/', globalState.value);
                setContas(response.data.dado);
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        getTabela(true);
        isReady(true);
        const updateHeight = () => {
            const windowHeight = window.innerHeight;
            setHeight(windowHeight - 200);  // Subtrai algum valor para ajustes, se necessário
        };

        updateHeight();  // Atualiza a altura no carregamento

        window.addEventListener('resize', updateHeight);  // Atualiza a altura na mudança de tamanho da janela
        return () => window.removeEventListener('resize', updateHeight);
    }, []);

    const [contasReceber, setContas] = useState([]);

    return (  
        <div style={{ height: 550, width: '100%' }}>      
                {ready ?     
                <DataGrid 
                    localeText={globalState.ptBRLocaleText}
                    rows={contasReceber || []}
                    columns={column}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                />
                : <div>Carregando...</div>}
               
                {!!snackbar && (
                    <Snackbar
                        open
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        onClose={handleCloseSnackbar}
                        autoHideDuration={6000}
                    >
                        <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}
        </div>
    );
});

export default ContasReceber;

ContasReceber.displayName = "ContasReceber";