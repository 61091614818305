import React from 'react';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Sidenav from './Components/Sidenav';

export default function Ajuda() { 
    return (
        <div>
            <Header />
            <Sidenav /> 
            
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Ajuda</h1> {/* Adicionado conteúdo para acessibilidade */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            
                            {/* Left col */}
                            <section className="col-lg-6 connectedSortable">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">
                                            <i className="far fa-question-circle mr-1" /> {/* Ícone corrigido */}
                                            Ajuda
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content p-0">
                                            <div className="chart tab-pane active" id="revenue-chart" style={{ position: 'relative', height: 300 }}>
                                                <canvas id="revenue-chart-canvas" height={300} style={{ height: 300 }} />
                                            </div>
                                            <div className="chart tab-pane" id="sales-chart" style={{ position: 'relative', height: 300 }}>
                                                <canvas id="sales-chart-canvas" height={300} style={{ height: 300 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            
                            {/* Right col */}
                            <section className="col-lg-6 connectedSortable">
                                {/* Espaço reservado para futuros conteúdos */}
                            </section>

                        </div>
                    </div>
                </section>
            </div>

            <Footer />
        </div>
    );
}

