import React from 'react';
import { useState } from 'react';
//import ModalEditUser from './Modals/ModalEditUser';
import ModalEditUser from "./Modals/ModalEditarUsuario";
import globalState from './Global';
import Api from './../Api'

export default function Header() {
  // const MySwal = withReactContent(Swal);
  // const navigate = useNavigate();
  // const [error, setError] = useState('');
  const [item, setItem] = useState([]);
  const [showModalEditUser, setShowModalEditUser] = useState(false);

  async function editUser() {
    var login = localStorage.getItem('login');
    var token = JSON.parse(login);
    var id = token.id;
    var response1 = await Api.get('usuarios/' + id, globalState.value);
    setItem(response1.data.tabela[0]);
    setShowModalEditUser(true);
  }


  // async function doSignUpDirect() {
  //     setError('');
  //     const { value: wallet } = await Swal.fire({
  //       title: 'Insira a sua chave',
  //       icon: 'question',
  //       input: 'text',
  //       inputLabel: '',
  //       inputPlaceholder: 'Entre sua chave privada'
  //     })

  //     if (wallet) {
  //       localStorage.setItem('wallet', wallet);
  //       navigate("/pacientes");
  //       navigate(0);
  //     }
  //   }

  return (
    <div>
      {/* Navbar */}
      <nav className="main-header navbar navbar-expand navbar-dark">

        <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
          {/* <li className="nav-item">
                    <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button" onClick={editUser}>
                      <i className="fa fa-cog" />
                    </a>
                  </li> */}
          <li className="nav-item">
            <button
              className="nav-link"
              data-widget="control-sidebar"
              data-slide="true"
              onClick={editUser}
              style={{ background: "none", border: "none", padding: 0, textAlign: "left", cursor: "pointer" }}
            >
              <i className="fa fa-cog" />
            </button>
          </li>

        </ul>
        <ModalEditUser onClose={() => { setShowModalEditUser(false); }} show={showModalEditUser} backdrop={"static"} keyboard={false} header="" type={"user"} item={item} />

      </nav>
      {/* /.navbar */}
    </div>

  )
}
