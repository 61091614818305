import Header from './Components/Header';
import Footer from './Components/Footer';
import Sidenav from './Components/Sidenav';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import Api from './Api'
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState, useMemo} from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import globalState from './Components/Global';

export default function Pacientes() {
    const navigate = useNavigate();

    const getItems = async () => {
        const response = await Api.get('pacientes/aniversariantes', globalState.value);
        setPacientes(response.data);
    };

    const getAgendas = async () => {
        const response = await Api.get('agendas/data', globalState.value);
        setAgendas(response.data);
    };

    useEffect(() => {
        getItems();
        getAgendas();
    }, []);

    const [pacientes, setPacientes] = useState([]);
    const [agendas, setAgendas] = useState([]);

    const handleRowClick = (row) => {
        navigate('/paciente', { state: { id: row.id, key: row.id } });
    }
    const handleRowClick2 = (row) => {
        navigate('/paciente', { state: { id: row.row.cod_paciente, key: row.row.cod_paciente } });
    }


    const columnsAgenda = [
        // { field: 'id', headerName: 'Cód', width: 20,  editable: false },
        { field: 'cod_paciente', headerName: 'codigo', width: 100, editable: true },
        { field: 'title', headerName: 'Nome', width: 300, editable: false },
        {
            field: 'start', headerName: 'Inicio', width: 100, editable: false, resizable: false, valueFormatter: params =>
                moment(params?.value).format("hh:mm"),
        },
        {
            field: 'end', headerName: 'Fim', width: 100, editable: false, resizable: false, valueFormatter: params =>
                moment(params?.value).format("hh:mm"),
        },
    ];


    const columns = useMemo(
        () => [
            { field: 'nome', headerName: 'Nome', width: 250, editable: true },
            {
                field: 'celular',
                headerName: 'celular',
                width: 200,
                type: 'number',
                editable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: 'email',
                headerName: 'email',
                width: 200,
                type: 'number',
                editable: false,
                align: 'center',
                headerAlign: 'center',
            },
        ],
        [],
    );


    return (
        <div>
            <Header />
            <Sidenav />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}
                        {/* /.row */}
                        {/* Main row */}

                        <div className="row">
                            <div className="col-lg-6 col-6">
                                {/* small box */}
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{pacientes.count}</h3>
                                        <p>Aniversariantes do dia</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-bag" />
                                    </div>
                                    <li key="agenda" className="small-box-footer">
                                                <Link to="/Pacientes" className="nav-link">Pacientes <i className="fas fa-arrow-circle-right" />
                                                </Link>
                                    </li>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-6 col-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{agendas.count}</h3>
                                        <p>Atendimentos no dia</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars" />
                                    </div>
                                    <li key="agenda" className="small-box-footer">
                                                <Link to="/Agenda" className="nav-link">Agenda <i className="fas fa-arrow-circle-right" />
                                                </Link>
                                    </li>
                                </div>
                            </div>
                            {/* ./col */}

                            {/* ./col */}
                        </div>
                        {/* /.row (main row) */}
                    </div>{/* /.container-fluid */}
                    <Box
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            p: 1,
                        }}
                    >
                    </Box>
                    <div className="row">
                        <div className="col-lg-6 col-6">

                            <div className="card">
                            <div className="card-header"><b>Aniversariantes</b>
                                <div style={{ height: '400px', width: '100%', margin: 'auto' }}>
                                    {pacientes?.rows !== undefined &&
                                        <DataGrid
                                            rowHeight={20}
                                            pageSize={100} // Valor do tamanho da página
                                            pageSizeOptions={[5, 10, 20, 50, 100]} // Inclua 100 aqui
                                            initialState={{
                                                columns: {
                                                    columnVisibilityModel: {
                                                        // Hide columns status and traderName, the other columns will remain visible
                                                        status: false,
                                                        traderName: false,
                                                    }
                                                },
                                            }}
                                            localeText={globalState.ptBRLocaleText}
                                            rows={pacientes?.rows}

                                            onRowClick={handleRowClick}
                                            columns={columns}
                                        />
                                    }
                                </div>
                            </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-6">
                        <div className="card">
                            <div className="card-header"> <b>Agendamentos de hoje</b>
                                <div style={{ height: 400, width: '100%', margin: 'auto' }}>
                                    {agendas?.rows !== undefined &&

                                        <DataGrid
                                            rowHeight={25}
                                            pageSize={100} // Valor do tamanho da página
                                            pageSizeOptions={[5, 10, 20, 50, 100]} // Inclua 100 aqui
                                            initialState={{
                                                columns: {
                                                    columnVisibilityModel: {
                                                        // Hide columns status and traderName, the other columns will remain visible
                                                        status: false,
                                                        traderName: false,
                                                    }
                                                },
                                            }}
                                            localeText={globalState.ptBRLocaleText}
                                            rows={agendas?.rows}

                                            onRowClick={handleRowClick2}
                                            columns={columnsAgenda}
                                        />
                                    }
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            <Footer />
        </div>
    )

}
