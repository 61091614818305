import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import 'bootstrap/dist/css/bootstrap.min.css';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Api from '../../Api';
import moment from 'moment';
import Swal from 'sweetalert2';
import Snackbar from '@mui/material/Snackbar';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Alert from '@mui/material/Alert';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import globalState from '../Global';

const useFakeMutation = () => {
  return React.useCallback(
    (user) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          if (user.name?.trim() === '') {
            reject(new Error("Error while saving user: name can't be empty."));
          } else {
            resolve({ ...user, name: user.name?.toUpperCase() });
          }
        }, 200);
      }),
    [],
  );
};

const Modal1 = props => {
  moment.locale('pt-br');

  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const closeOnEscapeKeyDown = e => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  const rmItem = async (id) => {
    var response = await Api.delete('plano_pagamento/' + id, globalState.value);
    await props.getItemsPagamento(props.idtrat);
  }

  const Mensagem = Swal.mixin({
    toast: true,
    // position: 'center',
    iconColor: 'green',
    customClass: {
      popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true
  });

  const Erro = Swal.mixin({
    toast: true,
    // position: 'center',
    iconColor: 'red',
    customClass: {
      popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true
  });

  function EditToolbar(props) {    
    const handleAddRow = async (reg, idtrat) => {
      var parc = currencyFormatter.format(props.props.total-props.props.itemsPlanoPagamento?.total);
      var teste = props.props.total-props.props.itemsPlanoPagamento?.total;
      

      if (props.props.total === props.props.itemsPlanoPagamento?.total){
        Erro.fire({
          icon: 'warning',
          title: 'Parcelamento já existe'
        });
      } else {
        const { value: formValues } = await Swal.fire({
          html:
            '<label for="swal-input2">Valor' +
            '<input id="swal-input1" class="swal2-input" data-type="currency" placeholder="Valor">' +
            '<label for="swal-input3">Data' +
            '<input type="date" id="swal-input2" class="swal2-input">' +
            '</label>',
          focusConfirm: false,
          preConfirm: () => {
            return [
              document.getElementById('swal-input1').value,
              document.getElementById('swal-input2').value
            ]
          }
        })
  
        // var data = moment.utc()
        // .utcOffset('-03:00')
        // .format('DD/MM/YYYY');
        
        teste = props.props.total-props.props.itemsPlanoPagamento?.total;
        const data = moment(formValues[1]).format('DD/MM/YYYY');

        if (teste >= formValues[0])  {
          try {
            let block = {
              "parcela": reg,
              "cod_tratamento": idtrat,
              "valor": formValues[0],
              "estado": "Não paga",
              "data": data,
              "observacao": ""
            };
  
            const response = await Api.post('plano_pagamento/', block, globalState.value);
  
            if (response.status === 200) {
              props.props.getItemsPagamento(idtrat);
              Mensagem.fire({
                icon: 'success',
                title: 'Item criado'
              });
            }
  
          } catch (e) {
            Erro.fire({
              icon: 'warning',
              title: 'Valor está errado'
            });
          }
        } else {
          Erro.fire({
            icon: 'warning',
            title: 'Valor é maior que o total'
          });
        }
      }   
      
    };
    
    function addMonths(date, months) {
      date.setMonth(date.getMonth() + months);
    
      return date;
    }

    const handleWizard = async (idtrat) => {
      
      // .utcOffset('-03:00')
      // .format('DD/MM/YYYY');

      const { value: formValues } = await Swal.fire({
        html:
          '<label for="swal-input1">Número de Parcelas' +
          '<select id="swal-input1" class="swal2-input"> <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="7">7</option><option value="8">8</option><option value="9">9</option><option value="10">10</option>' +
          '</select>' +
          '</label>' ,
        focusConfirm: false,
        preConfirm: () => {
          return [
            document.getElementById('swal-input1').value
          ]
        }
      })

      

      if (formValues) {
        try {
          const parcela = props.props.total/formValues[0];
          const data = moment(Date()).format('DD/MM/YYYY')

          let block2 = {
            "valor": props.props.total,
            "historico": `Valor a receber do plano de tratamento paciente ${props.props.cod_paciente}`,
            "pk_clinica": 1,
            "data": String(data),
            "pk_plano_pagamento": idtrat,
            "estado": "Não paga",
        };
        
          const response2 = await Api.post('contas_receber/', block2, globalState.value);

          for (let i = 0; i < formValues[0]; i++) {
            const data = new Date();
            const result = data.setMonth(data.getMonth() + i);
            const result1 = moment(result).format('DD/MM/YYYY')
            let block = {
              "parcela": i,
              "cod_tratamento": idtrat,
              "valor": parcela,
              "estado": "Não paga",
              "data": result1,
              "observacao": ""
            };
            
            const response = await Api.post('plano_pagamento/', block, globalState.value);

          }  
          props.props.getItemsPagamento(idtrat);
          Mensagem.fire({
            icon: 'success',
            title: 'Itens criados'
          });

        } catch (e) {
          Erro.fire({
            icon: 'warning',
            title: 'Valor está errado'
          });
        }
      }
    };

    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => [...oldRows, { id, descricao: '', valor: '', isNew: true }]);

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'descricao' },
      }));
    };

    return (
      
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          p: 1
        }}
      >
        <GridToolbarContainer props={props}>
        <div><b>Total do tratamento : </b>{currencyFormatter.format(props.props.total)}</div>
        </GridToolbarContainer>
        <GridToolbarContainer props={props}>
        <div><b>Total do plano pagamento : </b>{currencyFormatter.format(props.props.itemsPlanoPagamento?.total)}</div>
       
        </GridToolbarContainer>
        <GridToolbarContainer props={props}>
        <div><b>Valor a parcelar : </b>{currencyFormatter.format(props.props.total-props.props.itemsPlanoPagamento?.total)}</div>
        <div><b>Registros : </b>{props.props.itemsPlanoPagamento.tabela?.length}</div>
        <div>
          <Button color="primary" startIcon={<AddIcon />} onClick={() => {
            handleAddRow(props.props.itemsPlanoPagamento.tabela?.length, props.props.idtrat,(props.props.total-props.props.itemsPlanoPagamento?.total));
          }}>
          </Button>
          { props.props.itemsPlanoPagamento.tabela?.length === 0 &&
          <Button color="warning" startIcon={<AutoFixHighIcon />} onClick={() => {
            handleWizard(props.props.idtrat);
          }}>
          </Button>
          }
          </div>  
        </GridToolbarContainer>
      </Box>
      
    );
  }

  const currencyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  //const [rows, setRows] = React.useState(props.itemsOrcamento?.tabela);
  const mutateRow = useFakeMutation();

  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);
  const [cont, setCont] = React.useState();
  const [rows, setRows] = React.useState();
  const [editRow, setEditRow] = React.useState({});
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStop = async (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
    await props.getItemsPagamento(params.row.cod_tratamento);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id, rows) => () => {
    setRowModesModel({ ...rowModesModel, [editRow.id]: { mode: GridRowModes.View } });
  };
  
  const [status, setStatus] = React.useState('connected');

  const handleDeleteClick = (id) => () => {
    Swal.fire({
      title: 'Deseja excluir o item?',
      text: "",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        rmItem(id);
        setRows(rows.filter((row) => row.id !== id));

        Mensagem.fire({
          icon: 'success',
          title: 'Orçamento excluído'
        });

      }
    })


  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      var response_data = await Api.put('plano_pagamento/' +  newRow.id,  newRow, globalState.value);
      const response = await mutateRow(newRow);
      await props.getItemsPagamento(newRow.cod_tratamento);
      setSnackbar({ children: 'Registro atualizado!', severity: 'success' });
      return response;

    },
    
    [mutateRow],
  );

  const mySaveOnServerFunction = () => {
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: 'parcela',
      headerName: 'Parc',
      width: 50,
      editable: false,
    },
    {
      field: 'valor',
      headerName: 'Valor',
      width: 120,
      editable: false,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return currencyFormatter.format(value);
      },
    },
   
    {
      field: 'estado',
      headerName: 'Estado',
      width: 120,
      editable: true,
      align: 'center',
      headerAlign: 'center',
      type: 'singleSelect',
      valueOptions: ["Não paga", "Paga"]
    },
    {
      field: 'data',
      headerName: 'Data',
      width: 150,
      editable: false,
    },
    {
      field: 'observacao',
      align: 'center',
      headerAlign: 'center',
      headerName: 'Observação',
      width: 350,
      editable: true,
    },
    {
      field: 'actions1', headerName: 'Excluir', width: 100, renderCell: (params) => {
        return (
          
          <IconButton color="error"
            onClick={handleDeleteClick(params.row.id)}
            variant="contained"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        );
      }
    },
  ];

  
  

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  let test = false;
  //setCont(props.itemsPlanoPagamento.tabela?.lengt);

  if (props.itemsPlanoPagamento.tabela?.length > 0) {
    test = true;
  }

  return ReactDOM.createPortal(
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div className="modal" onClick={props.onClose}>
        <div className="modal-content" style={{width: '900px'}} onClick={e => e.stopPropagation()}>
          <div className="modal-header">
            <h4 className="modal-title">Plano de pagamento Cód. {props.idtrat}</h4>
            <button type="button" className="close" onClick={props.onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
          
            {test === true &&
              <Box
                sx={{
                  'height': '500px',
                  'width': '100%',
                  '& .actions': {
                    color: 'text.secondary',
                  },
                  '& .textPrimary': {
                    color: 'text.primary',
                  },
                }}
              >
                <DataGrid
                  rowHeight={25}
                  rows={props.itemsPlanoPagamento?.tabela}
                  columns={columns}
                  editMode="row"
                  //onCellEditCommit={handleRowEditCommit}
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  processRowUpdate={processRowUpdate}
                  onProcessRowUpdateError={handleProcessRowUpdateError}
                  slots={{
                    toolbar: EditToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                      props,
                      setRows,
                      setRowModesModel,
                      editRow,
                    },
                    footer1: { status, props },
                  }}
                  localeText={globalState.ptBRLocaleText}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                />
                {!!snackbar && (
                  <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                  >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                  </Snackbar>
                )}
  
              </Box>
              
            }
            {test === false &&
              <Box
              sx={{
                'height': '500px',
                'width': '100%',
                '& .actions': {
                  color: 'text.secondary',
                },
                '& .textPrimary': {
                  color: 'text.primary',
                },
              }}
            >
              <DataGrid
                rowHeight={25}
                rows=""
                columns={columns}
                editMode="row"
                //onCellEditCommit={handleRowEditCommit}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                    props,
                    setRows,
                    setRowModesModel,
                    editRow,
                  },
                  footer1: { status, props },
                }}
                localeText={globalState.ptBRLocaleText}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
              />
              {!!snackbar && (
                <Snackbar
                  open
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  onClose={handleCloseSnackbar}
                  autoHideDuration={6000}
                >
                  <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
              )}
            </Box>
            }
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById("root")
  );
};

export default Modal1;