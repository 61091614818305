import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { DataGrid, GridCellModes } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import Button from '@mui/material/Button';
import PlusIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Api from '../../Api';
import globalState from '../Global';

const useFakeMutation = () => {
  return React.useCallback(
    (user) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          if (user.name?.trim() === '') {
            reject(new Error("Error while saving user: name can't be empty."));
          } else {
            resolve({ ...user, name: user.name?.toUpperCase() });
          }
        }, 200);
      }),
    [],
  );
};


const TableArquivos = ({ data, setIDPRONT, getArquivos, cod_paciente }) => {
  //const ref_prontuarios = useRef();
  var baseURL = process.env.REACT_APP_baseURL + "/files/"

  const FileUpload = async (cod_paciente) => {
    var login = localStorage.getItem('login');
    var token = JSON.parse(login);

    let formdata = new FormData();

    const { value: file } = await Swal.fire({
      title: 'Selecionar arquivo',
      input: 'file',
      inputAttributes: {
        'accept': 'image/*',
        'aria-label': 'Carregue o seu arquivo de imagem'
      }
    })

    if (file) {
      formdata.append('file', file);

      var headers = {
        'headers': {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + token.access_token,
        }
      }

      var result = await Api.post('/upload-single', formdata, headers);

      const reader = new FileReader()
      reader.onload = (e) => {
        Swal.fire({
          title: 'Arquivo atualizado',
          //imageUrl: e.target.result,
          imageAlt: 'Arquivo carregado'
        })


      }

      let block = {
        "arquivo": result.data.file,
        "descricao": "Imagem de tratamento",
        "cod_tratamento": 0,
        "cod_paciente": cod_paciente
      };

      const response = await Api.post('imagenstratamento/', block, globalState.value);
      if (response.status === 200) {
        getLocalArquivos(cod_paciente);
        Sucesso.fire({
          icon: 'success',
          title: 'Arquivo inserido'
        });
      }
      if (response.status === 201) {
        Sucesso.fire({
          icon: 'warning',
          title: 'Registro já existe!!!'
        });
      }
      //reader.readAsDataURL(file)
      //navigate("/paciente");
    }

  }

  const [arquivos, setArquivos] = useState([]);

  const getLocalArquivos = async (cod_paciente) => {
    const response = await Api.get('imagenstratamento/paciente/' + cod_paciente, globalState.value);
    setArquivos(response.data.rows);
  };

  useEffect(() => {
    getLocalArquivos(cod_paciente);
    
  }, []);


  const handleViewEdit = async (row) => {
    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputLabel: 'Mensagem',
      inputPlaceholder: 'Digite aqui sua mensagem',
      inputValue: row.descricao,
      width: 600,
      height: 400,
      cancelButtonText: 'Cancela',
      confirmButtonText: 'Salvar',
      inputAttributes: {
        'aria-label': 'Digite aqui sua mensagem'
      },
      showCancelButton: true
    })

    if (text) {
      let block = {
        "descricao": text,
        "cod_paciente": row.cod_paciente,
        "data": row.data
      };

      const response = await Api.put('prontuario/' + row.id, block);
      getLocalArquivos(row.cod_paciente);
    }
  };


  const handleDeleteClick = (id, arquivo) => () => {


    Swal.fire({
      title: 'Deseja excluir o arquivo?',
      text: "",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        var teste = arquivos.filter((row) => row.id !== id);
        var response = Api.delete('imagenstratamento/' + id, globalState.value);
//        var response2 = Api.post('remove-single/' + arquivo, globalState.value);
        setArquivos(teste);
        Sucesso.fire({
          icon: 'success',
          title: 'Arquivo excluído'
        });
      }

    })
  };


  const columns = [
    { field: 'id', headerName: 'Cód', width: 20, editable: false },
    // { field: 'cod_tratamento', headerName: 'Tratamento', width: 100, editable: true },
    {
      field: 'arquivo',
      headerName: 'Arquivo',
      width: 400,
      type: 'number',
      editable: false,
      align: 'left',
      headerAlign: 'center',
    },
    {
      field: 'actions1', headerName: 'Excluir', width: 70, renderCell: (params) => {
        return (

          <IconButton color="error"
            onClick={handleDeleteClick(params.row.id, params.row.arquivo)}
            variant="contained"
          >
            {/* {cellMode === 'edit' ? 'Salvar' : 'Editar'} */}
            <DeleteIcon fontSize="small" />
          </IconButton>

        );
      }
    },
    {
      field: 'actions2', headerName: 'Download', width: 100, renderCell: (params) => {
        return (
          <IconButton color="primary"
            href={baseURL + params.row.arquivo}
            variant="contained"
          >
          <ArrowDownwardIcon fontSize="small" />
          </IconButton>

        );
      }
    },
    // {
    //   field: 'actions3', headerName: 'Preview', width: 200, renderCell: (params) => {
    //     return (

    //       <IconButton color="success"
    //         onClick="{handleDeleteClick(params.row.id, params.row.arquivo)}"
    //         variant="contained"
    //       >
    //         <VisibilityIcon fontSize="small" />
    //       </IconButton>

    //     );
    //   }
    // }
  ];

  const mutateRow = useFakeMutation();
  const [rows, setRows] = React.useState();
  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const Sucesso = Swal.mixin({
    toast: true,
    // position: 'center',
    iconColor: 'green',
    customClass: {
      popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true
  });

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      // Make the HTTP request to save in the backend
      const response = await mutateRow(newRow);
      setSnackbar({ children: 'User successfully saved', severity: 'success' });
      return response;
    },
    [mutateRow],
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const EditToolbar = (props) => {

    const { selectedCellParams, cellMode, cellModesModel, setCellModesModel } = props;
    const handleClick = () => {

    };


    const handleSaveOrEdit = () => {
      if (!selectedCellParams) {
        return;
      }
      const { id, field } = selectedCellParams;
      if (cellMode === 'edit') {
        setCellModesModel({
          ...cellModesModel,
          [id]: { ...cellModesModel[id], [field]: { mode: GridCellModes.View } },
        });
      } else {
        setCellModesModel({
          ...cellModesModel,
          [id]: { ...cellModesModel[id], [field]: { mode: GridCellModes.Edit } },
        });
      }
    };

    const handleCancel = () => {
      if (!selectedCellParams) {
        return;
      }
      const { id, field } = selectedCellParams;
      setCellModesModel({
        ...cellModesModel,
        [id]: {
          ...cellModesModel[id],
          [field]: { mode: GridCellModes.View, ignoreModifications: true },
        },
      });
    };

    const handleMouseDown = (event) => {
      // Keep the focus in the cell
      event.preventDefault();
    };


    const handleAddRow = async () => {
      var data = moment.utc()
        .utcOffset('-03:00')
        .format('DD/MM/YYYY');

      const { value: text } = await Swal.fire({
        input: 'textarea',
        inputLabel: 'Mensagem',
        inputPlaceholder: 'Digite aqui sua mensagem',
        width: 600,
        height: 400,
        cancelButtonText: 'Cancela',
        confirmButtonText: 'Salvar',
        inputAttributes: {
          'aria-label': 'Digite aqui sua mensagem'
        },
        showCancelButton: true
      })

      if (text) {
        let block = {
          "descricao": text,
          "cod_paciente": cod_paciente,
          "data": data
        };

        //const response = await Api.post('prontuario/', block);
        //console.log(response.status)

        // if (response.status === 200) {
        //   getLocalProntuarios(cod_paciente);
        //   Sucesso.fire({
        //     icon: 'success',
        //     title: 'Prontuario criado'
        //   });
        // }
        // if (response.status === 201) {
        //   Sucesso.fire({
        //     icon: 'warning',
        //     title: 'Registro já existe!!!'
        //   });
        // }
      }
    };



    return (
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          p: 1,
        }}
      >
        <Button variant="outlined" size="small"  onClick={() => { FileUpload(cod_paciente) }}
          onMouseDown={handleMouseDown} startIcon={<PlusIcon />}>
          Novo
        </Button>
      </Box>
    );
  }

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <DataGrid
        autoHeight
        rowHeight={25}
        //localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={arquivos}
        slots={{
          toolbar: EditToolbar,
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible
              status: false,
              traderName: false,
            },
            getLocalArquivos,
            cod_paciente,
          },
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
          getLocalArquivos,
          cod_paciente
        }}
        columns={columns}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
}


export default TableArquivos;

TableArquivos.displayName = "TableArquivos";
