import Header from './Components/Header';
import Footer from './Components/Footer';
import Sidenav from './Components/Sidenav';
import React, { useState, useEffect, useRef } from 'react';
import ContasPagar from "./Components/tabs/contasPagar";
import ContasReceber from "./Components/tabs/contasReceber";
import Estoque from "./Components/tabs/estoque";
import Planos from "./Components/tabs/planos";
import Proteses from "./Components/tabs/proteses";
import Recibos from "./Components/tabs/recibos";
import ModalNovoOrc from "./Components/Modals/ModalNovo";
import Button from '@mui/material/Button';
import ModalEdit from "./Components/Modals/ModalEditBasicos";
import Swal from 'sweetalert2';
import PlusIcon from '@mui/icons-material/Add';
import { ptBR } from '@mui/material/locale'
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function Clinica() {

    const theme = createTheme(
        {
          palette: {
            mode: 'light',
          },
        },
        ptBR // Aplica a localização pt-BR ao tema do MUI
      );

    const handleClose = () => setShowModalNovo(false);
    const handleCloseEdit = () => setShowModalEdit(false);
    const handleShowEdit = () => setShowModalEdit(true);

    const [showModalNovoOrc, setShowModalNovo] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);

    const [tabela, setTabela] = useState(" ");
    const [id, setID] = useState(false);

    const ref_recibos = useRef();
    const ref_contas_pagar = useRef();
    const ref_contas_receber = useRef();
    const ref_estoque = useRef();
    const ref_plano = useRef();
    const ref_proteses = useRef();
    const handleMouseDown = (event) => {
        // Keep the focus in the cell
        event.preventDefault();
      };

    const Sucesso = Swal.mixin({
        toast: true,
        // position: 'center',
        iconColor: 'green',
        customClass: {
            popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true
    });

    const Falha = Swal.mixin({
        toast: true,
        position: 'center',
        iconColor: 'falha',
        customClass: {
            popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true
    });

    const updateTable = async (tabela) => {
        //ref_contas_pagar.current.update();
        if (tabela === 'recibos') {
            ref_recibos.current.update();
        } else if (tabela === 'contas_pagar') {
            ref_contas_pagar.current.update();
        } else if (tabela === 'contas_receber') {
            ref_contas_receber.current.update();
        } else if (tabela === 'estoques') {
            ref_estoque.current.update();
        } else if (tabela === 'planos_conta') {
            ref_plano.current.update();
        } else if (tabela === 'proteses') {
            ref_proteses.current.update();
        }
    }

    useEffect(() => {

    }, []);

    return (
        <ThemeProvider theme={theme}>
        <div>
            <Header />
            <Sidenav />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Clinica</h1>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row"></div>
                        <div className="col-md-12">
                            <div className="card card-primary card-tabs">
                                <div className="card-header p-0 pt-1">
                                    <ul className="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="custom-tabs-one-pagar-tab" data-toggle="pill" href="#custom-tabs-one-pagar" role="tab" aria-controls="custom-tabs-one-pagar" aria-selected="false">Contas a pagar</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-receber-tab" data-toggle="pill" href="#custom-tabs-one-receber" role="tab" aria-controls="custom-tabs-one-receber" aria-selected="false">Contas a receber</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-estoque-tab" data-toggle="pill" href="#custom-tabs-one-estoque" role="tab" aria-controls="custom-tabs-one-estoque" aria-selected="false">Estoque</a>
                                        </li>
                                        {/* <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-planos-tab" data-toggle="pill" href="#custom-tabs-one-planos" role="tab" aria-controls="custom-tabs-one-planos" aria-selected="false">Planos de conta</a>
                                        </li> */}
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-pro-tab" data-toggle="pill" href="#custom-tabs-one-pro" role="tab" aria-controls="custom-tabs-one-pro" aria-selected="false">Proteses</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-recibos-tab" data-toggle="pill" href="#custom-tabs-one-recibos" role="tab" aria-controls="custom-tabs-one-recibos" aria-selected="false">Recibos</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content" id="custom-tabs-one-tabContent">
                                        <div className="tab-pane fade show active" id="custom-tabs-one-pagar" role="tabpanel" aria-labelledby="custom-tabs-one-pagar-tab">
                                            {/* <Button className="btn btn-primary btn-sm btn-success" 
                                            onClick={() => { setShowModalNovo(true); setTabela('contas_pagar'); }}><i className="nav-icon fas fa-plus" />
                                            </Button> */}
                                            <Button variant="outlined" size="small"  
                                                onClick={() => { setShowModalNovo(true); setTabela('contas_pagar'); }}
                                                onMouseDown={handleMouseDown}
                                                startIcon={<PlusIcon />}>
                                                Novo
                                            </Button>
                                            <div className="table-responsive">
                                                <ContasPagar handleShowEdit={handleShowEdit} handleCloseEdit={handleCloseEdit} setID={setID} setTabela={setTabela} Sucesso={Sucesso} Falha={Falha} ref={ref_contas_pagar} updateTable={updateTable} />
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="custom-tabs-one-receber" role="tabpanel" aria-labelledby="custom-tabs-one-receber-tab">
                                            <Button variant="outlined" size="small"  
                                                onClick={() => { setShowModalNovo(true); setTabela('contas_receber'); }}
                                                onMouseDown={handleMouseDown}
                                                startIcon={<PlusIcon />}>
                                                Novo
                                            </Button>
                                            <div className="table-responsive">
                                                <ContasReceber handleShowEdit={handleShowEdit} handleCloseEdit={handleCloseEdit} setID={setID} setTabela={setTabela} Sucesso={Sucesso} Falha={Falha} ref={ref_contas_receber} updateTable={updateTable} />
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="custom-tabs-one-estoque" role="tabpanel" aria-labelledby="custom-tabs-one-estoque-tab">
                                            <Button variant="outlined" size="small"  
                                                onClick={() => { setShowModalNovo(true); setTabela('estoques'); }}
                                                onMouseDown={handleMouseDown}
                                                startIcon={<PlusIcon />}>
                                                Novo
                                            </Button>
                                            <div className="table-responsive">
                                                <Estoque handleShowEdit={handleShowEdit} handleCloseEdit={handleCloseEdit} setID={setID} setTabela={setTabela} Sucesso={Sucesso} Falha={Falha} ref={ref_estoque} updateTable={updateTable} />
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="custom-tabs-one-planos" role="tabpanel" aria-labelledby="custom-tabs-one-planos-tab">
                                            <Button variant="outlined" size="small"  
                                                onClick={() => { setShowModalNovo(true); setTabela('planos_conta'); }}
                                                onMouseDown={handleMouseDown}
                                                startIcon={<PlusIcon />}>
                                                Novo
                                            </Button>
                                            <div className="table-responsive">
                                                <Planos handleShowEdit={handleShowEdit} handleCloseEdit={handleCloseEdit} setID={setID} setTabela={setTabela} Sucesso={Sucesso} Falha={Falha} ref={ref_plano} updateTable={updateTable} />
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="custom-tabs-one-pro" role="tabpanel" aria-labelledby="custom-tabs-one-pro-tab">
                                            <Button variant="outlined" size="small"  
                                                onClick={() => { setShowModalNovo(true); setTabela('proteses'); }}
                                                onMouseDown={handleMouseDown}
                                                startIcon={<PlusIcon />}>
                                                Novo
                                            </Button>
                                            <div className="table-responsive">
                                                <Proteses handleShowEdit={handleShowEdit} handleCloseEdit={handleCloseEdit} setID={setID} setTabela={setTabela} Sucesso={Sucesso} Falha={Falha} ref={ref_proteses} updateTable={updateTable} />
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="custom-tabs-one-recibos" role="tabpanel" aria-labelledby="custom-tabs-one-recibos-tab">
                                            <Button variant="outlined" size="small"  
                                                onClick={() => { setShowModalNovo(true); setTabela('recibos'); }}
                                                onMouseDown={handleMouseDown}
                                                startIcon={<PlusIcon />}>
                                                Novo
                                            </Button>
                                            <div className="table-responsive">
                                                <Recibos handleShowEdit={handleShowEdit} handleCloseEdit={handleCloseEdit} setID={setID} setTabela={setTabela} Sucesso={Sucesso} Falha={Falha} ref={ref_recibos} updateTable={updateTable} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* /.row (main row) */}
                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <ModalNovoOrc title={tabela}
                onClose={() => {
                    setShowModalNovo(false)
                    if (tabela === 'recibos') {
                        ref_recibos.current.update();
                    } else if (tabela === 'contas_pagar') {
                        ref_contas_pagar.current.update();
                    } else if (tabela === 'contas_receber') {
                        ref_contas_receber.current.update();
                    } else if (tabela === 'estoques') {
                        ref_estoque.current.update();
                    } else if (tabela === 'planos_conta') {
                        ref_plano.current.update();
                    } else if (tabela === 'proteses') {
                        ref_proteses.current.update();
                    }
                }}

                show={showModalNovoOrc} handleClose={handleClose} id="0" Sucesso={Sucesso} Falha={Falha} />

            <ModalEdit
                onClose={() => {
                    setShowModalEdit(false);
                    if (tabela === 'recibos') {
                        ref_recibos.current.update();
                    } else if (tabela === 'contas_pagar') {
                        ref_contas_pagar.current.update();
                    } else if (tabela === 'contas_receber') {
                        ref_contas_receber.current.update();
                    } else if (tabela === 'estoques') {
                        ref_estoque.current.update();
                    } else if (tabela === 'planos_conta') {
                        ref_plano.current.update();
                    } else if (tabela === 'proteses') {
                        ref_proteses.current.update();
                    }
                }}
                show={showModalEdit}
                id={id}
                tabela={tabela}
                Sucesso={Sucesso}
                Falha={Falha}
            />


            <Footer />
        </div>
        </ThemeProvider>
    )
}
