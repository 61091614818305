import React, { useEffect } from 'react';

// eslint-disable-next-line react/prop-types
const StepSeis= (props) => {

  useEffect(() => {
  }, [])

  return (
    <div className="card">
      <div className="card-body">
            <div className="row">
            <div className="modal-body">
              <div>
                <div>
                  <b>Nome :</b> {props.paciente?.nome}
                </div>
                <div>
                  <b>Endereço :</b> {props.paciente?.endereco}
                </div>
                <div>
                  <b>Bairro:</b> {props.paciente?.bairro}
                </div>
                <div>
                  <b> CPF:</b> {props.paciente?.cpf}
                </div>
                <div>
                  <b> Documento:</b> {props.paciente?.documento}
                </div>
                <div>
                  <b> Sexo :</b> {props.paciente?.sexo}
                </div>
                <div>
                  <b> Data : </b>{props.paciente?.data_nasc}
                </div>
                <div>
                  <b> CEP : </b>{props.paciente?.cep}
                </div>
                <div>
                  <b>  Cidade :</b> {props.paciente?.cidade}
                </div>
                <div>
                  <b>  Estado : </b>{props.paciente?.estado}
                </div>
                <div>
                  <b>  Celular : </b>{props.paciente?.celular}
                </div>
                <div>
                  <b> Telefone :</b> {props.paciente?.telefone1}
                </div>
                <div>
                  <b> Telefone 2 : </b>{props.paciente?.telefone1}
                </div>
                <div>
                  <b>  Email :</b> {props.paciente?.email}
                </div>
                <div>
                  <b> Facebook : </b>{props.paciente?.facebook}
                </div>
                <div>
                  <b>  Instagram : </b>{props.paciente?.instagram}
                </div>
                <div>
                  <b>  Foto :</b> {props.file?.name}
                </div>
              </div>
              </div>
          </div>
      </div>
    </div >
  )
}

export default StepSeis;