import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import "./Modal.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import FormClinica from "../Forms/FormClinicaAdd";
import FormCidade from "../Forms/FormCidadeAdd";
import FormConvenio from "../Forms/FormConvenioAdd";
import FormEstado from "../Forms/FormEstadoAdd";
import FormEspecialidade from "../Forms/FormEspecialidadeAdd";
import FormFormaPagamento from "../Forms/FormFormaPagamentoAdd";
import FormFornecedor from "../Forms/FormFornecedorAdd";
import FormMedicamento from "../Forms/FormMedicamentoAdd";
import FormTipoAgenda from "../Forms/FormTipoAgendaAdd";
import FormSegmentos from "../Forms/FormSegmentosAdd";
import FormContasPagar from "../Forms/FormContasPagarAdd";
import FormContasReceber from "../Forms/FormContasReceberAdd";
import FormEstoque from "../Forms/FormEstoqueAdd";
import FormProtese from "../Forms/FormProteseAdd";
import FormSituacao from "../Forms/FormSituacaoAdd";
import FormRecibo from "../Forms/FormReciboAdd";
import FormPlanos from "../Forms/FormPlanoAdd";
import Swal from 'sweetalert2';

const ModalNovoOrc = props => {
  // const Sucesso = Swal.mixin({
  //   toast: true,
  //   // position: 'center',
  //   iconColor: 'green',
  //   customClass: {
  //     popup: 'colored-toast'
  //   },
  //   showConfirmButton: false,
  //   timer: 2000,
  //   timerProgressBar: true
  // });

  const closeOnEscapeKeyDown = e => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  const closeModal = e => {
      props.onClose();
      
      // props.Sucesso.fire({
      //   icon: 'success',
      //   title: 'Registro foi incluído!!!'
      // });
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  return ReactDOM.createPortal(
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div className="modal" onClick={props.onClose}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <div className="modal-header">

            <h4 className="modal-title">Novo regitro tabela {props.title}</h4>
            <button type="button" className="close" onClick={props.onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {/* <button className="btn btn-secondary mr-2" type="button" onClick={props.onClose}>
                Cancelar
              </button> */}
            {props.title === "cidades" &&
                <FormCidade props={props} closeModal={closeModal} Sucesso={props.Sucesso} Falha={props.Falha}/>
            }
            {props.title === "clinicas" &&
              <FormClinica props={props} closeModal={closeModal} Sucesso={props.Sucesso} Falha={props.Falha}/>
            }
            {props.title === "convenios" &&
              <FormConvenio props={props} closeModal={closeModal} Sucesso={props.Sucesso} Falha={props.Falha}/>
            }
            {props.title === "estados" &&
              <FormEstado props={props} closeModal={closeModal} Sucesso={props.Sucesso} Falha={props.Falha}/>
            }
            {props.title === "especialidades" &&
              <FormEspecialidade props={props} closeModal={closeModal} Sucesso={props.Sucesso} Falha={props.Falha}/>
            }
            {props.title === "formas_pagamento" &&
              <FormFormaPagamento props={props} closeModal={closeModal} Sucesso={props.Sucesso} Falha={props.Falha}/>
            }
            {props.title === "fornecedores" &&
              <FormFornecedor props={props} closeModal={closeModal} Sucesso={props.Sucesso} Falha={props.Falha}/>
            }
            {props.title === "medicamentos" &&
              <FormMedicamento props={props} closeModal={closeModal} Sucesso={props.Sucesso} Falha={props.Falha}/>
            }
            {props.title === "situacoes_agenda" &&
              <FormSituacao props={props} closeModal={closeModal} Sucesso={props.Sucesso} Falha={props.Falha}/>
            }
            {props.title === "tipo_agendamento" &&
              <FormTipoAgenda props={props} closeModal={closeModal} Sucesso={props.Sucesso} Falha={props.Falha}/>
            }
            {props.title === "segmentos" &&
              <FormSegmentos props={props} closeModal={closeModal} Sucesso={props.Sucesso} Falha={props.Falha}/>
            }
            {props.title === "contas_pagar" &&
              <FormContasPagar props={props} closeModal={closeModal} Sucesso={props.Sucesso} Falha={props.Falha}/>
            }
            {props.title === "contas_receber" &&
              <FormContasReceber props={props} closeModal={closeModal} Sucesso={props.Sucesso} Falha={props.Falha}/>
            }
            {props.title === "estoques" &&
              <FormEstoque props={props} closeModal={closeModal} Sucesso={props.Sucesso} Falha={props.Falha}/>
            }
            {props.title === "planos_conta" &&
              <FormPlanos props={props} closeModal={closeModal} Sucesso={props.Sucesso} Falha={props.Falha}/>
            }
            {props.title === "proteses" &&
              <FormProtese props={props} closeModal={closeModal} Sucesso={props.Sucesso} Falha={props.Falha}/>
            }
            {props.title === "situacao" &&
              <FormSituacao props={props} closeModal={closeModal} Sucesso={props.Sucesso} Falha={props.Falha}/>
            }
            {props.title === "recibos" &&
              <FormRecibo props={props} closeModal={closeModal} Sucesso={props.Sucesso} Falha={props.Falha}/>
            }
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById("root")
  );
};

export default ModalNovoOrc;