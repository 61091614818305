import React, { useState, useEffect, useCallback, useRef } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "../css/react-big-calendar.css";
import "../css/myCalendar.css"
import Api from '../Api';
import 'moment/locale/pt-br';
import ModalAgendaInserir from './Modals/ModalAgendaPessoalInserir';
import ModalAgendaEditar from './Modals/ModalAgendaPessoalEditar';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';

moment.locale('pt-br')
const localizer = momentLocalizer(moment);

export default function Agenda(props) {

    const pressRef = useRef(null)
    const [, setAgendas] = useState([]);
    const [events, setEvents] = useState([]);
    const [selected, setSelected] = useState();
    const [viewInserir, setShowInserir] = useState(false);
    const [viewEditar, setShowEditar] = useState(false);
    const [item, setItem] = useState([' ']);
    const [month, setMonth] = useState([' ']);
    const [start, setStart] = useState([' ']);
    const [end, setEnd] = useState([' ']);
    const [year, setYear] = useState([' ']);
    const [header, setHeader] = useState([' ']);
    const ref_agendas = useRef();
    //const events = []];

    const getItems = async (month, year) => {
        

        const headers = {
            'headers': {
                'Content-Type': 'application/json'
            }
        }

        setHeader(headers);

        const body = {
            "month": month,
            "year": year
        }

        const response = await Api.post('agendas/date/1', body, headers);

        let appointments = response.data.agendas;

        for (let i = 0; i < response.data.agendas.length; i++) {
            appointments[i].start = moment.utc(appointments[i].start).toDate();
            appointments[i].end = moment.utc(appointments[i].end).toDate();
        }
        setEvents(appointments);
    };


    useEffect(() => {
        var check = moment();
        var month = check.format('M');
        var year = check.format('YYYY');
        setYear(year);
        setMonth(month);
        getItems(month, year)
    }, []);


    const [eventsData, setEventsData] = useState(events);

    const onKeyPressEvent = useCallback((event, keypressEvent) => {

        pressRef.current = window.setTimeout(() => {
        }, 250)
    }, [])

    const handleSelected = (event) => {
        setSelected(event);
        setShowEditar(true);
        setItem(event);
    };

    const handleNavigation = async (detail, elem) => {
        var year = detail.getFullYear();
        var month = parseInt(detail.getMonth() + 1);
        setYear(year);
        setMonth(month);
        await getItems(month, year);
    };

    const eventWrapper = ({ value }) => {

    }

    const handleSelect = ({ start, end, event, slots, action }) => {

        const now = moment(start).format('HHmmss');

        if (now === "000000") {
            Swal.fire({
                title: 'Atenção',
                text: "Selecione um dia para adicionar um evento!",
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
              }).then((result) => {
                // if (result.isConfirmed) {
                //   props.onClose();
                // }
              })
        } else {
            setStart(start);
            var year = start.getFullYear();
            var month = parseInt(start.getMonth() + 1);
            setYear(year);
            setMonth(month);
            setEnd(end);
            setShowInserir(true);
            setSelected(event);
        }
    };

    let today = moment();
    let am7 = today.set('hour', 7).set('minutes', 30).toDate();
    let pm7 = today.set('hour', 20).set('minutes', 30).toDate();

    return (
        <div className="App">
            <ModalAgendaInserir onClose={() => { setShowInserir(false);  getItems(month, year); ref_agendas.current.update();}} show={viewInserir} header={header} start={start} end={end} cod_paciente={props.cod_paciente} />
            <ModalAgendaEditar onClose={() => { setShowEditar(false); getItems(month, year); }} show={viewEditar} item={item} header={header} />
            <Calendar
                culture='pt-br'
                //style={{ height: '95%' }}
                showMultiDayTimes
                dayLayoutAlgorithm="no-overlap"
                startAccessor="start"
                endAccessor="end"
                timeslots={2}
                defaultView="month"
                weekStartsOn={1}
                startHour={7}
                weekSEndsOn={5}
                step={30}
                slotPropGetter={(date) => {
                    if (date.getDay() === 0 || date.getDay() === 6)
                        return {
                            className: 'special-day',
                        }
                }}
                min={am7}
                //doShowMoreDrillDown={false} 
                max={pm7}
                messages={{
                    week: 'Semana',
                    previous: 'Anterior',
                    next: 'Próximo',
                    month: 'Mês',
                    day: 'Dia',
                    today: 'Hoje',
                    yesterday: 'Ontem',
                    date: 'Data',
                    noEventsInRange: 'Sem eventos',
                    time: 'Hora',
                    work_week: 'Semana de trabalho',
                    event: 'Evento',
                    allDay: 'Dia',
                    more: 'mais',
                    showMore: total => `mais ${total} eventos`
                }}
                formats={{
                    monthHeaderFormat: 'MMMM/YYYY',
                    dayHeaderFormat: 'DD/MMMM',
                    agendaTimeFormat: 'HH:MM',
                    agendaDateFormat: 'DD/MM',
                    agendaHeaderFormat: 'YYYY',
                    dayRangeHeaderFormat: 'MMMM/YYYY',
                    //     weekdayFormat: 'MMMM/YYYY'
                }}
                selectable={true}
                onSelectEvent={event => this.handleEventSelected(event)}
                onKeyPressEvent={onKeyPressEvent}
                onNavigate={handleNavigation}
                eventPropGetter={(events) => {
                    const backgroundColor = events.colorEvento ? events.colorEvento : 'green';
                    const color = events.color ? events.color : 'white';
                    return { style: { backgroundColor, color } }
                }}
                views={["day", "agenda", "week", "month"]}
                localizer={localizer}
                defaultDate={new Date()}
                events={events}
                style={{ height: "80vh" }}
                //				onSelectEvent={(event) => alert(event.title)}
                //onSelectEvent={handleSelected}
                onSelectSlot={handleSelect}
            />
        </div>

    );

}


Agenda.propTypes = {
    cod_paciente: PropTypes.string.isRequired, // ou PropTypes.number dependendo do tipo esperado
};