import Header from './Components/Header';
import Footer from './Components/Footer';
import Sidenav from './Components/Sidenav';
import { useNavigate } from 'react-router-dom';
import ModalNovoPaciente from "./Components/Modals/ModalNovoPaciente";
import PrintIcon from '@mui/icons-material/PrintOutlined';
import Api from './Api'
import { ptBR } from '@mui/material/locale'
import React, { useEffect, useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PlusIcon from '@mui/icons-material/Add';
import { DataGrid, GridToolbar  } from '@mui/x-data-grid';
import {  format } from 'date-fns';
import globalState from './Components/Global';
import { createTheme, ThemeProvider } from '@mui/material/styles';


export default function Pacientes() {

    const theme = createTheme(
        {
          palette: {
            mode: 'light',
          },
        },
        ptBR // Aplica a localização pt-BR ao tema do MUI
      );

    var baseURL = process.env.REACT_APP_baseURL + "/print/pacientes/";

    const navigate = useNavigate();

    const getItems = async () => {
        const response = await Api.get('pacientes', globalState.value);
        setPacientes(response.data);
    };


    useEffect(() => {
        getItems()
    }, []);

    const [showMModalNovoPaciente, setShowModalNovoPaciente] = useState(false);
    const [pacientes, setPacientes] = useState([]);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 18,
    });

    const handleRowClick = (row) => {
        navigate('/paciente', { state: { id: row.id, key: row.id } });
    }

    const columns = useMemo(
        () => [
            { field: 'id', headerName: 'Cód', width: 50, editable: false },
            { field: 'nome', headerName: 'Nome', width: 250, editable: true },
            {
                field: 'cpf',
                headerName: 'cpf',
                width: 150,
                type: 'number',
                editable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: 'celular',
                headerName: 'celular',
                width: 250,
                type: 'number',
                editable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: 'email',
                headerName: 'email',
                width: 250,
                type: 'number',
                editable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: 'facebook',
                headerName: 'facebook',
                width: 150,
                type: 'number',
                editable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: 'instagram',
                headerName: 'instagram',
                width: 150,
                type: 'number',
                editable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: 'data_nasc',
                headerName: 'Aniversário',
                width: 100,
                editable: false,
                align: 'center',
                headerAlign: 'center',
                valueFormatter: (params) => format(new Date(params.value), 'dd/MM')
            },
            {
                field: 'cidade',
                headerName: 'Cidade',
                width: 200,
                type: 'number',
                editable: false,
                align: 'center',
                headerAlign: 'center',
            }
        ],
        [],
    );



    return (
        <ThemeProvider theme={theme}>
        <div>
            <Header />
            <Sidenav />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-1">
                            <div className="col-sm-8">
                                <h1 className="m-0">Pacientes</h1>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        p: 1,
                    }}
                >

                    <Button variant="outlined" size="small" onClick={() => setShowModalNovoPaciente(true)} startIcon={<PlusIcon />}>
                        Novo
                    </Button>
                    <Button variant="outlined" size="small"
                        href={baseURL}
                        icon={<PrintIcon color="normal" />}
                        sx={{
                            color: 'primary.main',
                        }}
                        startIcon={<PrintIcon />}>
                        Lista
                    </Button>

                </Box>
                <section className="content">

                    <div style={{ height: '630px', width: '100%' }}>
                        {pacientes.dado !== undefined &&
                            <DataGrid
                                rowHeight={25}
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: {
                                            // Hide columns status and traderName, the other columns will remain visible
                                            status: false,
                                            traderName: false,
                                        }
                                    },
                                }}
                                localeText={globalState.ptBRLocaleText}
                                rows={pacientes?.dado}
                                onRowClick={handleRowClick}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                        printOptions: {
                                            hideFooter: true,
                                            hideToolbar: false,
                                        }
                                    }
                                }}
                                pagination
                                pageSize={18}
                                pageSizeOptions={[10, 18, 25, 50]} // Lista de tamanhos
                                paginationModel={paginationModel}
                                onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
                                rowsPerPageOptions={[5, 10, 20]}
                                columns={columns}
                                slots={{ toolbar: GridToolbar }}
                            />
                        }
                    </div>
                </section>
            </div>
            <ModalNovoPaciente title="Novo paciente" onClose={() => { setShowModalNovoPaciente(false); navigate(0); }} show={showMModalNovoPaciente} />
            <Footer />
        </div>
        </ThemeProvider>
    )

}
