import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Api from '../../Api';
import globalState from '../Global';

// eslint-disable-next-line react/prop-types

const StepDois = (props) => {
  const [data, setData] = useState({});

  useEffect(() => {

    const fetchData = async () => {
      let cep =  props.paciente.cep.replace("-", "");

      const response = await Api.get('pacientes/cep/' + cep, globalState.value);
      setData(response.data);

      await props.setPaciente({ ...props.paciente, 
        cidade: response.data.localidade, 
        estado: response.data.uf, 
        bairro: response.data.bairro, 
        endereco: response.data.logradouro })
    }
    fetchData()
    // make sure to catch any error
    .catch(console.error);
  }, [])

  return (
    <div className="card-body">
      <div className="card-body">
        <Box
          // sx={{
          //     display: 'flex',
          //     alignItems: 'center',
          //     '& > :not(style)': { m: 2 },
          // }}
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
        >

          <div className="card-body">
            <div className="row">
              <div>

                <FormControl fullWidth sx={{ m: 1 }} variant="standard"></FormControl>
                <TextField
                  style={{ width: '98%' }}
                  multiline={true}
                  label="Endereço"
                  id="endereco"
                  name="endereco"
                  focused
                  defaultValue={data.logradouro}
                  size="small"
                  onLoadedData={(e) => props.setPaciente({ ...props.paciente, endereco: e.target.value })}
                />
              </div>
            </div>
            <div className="row">
              <div>
                <TextField
                  multiline={true}
                  style={{ width: '98%' }}
                  label="Complemento"
                  id="complemento"
                  //value={cpf}
                  name="complemento"
                  fullWidth
                  focused
                  defaultValue=""
                  size="small"
                  onChange={(e) => props.setPaciente({ ...props.paciente, complemento: e.target.value })}
                />
              </div>


            </div>
            <div className="row">
              <div>
              <TextField
                  style={{ width: '98%' }}
                  multiline={true}
                  label="Bairro"
                  id="bairro"
                  name="bairro"
                  focused
                  defaultValue={data.bairro}
                  size="small"
                  onChange={(e) => props.setPaciente({ ...props.paciente, bairro: e.target.value })}
                />
              </div>


            </div>
            <div className="row">
              <div>
                <TextField
                  multiline={true}
                  style={{ width: '98%' }}
                  label="Cidade"
                  id="cidade"
                  name="cidade"
                  focused
                  fullWidth
                  defaultValue={data.localidade}
                  size="small"
                  onValue={(e) => props.setPaciente({ ...props.paciente, cidade: e.target.value })}
                />
              </div>


            </div>
            <div className="row">
              <div>
                <TextField
                  multiline={true}
                  style={{ width: '98%' }}
                  label="Estado"
                  id="estado"
                  focused
                  name="estado"
                  fullWidth
                  defaultValue={data.uf}
                  size="small"
                  onChange={(e) => props.setPaciente({ ...props.paciente, estado: e.target.value })}
                />
              </div>


            </div>
          </div>
        </Box>
      </div>
    </div >
  )
}

export default StepDois;