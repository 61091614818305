import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Button, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Api from '../../Api';
import Swal from 'sweetalert2';
import { DateTimePicker } from "react-tempusdominus-bootstrap";
import globalState from '../Global';

const ModalAgendaEditar = props => {
  const form = useRef(null);
  let id = props.item?.id;
  let cod_unidade = props.item?.cod_unidade;
  let pk_clinica = props.item?.pk_clinica;
  let title = props.item?.title;
  //let start = props.item?.start;
  //let end = props.item?.end;
  let cod_paciente = props.item?.cod_paciente;
  let cod_profissional = props.item?.cod_profissional;
  const [pacientes, setPacientes] = useState(' ');
  const [profissionais, setProfissionais] = useState(' ');
  const [end, setDateFim] = useState(new Date());
  const [start, setDateInicio] = useState(new Date());

  const closeOnEscapeKeyDown = e => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: "",
      title: "",
      start: "",
      end: "",
      cod_paciente: "",
      cod_profissional: ""
    },
  });

  async function submitForm() {
    const paciente = await Api.get('pacientes/' + cod_paciente, globalState.value);
    title = paciente.data.tabela.nome;

    const body = {
      "id": id,
      "cod_paciente": cod_paciente,
      "cod_profissional": cod_profissional,
      "cod_unidade": cod_unidade,
      "pk_clinica": pk_clinica,
      "day": 0,
      "title": title,
      "start": start,
      "end": end,
    }

    const response = await Api.put('agendas/' + id, body, globalState.value);
    Swal.fire({
      title: 'Atenção',
      text: "Agenda atualizada com sucesso!",
      icon: 'success',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    }).then((result) => {
      if (result.isConfirmed) {
        props.onClose();
      }
    })

  }
  const style = { width: '105px' }

  async function delAgenda(id) {
    Swal.fire({
      title: 'Deseja excluir o agendamento?',
      text: "",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        Api.delete('agendas/' + id, globalState.value);
        Swal.fire({
          title: 'Atenção',
          text: "Agendamento excluído!",
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            props.onClose();
          }
        })
      }
    })
  }
  const getPacientes = async () => {
    const response = await Api.get('pacientes/', globalState.value);
    setPacientes(response.data);
  };

  const getProfissionais = async () => {
    const response = await Api.get('usuarios/dentistas', globalState.value);
    setProfissionais(response.data);
  };

  useEffect(() => {
    getPacientes();
    getProfissionais();
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  return ReactDOM.createPortal(
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div className="modal" onClick={props.onClose}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <div className="modal-header">
            <h4 className="modal-title">Editar evento</h4>
            <button type="button" className="btn-close" aria-label="Close" onClick={props.onClose}></button>
          </div>
          <div className="modal-body">
            <form ref={form} noValidate onSubmit={handleSubmit(submitForm)}>
              {/* <Form.Group as={Col} md="20">
                <Form.Label>Id</Form.Label>
                <Form.Control
                  type="text"
                  id="id"
                  name="id"
                  placeholder="id"
                  defaultValue={props.item?.id}
                  onChange={(e) => id = e.target.value}
                />
              </Form.Group> */}
              <br></br>
              <Form.Group as={Col} md="20">
                <Form.Label>Titulo</Form.Label>
                <Form.Control
                  type="text"
                  id="title"
                  name="title"
                  placeholder="title"
                  defaultValue={props.item?.title}
                  onChange={(e) => title = e.target.value}
                />
              </Form.Group>
              <br></br>
              <Form.Group as={Col} md="20">
                <Form.Label>Paciente</Form.Label>
                <Form.Select onChange={(e) => cod_paciente = e.target.value} defaultValue={props.item?.cod_paciente}>
                  {
                    pacientes.dado?.map(person => <option key={person.id}  value={person.id}>{person.nome}</option>)
                  }
                </Form.Select>
                {/* <Form.Control
                  type="text"
                  id="paciente"
                  name="paciente"
                  placeholder="paciente"
                  defaultValue={props.item?.cod_paciente}
                  onChange={(e) => cod_paciente = e.target.value}
                /> */}
              </Form.Group>
              <br></br>
              <Form.Group as={Col} md="20">
                <Form.Label>Profissional</Form.Label>
                <Form.Select onChange={(e) => cod_profissional = e.target.value}>
                  {
                    profissionais.usuarios?.map(prof => <option key={prof.id} value={prof.id}>{prof.first_name}</option>)
                  }
                </Form.Select>
                {/* <Form.Control
                  type="text"
                  id="profissional"
                  name="profissional"
                  placeholder="profissional"
                  defaultValue={props.item?.cod_profissional}
                  onChange={(e) => cod_profissional = e.target.value}
                /> */}
              </Form.Group>
              <br></br>
              <Form.Group as={Col} md="20">
                <Form.Label>Inicio</Form.Label>
                <DateTimePicker
                  format="DD/MM/YYYY HH:mm"
                  locale="pt-br"
                  //date={end}
                  defaultDate={props.item?.start}
                  onChange={e => setDateInicio(e.date.format("YYYY-MM-DD HH:mm:ss"))}
                  icons={{
                    date: "fa fa-birthday-cake",
                    time: "fa fa-hourglass-half",
                    up: "fa fa-chevron-circle-up",
                    down: "fa fa-chevron-circle-down",
                  }} />
                {/* <Form.Control
                  type="text"
                  id="start"
                  name="start"
                  placeholder="start"
                  defaultValue={props.item?.start}
                  onChange={(e) => start = e.target.value}
                /> */}
              </Form.Group>
              <br></br>
              <Form.Group as={Col} md="20">
                <Form.Label>Fim</Form.Label>
                {/* <Form.Control
                  type="text"
                  id="end"
                  name="end"
                  placeholder="end"
                  defaultValue={props.item?.end}
                  onChange={(e) => end = e.target.value}
                /> */}
                <DateTimePicker
                  //dateFormat="DD/MM/YYYY HH:mm"
                  locale="pt-br"
                  //date={end}
                  defaultDate={props.item?.end}
                  onChange={e => setDateFim(e.date.format("YYYY-MM-DD HH:mm:ss"))}
                  icons={{
                    date: "fa fa-birthday-cake",
                    time: "fa fa-hourglass-half",
                    up: "fa fa-chevron-circle-up",
                    down: "fa fa-chevron-circle-down",
                  }} />
              </Form.Group>
              <br></br>
              <div className="text-right">
                <Button style={style} variant="danger" onClick={() => delAgenda(id)} size="sm">
                  <i className="fas fa-trash"> Excluir</i>
                </Button>
                <Button style={style} variant="primary" type="submit" size="sm">
                  <i className="fas fa-save"> Salvar</i>
                </Button>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            {/* <button onClick={props.onClose} className="button">
              Close1
            </button> */}

          </div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById("root")
  );
};

export default ModalAgendaEditar;