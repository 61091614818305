import Header from './Components/Header';
import Footer from './Components/Footer';
import Sidenav from './Components/Sidenav';
import React, { useState, useEffect, useRef } from 'react';
import PlusIcon from '@mui/icons-material/Add';
import Cidades from "./Components/tabs/cidades";
import { ptBR } from '@mui/material/locale'
import Clinicas from "./Components/tabs/clinicas";
import Convenios from "./Components/tabs/convenios";
import Especialidades from "./Components/tabs/especialidades";
import Estados from "./Components/tabs/estados";
import Formas from "./Components/tabs/formas";
import Fornecedores from "./Components/tabs/fornecedores";
import Medicamentos from "./Components/tabs/medicamentos";
import ModalNovoOrc from "./Components/Modals/ModalNovo";
import Button from '@mui/material/Button';
import ModalEdit from "./Components/Modals/ModalEditBasicos";
import Swal from 'sweetalert2';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function Cadastros() {
    const theme = createTheme(
        {
          palette: {
            mode: 'light',
          },
        },
        ptBR // Aplica a localização pt-BR ao tema do MUI
      );
    const [id, setID] = useState(false);
    const [tabela, setTabela] = useState(" ");

    const ref_cidades = useRef();
    const ref_clinicas = useRef();
    const ref_convenios = useRef();
    const ref_estados = useRef();
    const ref_especialidades = useRef();
    const ref_formas_pagamento = useRef();
    const ref_fornecedores = useRef();
    const ref_medicamentos = useRef();
    const ref_segmentos = useRef();

    const handleClose = () => setShowModalNovoOrc(false);

    const handleCloseEdit = () => setShowModalEditOrc(false);
    const handleShowEdit = () => setShowModalEditOrc(true);


    const updateTable = async (tabela) => {
        if (tabela === 'cidades') {
            await ref_cidades.current.update();
        } else if (tabela === 'clinicas') {
            await ref_clinicas.current.update();
        } else if (tabela === 'convenios') {
            await ref_convenios.current.update();
        } else if (tabela === 'estados') {
            await ref_estados.current.update();
        } else if (tabela === 'especialidades') {
            await ref_especialidades.current.update();
        } else if (tabela === 'formas_pagamento') {
            await ref_formas_pagamento.current.update();
        } else if (tabela === 'fornecedores') {
            await ref_fornecedores.current.update();
        } else if (tabela === 'medicamentos') {
            await ref_medicamentos.current.update();
        } 
    };


    const handleMouseDown = (event) => {
        // Keep the focus in the cell
        event.preventDefault();
      };

    const [showModalNovoOrc, setShowModalNovoOrc] = useState(false);
    const [showModalEditOrc, setShowModalEditOrc] = useState(false);

    const Sucesso = Swal.mixin({
        toast: true,
        // position: 'center',
        iconColor: 'green',
        customClass: {
            popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true
    });

    const Falha = Swal.mixin({
        toast: true,
        position: 'center',
        iconColor: 'falha',
        customClass: {
            popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true
    });

    useEffect(() => {
        // Sucesso.fire({
        //     icon: 'success',
        //     title: 'Operação realizada com sucesso'
        //   });

    }, []);

    return (
        <ThemeProvider theme={theme}>

        <div>
            <Header />
            <Sidenav />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Cadastros</h1>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row"></div>
                        <div className="col-md-12">
                            <div className="card card-primary card-tabs">
                                <div className="card-header p-0 pt-1">
                                    <ul className="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="custom-tabs-one-home-tab" data-toggle="pill" href="#custom-tabs-one-home" role="tab" aria-controls="custom-tabs-one-home" aria-selected="false">Cidades</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-profile-tab" data-toggle="pill" href="#custom-tabs-one-profile" role="tab" aria-controls="custom-tabs-one-profile" aria-selected="false">Clinicas</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-messages-tab" data-toggle="pill" href="#custom-tabs-one-messages" role="tab" aria-controls="custom-tabs-one-messages" aria-selected="false">Convenios</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-settings-tab" data-toggle="pill" href="#custom-tabs-one-settings" role="tab" aria-controls="custom-tabs-one-settings" aria-selected="true">Estados</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-especialidades-tab" data-toggle="pill" href="#custom-tabs-one-especialidades" role="tab" aria-controls="custom-tabs-one-especialidades" aria-selected="true">Especialidades</a>
                                        </li>
                                        {/* <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-formas-tab" data-toggle="pill" href="#custom-tabs-one-formas" role="tab" aria-controls="custom-tabs-one-formas" aria-selected="true">Formas Pagto</a>
                                        </li> */}
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-fornecedores-tab" data-toggle="pill" href="#custom-tabs-one-fornecedores" role="tab" aria-controls="custom-tabs-one-fornecedores" aria-selected="true">Fornecedores</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-med-tab" data-toggle="pill" href="#custom-tabs-one-med" role="tab" aria-controls="custom-tabs-one-med" aria-selected="true">Medicamentos</a>
                                        </li>
                                        {/* <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-situacoess-tab" data-toggle="pill" href="#custom-tabs-one-situacoes" role="tab" aria-controls="custom-tabs-one-situacoes" aria-selected="true">Situações Agenda</a>
                                        </li> */}
                                        {/* <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-tipo-tab" data-toggle="pill" href="#custom-tabs-one-tipo" role="tab" aria-controls="custom-tabs-one-tipo" aria-selected="true">Tipo Agenda</a>
                                        </li> */}
                                        {/* <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-segmentos-tab" data-toggle="pill" href="#custom-tabs-one-segmentos" role="tab" aria-controls="custom-tabs-one-segmentos" aria-selected="true">Segmentos</a>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content" id="custom-tabs-one-tabContent">
                                        <div className="tab-pane fade show active" id="custom-tabs-one-home" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
                                            <Button variant="outlined" size="small"  
                                                onClick={() => { setShowModalNovoOrc(true); setTabela('cidades'); }}
                                                onMouseDown={handleMouseDown}
                                                startIcon={<PlusIcon />}>
                                                Novo
                                            </Button>

                                            <div className="table-responsive">
                                                <Cidades handleShowEdit={handleShowEdit} handleCloseEdit={handleCloseEdit} setID={setID} setTabela={setTabela} Sucesso={Sucesso} Falha={Falha} ref={ref_cidades} updateTable={updateTable} />
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="custom-tabs-one-profile" role="tabpanel" aria-labelledby="custom-tabs-one-profile-tab">
                                            <Button variant="outlined" size="small"  
                                                onClick={() => { setShowModalNovoOrc(true); setTabela('clinicas'); }}
                                                onMouseDown={handleMouseDown}
                                                startIcon={<PlusIcon />}>
                                                Novo
                                            </Button>
                                            <div className="table-responsive">
                                                <Clinicas handleShowEdit={handleShowEdit} handleCloseEdit={handleCloseEdit} setID={setID} setTabela={setTabela} Sucesso={Sucesso} Falha={Falha} ref={ref_clinicas} updateTable={updateTable} />
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="custom-tabs-one-messages" role="tabpanel" aria-labelledby="custom-tabs-one-messages-tab">
                                            <Button variant="outlined" size="small"  
                                                onClick={() => { setShowModalNovoOrc(true); setTabela('convenios'); }}
                                                onMouseDown={handleMouseDown}
                                                startIcon={<PlusIcon />}>
                                                Novo
                                            </Button>
                                            <div className="table-responsive">
                                                <Convenios handleShowEdit={handleShowEdit} handleCloseEdit={handleCloseEdit} setID={setID} setTabela={setTabela} Sucesso={Sucesso} Falha={Falha} ref={ref_convenios} updateTable={updateTable} />
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="custom-tabs-one-settings" role="tabpanel" aria-labelledby="custom-tabs-one-settings-tab">
                                            
                                            <Button variant="outlined" size="small"  
                                                onClick={() => { setShowModalNovoOrc(true); setTabela('estados'); }}
                                                onMouseDown={handleMouseDown}
                                                startIcon={<PlusIcon />}>
                                                Novo
                                            </Button>
                                            <div className="table-responsive">
                                                <Estados handleShowEdit={handleShowEdit} handleCloseEdit={handleCloseEdit} setID={setID} setTabela={setTabela} Sucesso={Sucesso} Falha={Falha} ref={ref_estados} updateTable={updateTable} />
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="custom-tabs-one-especialidades" role="tabpanel" aria-labelledby="custom-tabs-one-especialidades-tab">
                                            <Button variant="outlined" size="small"  
                                                onClick={() => { setShowModalNovoOrc(true); setTabela('especialidades'); }}
                                                onMouseDown={handleMouseDown}
                                                startIcon={<PlusIcon />}>
                                                Novo
                                            </Button>
                                            <div className="table-responsive">
                                                <Especialidades handleShowEdit={handleShowEdit} handleCloseEdit={handleCloseEdit} setID={setID} setTabela={setTabela} Sucesso={Sucesso} Falha={Falha} ref={ref_especialidades} updateTable={updateTable} />
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="custom-tabs-one-formas" role="tabpanel" aria-labelledby="custom-tabs-one-formas-tab">
                                            <Button variant="outlined" size="small"  
                                                onClick={() => { setShowModalNovoOrc(true); setTabela('formas_pagamento'); }}
                                                onMouseDown={handleMouseDown}
                                                startIcon={<PlusIcon />}>
                                                Novo
                                            </Button>
                                            <div className="table-responsive">
                                                <Formas handleShowEdit={handleShowEdit} handleCloseEdit={handleCloseEdit} setID={setID} setTabela={setTabela} Sucesso={Sucesso} Falha={Falha} ref={ref_formas_pagamento} updateTable={updateTable} />
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="custom-tabs-one-fornecedores" role="tabpanel" aria-labelledby="custom-tabs-one-fornecedores-tab">
                                        <Button variant="outlined" size="small"  
                                                onClick={() => { setShowModalNovoOrc(true); setTabela('fornecedores'); }}
                                                onMouseDown={handleMouseDown}
                                                startIcon={<PlusIcon />}>
                                                Novo
                                            </Button>
                                            <div className="table-responsive">
                                                <Fornecedores handleShowEdit={handleShowEdit} handleCloseEdit={handleCloseEdit} setID={setID} setTabela={setTabela} Sucesso={Sucesso} Falha={Falha} ref={ref_fornecedores} updateTable={updateTable} />
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="custom-tabs-one-med" role="tabpanel" aria-labelledby="custom-tabs-one-med-tab">
                                            <Button variant="outlined" size="small"  
                                                onClick={() => { setShowModalNovoOrc(true); setTabela('medicamentos'); }}
                                                onMouseDown={handleMouseDown}
                                                startIcon={<PlusIcon />}>
                                                Novo
                                            </Button>
                                            <div className="table-responsive">
                                                <Medicamentos handleShowEdit={handleShowEdit} handleCloseEdit={handleCloseEdit} setID={setID} setTabela={setTabela} Sucesso={Sucesso} Falha={Falha} ref={ref_medicamentos} updateTable={updateTable} />
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="custom-tabs-one-situacoes" role="tabpanel" aria-labelledby="custom-tabs-one-med-situacoes">
                                
                                            <Button variant="outlined" size="small"  
                                                onClick={() => { setShowModalNovoOrc(true); setTabela('situacoes_agenda'); }}
                                                onMouseDown={handleMouseDown}
                                                startIcon={<PlusIcon />}>
                                                Novo
                                            </Button>
                                        </div>

                                        <div className="tab-pane fade" id="custom-tabs-one-tipo" role="tabpanel" aria-labelledby="custom-tabs-one-med-tipo">
                                            <Button variant="outlined" size="small"  
                                                onClick={() => { setShowModalNovoOrc(true); setTabela('tipo_agendamento'); }}
                                                onMouseDown={handleMouseDown}
                                                startIcon={<PlusIcon />}>
                                                Novo
                                            </Button>
                                        </div>

                                        <div className="tab-pane fade" id="custom-tabs-one-segmentos" role="tabpanel" aria-labelledby="custom-tabs-one-med-segmentos">
                                            
                                            <Button variant="outlined" size="small"  
                                                onClick={() => { setShowModalNovoOrc(true); setTabela('segmentos'); }}
                                                onMouseDown={handleMouseDown}
                                                startIcon={<PlusIcon />}>
                                                Novo
                                            </Button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* /.row (main row) */}
                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            <ModalNovoOrc title={tabela}
                onClose={() => {
                    setShowModalNovoOrc(false)
                    if (tabela === 'cidades') {
                        ref_cidades.current.update();
                    } else if (tabela === 'clinicas') {
                        ref_clinicas.current.update();
                    } else if (tabela === 'convenios') {
                        ref_convenios.current.update();
                    } else if (tabela === 'estados') {
                        ref_estados.current.update();
                    } else if (tabela === 'especialidades') {
                        ref_especialidades.current.update();
                    } else if (tabela === 'formas_pagamento') {
                        ref_formas_pagamento.current.update();
                    } else if (tabela === 'fornecedores') {
                        ref_fornecedores.current.update();
                    } else if (tabela === 'medicamentos') {
                        ref_medicamentos.current.update();
                    } 
                }}

                show={showModalNovoOrc} handleClose={handleClose} id="0" Sucesso={Sucesso} Falha={Falha} />

            {/* <ModalEditOrc title="My ModalEditOrc" onClose={() => {setShowModalEditOrc(false); cidade_ref.current.update()}} show={showModalEditOrc} id={id} tabela={tabela} Sucesso={Sucesso}  Falha={Falha}/> */}
            <ModalEdit
                onClose={() => {
                    setShowModalEditOrc(false);
                    if (tabela === 'cidades') {
                        ref_cidades.current.update();
                    } else if (tabela === 'clinicas') {
                        ref_clinicas.current.update();
                    } else if (tabela === 'convenios') {
                        ref_convenios.current.update();
                    } else if (tabela === 'estados') {
                        ref_estados.current.update();
                    } else if (tabela === 'especialidades') {
                        ref_especialidades.current.update();
                    } else if (tabela === 'formas_pagamento') {
                        ref_formas_pagamento.current.update();
                    } else if (tabela === 'fornecedores') {
                        ref_fornecedores.current.update();
                    } else if (tabela === 'medicamentos') {
                        ref_medicamentos.current.update();
                    } else if (tabela === 'segmentos') {
                        ref_segmentos.current.update();
                    }
                }}
                show={showModalEditOrc}
                id={id}
                tabela={tabela}
                Sucesso={Sucesso}
                Falha={Falha}
            />
            <Footer />
           
        </div>
        </ThemeProvider>
    )
}
