import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// eslint-disable-next-line react/prop-types


const StepUm = (props) => {

  const [nome, setNome] = useState('')
  const [value, setValue] = useState('2000-01-01'); // valor inicial
  const [data, setData] = useState("");
  const [focus, setFocus] = useState(true);

  const [sexo, setSexo] = React.useState('');

  const handleChange = (event) => {
    setSexo(event.target.value);

    props.setPaciente({ ...props.paciente, sexo: event.target.value })
  };

  const handleChangeData = (event) => {
    const formattedDate = event.target.value; // já está no formato YYYY-MM-DD
    setValue(formattedDate);
    props.setPaciente({ ...props.paciente, data_nasc: formattedDate })
    console.log('Data de Nascimento:', formattedDate);
  };

  useEffect(() => {
    if (props.paciente !== "") {
      setData(props.paciente);
    }

  }, [])

  return (
    <div className="card-body">
      <Box
        // sx={{
        //     display: 'flex',
        //     alignItems: 'center',
        //     '& > :not(style)': { m: 2 },
        // }}
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
      >

        <div className="card-body">
          <div className="row">
            <div className="six columns">

              <FormControl fullWidth sx={{ m: 1 }} variant="standard"></FormControl>
              <TextField
                style={{ width: '98%' }}
                multiline={true}
                label="Nome"
                id="nome"
                name="nome"
                focused
                defaultValue={data.nome}
                onChange={(e) => props.setPaciente({ ...props.paciente, nome: e.target.value })}
                size="small"
              />
            </div>
          </div>
          <div className="row">
            <div>
              <TextField
                multiline={true}
                style={{ width: '98%' }}
                label="CPF"
                id="cpf"
                //value={cpf}
                name="cpf"
                fullWidth
                defaultValue={data.cpf}
                focused
                onChange={(e) => props.setPaciente({ ...props.paciente, cpf: e.target.value })}
                size="small"
              />
            </div>


          </div>
          <div className="row">
            <div>
              <TextField
                multiline={true}
                style={{ width: '98%' }}
                label="RG"
                id="documento"
                //value={cpf}
                name="documento"
                fullWidth
                focused
                defaultValue={data.documento}
                size="small"
                onChange={(e) => props.setPaciente({ ...props.paciente, documento: e.target.value })}
              />
            </div>


          </div>

          <div className="row">
            <div>
              <TextField
                multiline={true}
                style={{ width: '98%' }}
                label="CEP"
                id="cep"
                //value={cpf}
                name="cep"
                fullWidth
                defaultValue={data.cep}
                focused
                size="small"
                onChange={(e) => props.setPaciente({ ...props.paciente, cep: e.target.value })}
              />
            </div>


          </div>
          {/* <div className="row">
              <div>
                <TextField
                  multiline={true}
                  style={{ width: '98%' }}
                  label="Data de nascimento"
                  id="data_nasc"
                  //value={cpf}
                  name="data_nasc"
                  fullWidth
                  defaultValue={data.data_nasc}
                  focused
                  size="small"
                  onChange={(e) => props.setPaciente({ ...props.paciente, data_nasc: e.target.value })}
                />
              </div>

            </div> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {/* <DatePicker
                  label="Data de nascimento"
                  value={data.data_nasc || null}
                  inputFormat="YYYY-MM-DD" 
                  onChange={(newValue) => {
                    setData({ ...data, data_nasc: newValue });
                    props.setPaciente({ ...props.paciente, data_nasc: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      style={{ width: '98%' }} 
                      size="small" 
                    />
                  )}
                /> */}
            <TextField
              label="Data de Nascimento"
              type="date"
              value={value}
              onChange={handleChangeData}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: '1900-01-01',
                max: new Date().toISOString().split('T')[0],
              }}
              fullWidth
            />
          </LocalizationProvider>
          <div className="row">
            <div>
              <TextField
                multiline={true}
                style={{ width: '98%' }}
                label="Profissão"
                id="profissao"
                //value={cpf}
                name="profissao"
                fullWidth
                defaultValue={data.profissao}
                focused
                size="small"
                onChange={(e) => props.setPaciente({ ...props.paciente, profissao: e.target.value })}
              />
            </div>
          </div>
          <div className="row">
            <div>
              <FormControl sx={{ m: 1, minWidth: 200, width: 300 }} size="small">
                <InputLabel id="sexo-label">Sexo</InputLabel>
                <Select
                  style={{ width: '98%' }}
                  labelId="sexo-label"
                  id="demo-simple-select"
                  value={sexo}
                  label="Sexo"
                  onChange={handleChange}
                >
                  <MenuItem value="M">Masculino</MenuItem>
                  <MenuItem value="F">Feminino</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </Box>
    </div >
  )
}
export default StepUm;