
import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import "../../css/prog-track.css";
import Api from '../../Api';
import Swal from 'sweetalert2';
import '../../css/styles.css';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import globalState from '../Global';

const ModalNovoPaciente = props => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const closeOnEscapeKeyDown = e => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };
  const Sucesso = Swal.mixin({
    toast: true,
    position: 'center',
    iconColor: 'green',
    customClass: {
      popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  });

  const style = { width: '85px', color: 'red' }

  const Falha = Swal.mixin({
    toast: true,
    position: 'center',
    iconColor: 'falha',
    customClass: {
      popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  });
  const [paciente, setPaciente] = useState([]);
  const [file, setFile] = useState('');
  const [data, setData] = useState("");
  const [formData, setFormData] = useState({
    email: '',
    celphone: '',
    first_name: '',
    last_name: '',
    password: '',
    role: '',
    last_login: '',
    pk_clinica: '',
    facebook: '',
    instagram: '',
    tratamento: '',
    cpf: '',
    cro: '',
    image: '',
    level: '0',
    domain: '',
    username: ''
  });

  const savePaciente = async () => {
    console.log("teste")
    
    const block = {
      "email": formData.email,
      "first_name": formData.first_name,
      "last_name": formData.last_name,
      "password": formData.password,
      "role": formData.role,
      "last_login": "2022-03-23T22:33:28Z",
      "pk_clinica": 1,
      "facebook": formData.facebook,
      "instagram": formData.instagram,
      "celphone": formData.celphone,
      "tratamento": formData.tratamento,
      "cpf": formData.cpf,
      "cro": formData.cro,
		  "image": formData.image,
	    "level": "0",
		  "domain": formData.domain,
	    "username": formData.email
    }

    const response = await Api.post('usuarios', block, globalState.value);
    if (response.status === 200) {
      await Sucesso.fire({
        icon: 'success',
        title: 'Usuário incluído'
      });
      await props.onClose();
    } else {
      await Falha.fire({
        icon: 'error',
        title: 'Usuário não incluído'
      });
    }
  }



  useEffect(() => {
    setPaciente({ nome: "", foto: "" })
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  return ReactDOM.createPortal(
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div className="modal" onClick={props.onClose}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <div className="modal-header">
            <h4 className="modal-title">Novo usuário</h4>
          </div>
          <div>
          <div>
          <div className="card-body">
          <Box
                    // sx={{
                    //     display: 'flex',
                    //     alignItems: 'center',
                    //     '& > :not(style)': { m: 2 },
                    // }}
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '20ch' },
                    }}
                >

                    <div className="card-body">
                    <form onSubmit={handleSubmit}>
                    <div className="row">

                                <div>
                                    <TextField
                                        multiline={true}
                                        label="Email"
                                        style={{ width: '45%' }}
                                        id="email"
                                        name="email"
                                        fullWidth
                                        defaultValue=""
                                        size="small"
                                        onKeyDown=""
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        multiline={true}
                                        label="Celular"
                                        style={{ width: '45%' }}
                                        id="celphone"
                                        //value={cpf}
                                        name="celphone"
                                        fullWidth
                                        defaultValue=""
                                        size="small"
                                        onKeyDown=""
                                        value={formData.celphone}
                                        onChange={handleChange}
                                    />

                                </div>
                            </div>
                            <div className="row">
                                <div>
                                    <TextField
                                        multiline={true}
                                        label="foto"
                                        style={{ width: '45%' }}
                                        id="image"
                                        //value={cpf}
                                        name="image"
                                        fullWidth
                                        defaultValue=""
                                        size="small"
                                        onKeyDown=""
                                        value={formData.image}
                                        onChange={handleChange}

                                    />
                                    <TextField
                                        multiline={true}
                                        label="dominio"
                                        style={{ width: '45%' }}
                                        id="domain"
                                        name="domain"
                                        fullWidth
                                        defaultValue=""
                                        size="small"
                                        value={formData.domain}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div>
                                    <TextField
                                        multiline={true}
                                        label="Nome"
                                        style={{ width: '45%' }}
                                        id="first_name"
                                        //value={cpf}
                                        name="first_name"
                                        fullWidth
                                        defaultValue=""
                                        size="small"
                                        onKeyDown=""
                                        value={formData.first_name}
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        multiline={true}
                                        label="Sobrenome"
                                        style={{ width: '45%' }}
                                        id="last_name"
                                        name="last_name"
                                        fullWidth
                                        defaultValue=""
                                        size="small"
                                        value={formData.last_name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div>
                                    <TextField
                                        multiline={true}
                                        label="CPF"
                                        id="cpf"
                                        //value={cpf}
                                        name="cpf"
                                        style={{ width: '45%' }}
                                        fullWidth
                                        defaultValue=""
                                        size="small"
                                        onKeyDown=""
                                        value={formData.cpf}
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        multiline={true}
                                        label="CRO"
                                        id="cro"
                                        name="cro"
                                        fullWidth
                                        style={{ width: '45%' }}
                                        defaultValue=""
                                        size="small"
                                        value={formData.cro}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div>
                                    <TextField
                                        multiline={true}
                                        label="Cargo"
                                        id="role"
                                        //value={cpf}
                                        name="role"
                                        style={{ width: '45%' }}
                                        fullWidth
                                        defaultValue=""
                                        size="small"
                                        onKeyDown=""
                                        value={formData.role}
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        multiline={true}
                                        label="Tratamento"
                                        id="tratmanento"
                                        name="tratamento"
                                        fullWidth
                                        style={{ width: '45%' }}
                                        defaultValue=""
                                        size="small"
                                        value={formData.tratamento}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div>
                                    <TextField
                                        multiline={true}
                                        label="Facebook"
                                        id="facebook"
                                        //value={cpf}
                                        name="facebook"
                                        style={{ width: '45%' }}
                                        fullWidth
                                        defaultValue=""
                                        size="small"
                                        onKeyDown=""
                                        value={formData.facebook}
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        multiline={true}
                                        label="Instagram"
                                        id="instagram"
                                        name="instagram"
                                        fullWidth
                                        style={{ width: '45%' }}
                                        defaultValue=""
                                        size="small"
                                        value={formData.instagram}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div>
                                    <TextField
                                        multiline={true}
                                        label="Senha"
                                        id="password"
                                        //value={cpf}
                                        name="password"
                                        style={{ width: '45%' }}
                                        fullWidth
                                        defaultValue=""
                                        size="small"
                                        onKeyDown=""
                                        value={formData.password}
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        multiline={true}
                                        label="Confirma senha"
                                        id="confirma"
                                        name="confirma"
                                        fullWidth
                                        style={{ width: '45%' }}
                                        defaultValue=""
                                        size="small"
                                        value={formData.confirma}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                    </form>
                    </div>
                </Box>
      </div >
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-danger" onClick={props.onClose}>Fechar</button>
            <button className="btn btn-primary" type="submit" onClick={() => savePaciente()}>Salvar</button>
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById("root")
  );
};

export default ModalNovoPaciente;