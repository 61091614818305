// global.js
const globalState = {
    value: null,
    ptBRLocaleText : {
        noRowsLabel: 'Nenhum registro encontrado',
        toolbarQuickFilterPlaceholder: 'Buscar...',
        toolbarQuickFilterLabel: 'Buscar', // Este é o texto que aparece no rótulo
        noResultsOverlayLabel: 'Nenhum resultado encontrado',
        errorOverlayDefaultLabel: 'Ocorreu um erro.',
        toolbarDensity: 'Densidade',
        toolbarDensityLabel: 'Densidade',
        toolbarDensityCompact: 'Compacto',
        toolbarDensityStandard: 'Padrão',
        toolbarDensityComfortable: 'Confortável',
        toolbarColumns: 'Colunas',
        toolbarColumnsLabel: 'Exibir seletor de colunas',
        toolbarFilters: 'Filtros',
        toolbarFiltersLabel: 'Exibir filtros',
        toolbarExport: 'Exportar',
        toolbarExportLabel: 'Exportar',
        toolbarExportCSV: 'Exportar para CSV',
        columnsPanelTextFieldLabel: 'Buscar coluna',
        columnsPanelTextFieldPlaceholder: 'Título da coluna',
        columnsPanelDragIconLabel: 'Reordenar coluna',
        columnsPanelShowAllButton: 'Mostrar todas',
        columnsPanelHideAllButton: 'Ocultar todas',
        filterPanelAddFilter: 'Adicionar filtro',
        filterPanelRemoveAll: 'Remover todos',
        filterPanelDeleteIconLabel: 'Excluir',
        filterPanelOperators: 'Operadores',
        filterPanelOperatorAnd: 'E',
        filterPanelOperatorOr: 'OU',
        filterPanelColumns: 'Colunas',
        filterPanelInputLabel: 'Valor',
        filterPanelInputPlaceholder: 'Filtrar valor',
        filterOperatorContains: 'contém',
        filterOperatorEquals: 'igual a',
        filterOperatorStartsWith: 'começa com',
        filterOperatorEndsWith: 'termina com',
        filterOperatorIs: 'é',
        filterOperatorNot: 'não é',
        filterOperatorAfter: 'depois de',
        filterOperatorOnOrAfter: 'em ou depois de',
        filterOperatorBefore: 'antes de',
        filterOperatorOnOrBefore: 'em ou antes de',
        filterValueAny: 'qualquer',
        filterValueTrue: 'verdadeiro',
        filterValueFalse: 'falso',
        columnMenuLabel: 'Menu',
        columnMenuShowColumns: 'Mostrar colunas',
        columnMenuFilter: 'Filtrar',
        columnMenuHideColumn: 'Ocultar',
        columnMenuUnsort: 'Remover ordenação',
        columnMenuSortAsc: 'Ordenar do menor para o maior',
        columnMenuSortDesc: 'Ordenar do maior para o menor',
        footerRowSelected: (count) =>
          count !== 1 ? `${count.toLocaleString()} linhas selecionadas` : `${count.toLocaleString()} linha selecionada`,
        footerTotalRows: 'Total de linhas:',
        footerTotalVisibleRows: (visibleCount, totalCount) =>
          `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
        checkboxSelectionHeaderName: 'Seleção de caixa de seleção',
        booleanCellTrueLabel: 'sim',
        booleanCellFalseLabel: 'não',
        actionsCellMore: 'mais',
      }
  };


var login = localStorage.getItem('login');
var token = JSON.parse(login);
if (token) {
    var header = {
        'headers': {
            'Authorization': 'Bearer ' + token.access_token,
            'Content-Type': 'application/json'
        }
    }
}   

if (token) {
    globalState.value = header;
} else {
    globalState.value = "Não logado";
}


export default globalState;