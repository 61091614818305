import React, { useState, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import Api from '../../Api';
import Swal from 'sweetalert2';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import globalState from '../Global';
import {
    DataGrid,
    GridActionsCellItem,
} from '@mui/x-data-grid';



const Formas = forwardRef((props, ref) => {

    const useFakeMutation = () => {
        return React.useCallback(
            (user) =>
                new Promise((resolve, reject) => {
                    setTimeout(() => {
                        if (user.name?.trim() === '') {
                            reject(new Error("Error while saving user: name can't be empty."));
                        } else {
                            resolve({ ...user, name: user.name?.toUpperCase() });
                        }
                    }, 200);
                }),
            [],
        );
    };

    useImperativeHandle(ref, () => ({
        update() {
            getTabela(true);
        }
    }));

    const Sucesso = Swal.mixin({
        toast: true,
        // position: 'center',
        iconColor: 'green',
        customClass: {
            popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true
    });

    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

    const processRowUpdate = React.useCallback(

        async (newRow) => {
            const response_row = await Api.put('formas_pagamento/' + newRow.id, newRow, globalState.value);
            const response = await mutateRow(newRow);
            setSnackbar({ children: 'Item atualizado', severity: 'success' });
            return response;
        },
        //[mutateRow],
    );

    const handleProcessRowUpdateError = React.useCallback((error) => {
    }, []);

    const handleDeleteClick = (id) => () => {
        Swal.fire({
            title: 'Deseja excluir o item?',
            text: "",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.isConfirmed) {
                var response = Api.delete('formas_pagamento/' + id, globalState.value);
                var teste = data.filter((row) => row.id !== id);
                Sucesso.fire({
                    icon: 'success',
                    title: 'Item excluído'
                });
                setData(teste);
            }

        })
    };

    const mutateRow = useFakeMutation();
    const [rows, setRows] = React.useState();
    const [snackbar, setSnackbar] = React.useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);

    const column = [
        { field: 'id', headerName: 'Cód', width: 70, editable: true },
        { field: 'nome', headerName: 'Nome', width: 300, editable: true },
        {
            field: 'actions1', type: 'actions', cellClassName: 'actions', headerName: 'Opções', width: 100, renderCell: (params) => {
                return [
                    <GridActionsCellItem
                        key={`delete-${params.row.id}`} 
                        icon={<DeleteIcon color="warning" />}
                        sx={{
                            color: 'primary.main',
                        }}
                        onClick={handleDeleteClick(params.row.id)}
                    />,
                ];
            }
        },
    ]
    //const mutateRow = useFakeMutation();

    const getTabela = async (test) => {
        if (test === true) {
            try {
                const response = await Api.get('formas_pagamento/', globalState.value);
                setData(response.data.dado);
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        getTabela(true);
    }, []);

    const [data, setData] = useState([]);

    return (
        <div style={{ height: '550px', width: '100%' }}>

            <DataGrid
                rowHeight={25}
                localeText={globalState.ptBRLocaleText}
                rows={data || []}
                columns={column}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
            />
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </div>
    )

});

export default Formas;

Formas.displayName = "Formas";