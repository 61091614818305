import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../Api';
import globalState from '../Global';

var tabName = "";
var user_id = 0;

async function delData(id, tabela, props) {
  const DeleteData = async (id) => {
    await Api.delete(`${tabela}/${id}`, globalState.value);
    await props.updateTable(tabela);
  };

  await Swal.fire({
    title: 'Deseja excluir?',
    text: "",
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não'
  }).then((result) => {
    if (result.isConfirmed) {
      DeleteData(id, tabela, props);
    }
  });
}

const Table = ({ data, column, tabela, props }) => {
  tabName = tabela;

  const doTeste = async (user_id, tab) => {
    props.setID(user_id);
    props.setTabela(tab);
    props.handleShowEdit();
  };

  return (
    <table className="blueTable">
      <thead className="text-center">
        <tr>
          {column.map((item, index) => (
            <TableHeadItem key={index} item={item} />
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <TableRow key={index} item={item} column={column} doTeste={doTeste} tab={tabName} props={props} />
        ))}
      </tbody>
    </table>
  );
};

const TableHeadItem = ({ item }) => <th>{item.heading}</th>;

const TableRow = ({ item, column, doTeste, tab, props }) => (
  <tr>
    {column.map((columnItem, index) => {
      if (columnItem.value.includes('.')) {
        const itemSplit = columnItem.value.split('.');
        return (
          <td key={index} className="text-center" onClick={() => doTeste(item[itemSplit[0]].id)}>
            {item[itemSplit[0]][itemSplit[1]]}
          </td>
        );
      }
      if (["data", "data_inicio", "data_fim"].includes(columnItem.value)) {
        var date_time = item[columnItem.value];
        var current = moment(date_time).utcOffset('-03:00').format('DD/MM/YYYY');
        return <td key={index} className="text-center">{current}</td>;
      } else if (["valor_total", "valor", "saldo"].includes(columnItem.value)) {
        var valor = item[columnItem.value].replace(".", ",");
        return <td key={index} className="text-center">{valor}</td>;
      } else if (columnItem.heading === "Editar") {
        return (
          <td key={index}>
            <center>
              <Button className="btn btn-sm btn-primary" onClick={() => doTeste(item[columnItem.value], tab)}>
                <i className="nav-icon fas fa-edit" />
              </Button>
            </center>
          </td>
        );
      } else if (columnItem.heading === "Excluir") {
        return (
          <td key={index}>
            <center>
              <Button className="btn btn-sm btn-danger" onClick={() => delData(item[columnItem.value], tab, props)}>
                <i className="nav-icon fas fa-trash" />
              </Button>
            </center>
          </td>
        );
      } else {
        return <td key={index} className="text-center">{item[columnItem.value]}</td>;
      }
    })}
  </tr>
);

// Adicionando PropTypes
Table.propTypes = {
  data: PropTypes.array.isRequired,
  column: PropTypes.array.isRequired,
  tabela: PropTypes.string.isRequired,
  props: PropTypes.shape({
    setID: PropTypes.func,
    setTabela: PropTypes.func,
    handleShowEdit: PropTypes.func,
    updateTable: PropTypes.func
  }).isRequired
};

TableHeadItem.propTypes = {
  item: PropTypes.shape({
    heading: PropTypes.string.isRequired
  }).isRequired
};

TableRow.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.array.isRequired,
  doTeste: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
  props: PropTypes.object.isRequired
};

export default Table;
Table.displayName = "Table";

