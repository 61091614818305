import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Swal from 'sweetalert2';
import { StyleSheet } from '@react-pdf/renderer';
import { DataGrid, GridCellModes } from '@mui/x-data-grid';
import {
} from '@mui/x-data-grid-generator';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import Button from '@mui/material/Button';
import PlusIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/PrintOutlined';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import Api from '../../Api';
import globalState from '../Global';

const useFakeMutation = () => {
  return React.useCallback(
    (user) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          if (user.name?.trim() === '') {
            reject(new Error("Error while saving user: name can't be empty."));
          } else {
            resolve({ ...user, name: user.name?.toUpperCase() });
          }
        }, 200);
      }),
    [],
  );
};


const Table = forwardRef((props, ref) => {

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      padding: 20,
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    header: {
      fontSize: 20,
      marginBottom: 10,
      textAlign: 'center',
    },
    table: {
      display: 'table',
      borderCollapse: 'collapse',
      width: 'auto',
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableColHeader: {
      width: '33.33%',
      borderWidth: 1,
      borderColor: '#000',
      borderStyle: 'solid',
      backgroundColor: '#f0f0f0',
      fontSize: 12,
      textAlign: 'center',
      padding: 5,
    },
    tableCol: {
      width: '33.33%',
      borderWidth: 1,
      borderColor: '#000',
      borderStyle: 'solid',
      fontSize: 10,
      textAlign: 'center',
      padding: 5,
    },
  });


  const baseURL = process.env.REACT_APP_baseURL + "/print/orcamento/"
  const [orcamentos, setOrcamentos] = useState([]);
  const [itemsOrcamentos, setItemsOrcamentos] = useState([]);

  const getOrcamentos = async (cod) => {
    const response = await Api.get('orcamentos/paciente/' + cod,globalState.value);
    setOrcamentos(response.data.tabela);
  };

  useEffect(() => {
    getOrcamentos(props.cod_paciente);
  }, []);

  useImperativeHandle(ref, () => ({
    update() {
      getOrcamentos(props.cod_paciente);
    }
  }));

  const handleDeleteClick = (id) => () => {
    Swal.fire({
      title: 'Deseja excluir o prontuário?',
      text: "",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        var teste = orcamentos.filter((row) => row.id !== id);
        var response = Api.delete('orcamentos/' + id,globalState.value);
        setOrcamentos(teste);

        Sucesso.fire({
          icon: 'success',
          title: 'Prontuario excluído'
        });
      }

    })
  };

  const currencyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const columns = React.useMemo(
    () => [
      { field: 'id', headerName: 'Cód', width: 50, editable: false },
      //{ field: 'nome', headerName: 'Nome', width: 180, editable: true },
      {
        field: 'valor_total',
        headerName: 'Valor Total',
        width: 150,
        type: 'number',
        editable: false,
        align: 'left',
        headerAlign: 'center',
        //valueFormatter: params => params?.value.toString().replace(".",","),
        valueFormatter: ({ value }) => {
          if (!value) {
            return value;
          }
          return currencyFormatter.format(value);
        },
      },
      {
        field: 'data',
        headerName: 'Data',
        width: 100,
        editable: false,
        align: 'center',
        headerAlign: 'center',
      },
      {
        field: 'estado',
        headerName: 'Estado',
        type: 'singleSelect',
        width: 150,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        //valueOptions: ["Criado", "Aguardando", "Aprovado"],
        valueOptions: ({ row }) => {
          const options = [];
          if (['Criado'].includes(row.estado)) {
            options.push('Criado', 'Aguardando', 'Aprovado');
          }
          if (['Aguardando'].includes(row.estado)) {
            options.push('Aguardando', 'Aprovado');
          }
          if (['Aprovado'].includes(row.estado)) {
            options.push('Aprovado', 'Executando');
          }
          if (['Executando'].includes(row.estado)) {
            options.push('Executando', 'Cancelado');
          }
          if (['Cancelado'].includes(row.estado)) {
            options.push('Cancelado');
          }
          return options;
        },
      },

      {
        field: 'actions2', type: 'actions', headerName: 'Opções', width: 120, renderCell: (params) => {
          return (

            <Box>

              <IconButton color="success"
                onClick={() => { props.setIDORC(params.id); props.getItemsOrcamento(params.id); props.setShowModalEditOrc(true) }}
                variant="contained"
              >
                {/* {cellMode === 'edit' ? 'Salvar' : 'Editar'} */}
                <FormatListNumberedIcon fontSize="small" />
              </IconButton>
              <IconButton color="error"
                onClick={handleDeleteClick(params.row.id)}
                variant="contained"
              >
                {/* {cellMode === 'edit' ? 'Salvar' : 'Editar'} */}
                <DeleteIcon fontSize="small" />
              </IconButton>
              <IconButton href={baseURL + (params.row.id)} color="primary" variant="contained">

                <PrintIcon fontSize="small" />
              </IconButton>

            </Box>
          );
        }
      },
    ]
  );

  const mutateRow = useFakeMutation();
  const [rows, setRows] = React.useState();
  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const Sucesso = Swal.mixin({
    toast: true,
    // position: 'center',
    iconColor: 'green',
    customClass: {
      popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true
  });

  const addTratamento = async (id_orc, id_trat) => {

    const response = await Api.get('itens_orcamento/orcamento/' + id_orc,globalState.value);
    var data = moment.utc()
      .utcOffset('-03:00')
      .format('DD/MM/YYYY');
    var block = "";

    setItemsOrcamentos(response.data.tabela);
  }

  const processRowUpdate = React.useCallback(

    async (newRow) => {

      // Make the HTTP request to save in the backend

      var data = moment.utc()
        .utcOffset('-03:00')
        .format('DD/MM/YYYY');

      const response_row = await Api.put('orcamentos/' + newRow.id, newRow,globalState.value);
      props.updateTratamentos(newRow.id);
      const response = await mutateRow(newRow);
      setSnackbar({ children: 'Orçamento atualizado', severity: 'success' });
      return response;
    },
    [mutateRow],
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: "ocorreu um erro", severity: 'error' });
  }, []);

  const EditToolbar = (props) => {

    const { selectedCellParams, cellMode, cellModesModel, setCellModesModel } = props;
    const handleClick = () => {

    };


    const handleSaveOrEdit = () => {
      if (!selectedCellParams) {
        return;
      }
      const { id, field } = selectedCellParams;
      if (cellMode === 'edit') {
        setCellModesModel({
          ...cellModesModel,
          [id]: { ...cellModesModel[id], [field]: { mode: GridCellModes.View } },
        });
      } else {
        setCellModesModel({
          ...cellModesModel,
          [id]: { ...cellModesModel[id], [field]: { mode: GridCellModes.Edit } },
        });
      }
    };

    const handleCancel = () => {
      if (!selectedCellParams) {
        return;
      }
      const { id, field } = selectedCellParams;
      setCellModesModel({
        ...cellModesModel,
        [id]: {
          ...cellModesModel[id],
          [field]: { mode: GridCellModes.View, ignoreModifications: true },
        },
      });
    };

    const handleMouseDown = (event) => {
      // Keep the focus in the cell
      event.preventDefault();
    };

    const handleAddRow = async () => {


      var data = moment.utc()
        .utcOffset('-03:00')
        .format('DD/MM/YYYY');

      try {
        let block = {
          "nome": "Novo orçamento",
          "cod_paciente": props.cod_paciente,
          "observacoes": "",
          "pk_clinica": 1,
          "estado": "Criado",
          "valor_total": "0.00",
          "data": data,
          "cod_tratamento": "0",
          "tipo": "1",
          "desconto": 0,
          "forma_pagamento": null
        };

        const response = await Api.post('orcamentos/', block, globalState.value);

        if (response.status === 200) {
          getOrcamentos(props.cod_paciente);
          Sucesso.fire({
            icon: 'success',
            title: 'Orçamento criado'
          });
        }
        if (response.status === 201) {
          Sucesso.fire({
            icon: 'warning',
            title: 'Registro já existe!!!'
          });
        }

      } catch (e) {
        console.error(e)
      }
    };


    return (
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          p: 1,
        }}
      >
        <Button variant="outlined" size="small" onClick={handleAddRow}
          onMouseDown={handleMouseDown} startIcon={<PlusIcon />}>
          Novo
        </Button>

      </Box>
    );
  }




  return (
    <div style={{ height: '450px', width: '100%' }}>
      <DataGrid
      sx={{
        '& .MuiDataGrid-cell': {
          fontSize: '0.75rem',  // Tamanho da fonte das células
        },
        '& .MuiDataGrid-columnHeaders': {
          fontSize: '0.75rem',  // Tamanho da fonte dos cabeçalhos
        },
      }}
        autoHeight
        rowHeight={25}
        localeText={globalState.ptBRLocaleText}
        rows={orcamentos}
        slots={{
          toolbar: EditToolbar,
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible
              status: false,
              traderName: false,
            },
            getOrcamentos,
          },
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            cod_paciente: props.cod_paciente
          },
          getOrcamentos,
        }}
        columns={columns}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );

  //}
});


export default Table;

Table.displayName = "Table";
