import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

// eslint-disable-next-line react/prop-types

const Contatos = (props) => {
    const [saved, setSaved] = useState(true);

    return (
        <div>
            <div className="card-body">
                <Box
                    // sx={{
                    //     display: 'flex',
                    //     alignItems: 'center',
                    //     '& > :not(style)': { m: 2 },
                    // }}
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                >
                    <div className="row">
                        <div>
                            
                            <TextField
                                multiline={true}
                                label="Celular"
                                id="celular"
                                name="celular"
                                fullWidth
                                defaultValue={props.paciente.celular}
                                size="small"
                                onChange={(e) => props.setPaciente({ ...props.paciente, celular: e.target.value })}
                            />
                            <TextField
                                 multiline={true}
                                 label="Telefone1"
                                 id="telefone1"
                                 name="telefone1"
                                 fullWidth
                                 size="small"
                                 defaultValue={props.paciente.telefone1}
                                 onChange={(e) => props.setPaciente({ ...props.paciente, telefone1: e.target.value })}
                            />
                            <TextField
                                multiline={true}
                                label="Telefone2"
                                id="telefone2"
                                name="telefone2"
                                fullWidth
                                size="small"
                                defaultValue={props.paciente.telefone2}
                                onChange={(e) => props.setPaciente({ ...props.paciente, telefone2: e.target.value })}
                            />
                        </div>
                    </div>
                </Box>
            </div>
            <div className="card-footer">
            <button type="submit" className="btn btn-danger" onClick={() => props.delUser(props.paciente.id)}>Excluir</button>

<button className="btn btn-primary" onClick={() => props.savePaciente(props.paciente.id)}>Salvar</button>
            </div>
        </div>

    )
};

export default Contatos;