import { useState } from "react";
import React from 'react';
import Swal from 'sweetalert2';
import Api from '../Api';
import { useNavigate } from 'react-router-dom';

export default function Home() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [userAnswer, setUserAnswer] = useState(""); // Resposta do usuário ao desafio
    const [captchaQuestion, setCaptchaQuestion] = useState(generateCaptcha()); // Questão do captcha
    const navigate = useNavigate();

    const Falha = Swal.mixin({
        toast: true,
        position: 'center',
        iconColor: 'red',
        customClass: {
            popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 4500,
        timerProgressBar: true
    });

    const handleSubmit = async event => {
        event.preventDefault();

        // Verificação do Captcha
        const correctAnswer = captchaQuestion.answer;
        if (parseInt(userAnswer) !== correctAnswer) {
            Falha.fire({
                icon: 'error',
                title: 'Captcha incorreto. Tente novamente.'
            });
            // Regenera o captcha após erro
            setCaptchaQuestion(generateCaptcha());
            setUserAnswer('');
            return;
        }

        var block = {
            "email": email,
            "password": password
        }

        try {
            var response = await Api.post('user/login', block);

            if (response.status === 200) {
                localStorage.setItem('login', JSON.stringify(response.data));
                navigate("/Dashboard");
                navigate(0);   
            } 
            
            if (response.status === 201) {
                Falha.fire({
                    icon: 'error',
                    title: 'Usuário ou senha inválido'
                })
            }
        } catch (error) {
            Falha.fire({
                icon: 'error',
                title: 'Erro no servidor. Tente novamente mais tarde.'
            });
        }
    }

    // Função para gerar uma pergunta de captcha simples
    function generateCaptcha() {
        const num1 = Math.floor(Math.random() * 10) + 1; // Número de 1 a 10
        const num2 = Math.floor(Math.random() * 10) + 1; // Número de 1 a 10
        return {
            question: `Quanto é ${num1} + ${num2}?`,
            answer: num1 + num2
        }
    }

    return (
        <div>
            <div className="login-box"></div>
            <div className="row align-items-left">
                <div className="col-sm"></div>
                <div className="col-sm"></div>
                <div className="col-sm">
                    <div>
                        <div className="login-logo">
                            Condent
                        </div>
                        <div className="card">
                            <div className="card-body login-card-body">
                                <p className="login-box-msg">Entre com o email e senha</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="input-group mb-3">
                                        <input 
                                            type="email" 
                                            className="form-control" 
                                            id="email" 
                                            placeholder="Email" 
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-envelope" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="input-group mb-3">
                                        <input 
                                            type="password" 
                                            className="form-control" 
                                            id="password" 
                                            placeholder="Password" 
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-lock" />
                                            </div>
                                        </div>
                                    </div>

                                    {/* CAPTCHA */}
                                    <div className="input-group mb-3">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder={captchaQuestion.question} 
                                            value={userAnswer} 
                                            onChange={(e) => setUserAnswer(e.target.value)} 
                                        />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-robot" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-4">
                                            <button type="submit" className="btn btn-primary btn-block">Login</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4"></div>
                </div>
                <div className="col-sm"></div>
                <div className="col-sm"></div>
            </div>
            <pre></pre>
        </div>
    )
}
