import React, { useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Button from "@mui/material/Button";

// eslint-disable-next-line react/prop-types

const StepCinco= (props) => {
  

  useEffect(() => {
  }, [])

  return (
    <div className="card">
      <div className="card-body">
            <div className="row">
            <div className="modal-body">
            <div>
              Inserir foto do paciente tamanho X Y Pixel
            </div>
              <div>
                <FormControl fullWidth sx={{ m: 1 }} variant="standard"></FormControl>
                <Button variant="contained" component="label">
                  Foto
                  <input hidden accept="image/*" multiple type="file" onChange={e => props.setFile(e.target.files[0])}/>
                </Button>
              </div>
            </div>
            <div>
              <br></br>
            </div>
          </div>
      </div>
    </div >
  )
}

export default StepCinco;