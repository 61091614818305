//import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Swal from 'sweetalert2';
import { DataGrid, GridCellModes, GridToolbarContainer, GridToolbarExport, GridActionsCellItem } from '@mui/x-data-grid';
import PrintIcon from '@mui/icons-material/PrintOutlined';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Api from '../../Api';
import globalState from '../Global';

const useFakeMutation = () => {
  return React.useCallback(
    (user) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          if (user.name?.trim() === '') {
            reject(new Error("Error while saving user: name can't be empty."));
          } else {
            resolve({ ...user, name: user.name?.toUpperCase() });
          }
        }, 200);
      }),
    [],
  );
};

const Mensagem = Swal.mixin({
  toast: true,
  // position: 'center',
  iconColor: 'green',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true
});

const Table = forwardRef((props, ref) => {
  const baseURL = process.env.REACT_APP_baseURL + "/print/plano/"
  //const Table = ({ setShowModalEditTrat, setID, setIDTRAT, setItemsTratamento, cod_paciente, getItemsTratamento }) => {

  const handleViewEdit = async (row) => {
    const response = await Api.get('prontuario_tratamento/items/' + row, globalState.value);

    var data = moment.utc()
      .utcOffset('-03:00')
      .format('DD/MM/YYYY');

    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputLabel: 'Mensagem',
      inputPlaceholder: 'Digite aqui sua mensagem',
      inputValue: response.data.tabela[0].descricao,
      width: 600,
      height: 400,
      cancelButtonText: 'Cancela',
      confirmButtonText: 'Salvar',
      inputAttributes: {
        'aria-label': 'Digite aqui sua mensagem'
      },
      showCancelButton: true
    })


    if (text) {
      let block = {
        "descricao": text,
        "cod_tratamento": row,
        "data": data
      };
      const response1 = await Api.put('prontuario_tratamento/' + response.data.tabela[0].id, block, globalState.value);
    }
  };

  //const ref_prontuarios = useRef();  
  const [tratamentos, setTratamentos] = useState([]);

  const getTratamentos = async (cod) => {
    const response = await Api.get('tratamentos/paciente/' + cod, globalState.value);
    setTratamentos(response.data.tabela);
  };

  useEffect(() => {
    getTratamentos(props.cod_paciente);
  }, []);

  useImperativeHandle(ref, () => ({
    update() {
      getTratamentos(props.cod_paciente);
    }
  }));

  const handleDeleteClick = (id) => () => {

    Swal.fire({
      title: 'Deseja excluir o tratamento?',
      text: "",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        var teste = tratamentos.filter((row) => row.id !== id);
        var response1 = Api.delete('tratamentos/' + id, globalState.value);
        var response2 = Api.delete('prontuario_tratamento/tratamento/' + id, globalState.value);

        setTratamentos(teste);
        Sucesso.fire({
          icon: 'success',
          title: 'Tratamento excluído'
        });
      }

    })
  };

  const currencyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const columns = [
    { field: 'id', headerName: 'Cód', width: 20, editable: false },
    //{ field: 'descricao', headerName: 'Descrição', width: 150, editable: true, resizable: false },
    {
      field: 'valor_total',
      headerName: 'Valor Total',
      type: 'number',
      editable: false,
      width: 120,
      align: 'left',
      headerAlign: 'center',
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return currencyFormatter.format(value);
      },
    },
    {
      field: 'valor_pago',
      headerName: 'Valor Pago',
      type: 'number',
      width: 120,
      editable: false,
      align: 'left',
      headerAlign: 'center',
      valueFormatter: ({ value }) => {
        if (!value) {
          if (value == null) {
            value = "R$ 0,00"
          }
          return value;

        }
        return currencyFormatter.format(value);
      },
    },
    {
      field: 'saldo',
      headerName: 'Saldo',
      type: 'number',
      editable: false,
      align: 'left',
      width: 120,
      headerAlign: 'center',
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return currencyFormatter.format(value);
      },
    },
    {
      field: 'data_inicio',
      headerName: 'Inicio',
      width: 90,
      editable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'data_fim',
      headerName: 'Fim',
      width: 90,
      editable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'actions1',  type: 'actions', cellClassName: 'actions', headerName: 'Opções', width: 100, renderCell: (params) => {
        return [
          <GridActionsCellItem
          key={`attch-${params.row.id}`} 
          icon={<AttachMoneyIcon  color="warning"/>}
          sx={{
            color: 'primary.main',
          }}
          onClick={() => { props.setIDTRAT(params.id); props.setTotal(params.row.valor_total); props.getItemsPagamento(params.id); props.setShowModalPlanoPagamento(true) }}
        />,
        <GridActionsCellItem
            key={`print-${params.row.id}`} 
            icon={<PrintIcon color="normal"/>}
            sx={{
              color: 'primary.main',
            }}
            href={baseURL + params.row.id}
          />
        ];
      }
    },
  ];


  const mutateRow = useFakeMutation();
  const [rows, setRows] = React.useState();
  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const Sucesso = Swal.mixin({
    toast: true,
    // position: 'center',
    iconColor: 'green',
    customClass: {
      popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true
  });

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      // Make the HTTP request to save in the backend
      const response_row = await Api.put('tratamentos/' + newRow.id, newRow, globalState.value);
      const response = await mutateRow(newRow);
      setSnackbar({ children: 'Tratamento atualizado', severity: 'success' });
      return response;
    },
    [mutateRow],
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: "ocorreu um erro", severity: 'error' });
  }, []);

  const EditToolbar = (props) => {

    const { selectedCellParams, cellMode, cellModesModel, setCellModesModel } = props;
    const handleClick = () => {

    };


    const handleSaveOrEdit = () => {
      if (!selectedCellParams) {
        return;
      }
      const { id, field } = selectedCellParams;
      if (cellMode === 'edit') {
        setCellModesModel({
          ...cellModesModel,
          [id]: { ...cellModesModel[id], [field]: { mode: GridCellModes.View } },
        });
      } else {
        setCellModesModel({
          ...cellModesModel,
          [id]: { ...cellModesModel[id], [field]: { mode: GridCellModes.Edit } },
        });
      }
    };

    const handleCancel = () => {
      if (!selectedCellParams) {
        return;
      }
      const { id, field } = selectedCellParams;
      setCellModesModel({
        ...cellModesModel,
        [id]: {
          ...cellModesModel[id],
          [field]: { mode: GridCellModes.View, ignoreModifications: true },
        },
      });
    };

    const handleMouseDown = (event) => {
      // Keep the focus in the cell
      event.preventDefault();
    };

    const handleAddRow = async () => {
      var data = moment.utc()
        .utcOffset('-03:00')
        .format('DD/MM/YYYY');

      try {
        let block = {
          "descricao": "Novo tratamento",
          "paciente": props.cod_paciente,
          "forma_pagamento": "9",
          "situacao": "Inicio",
          "tipo": "1",
          "profissional": "1",
          "data_inicio": data,
          "data_fim": "",
          "cod_unidade": "1",
          "cod_convenio": "1",
          "pk_clinica": "1",
          "valor_total": 0,
          "cod_orcamento": "",
          "desconto": 0,
          "valor_pago": 0,
          "saldo": 0,
          "faturar": 0
        };
        const response1 = await Api.post('tratamentos/', block, globalState.value);

        if (response1.status === 200) {
          let block2 = {
            "descricao": "",
            "cod_tratamento": response1.data.tabela.id,
            "data": data
          };

          const response2 = await Api.post('prontuario_tratamento/', block2, globalState.value);

          getTratamentos(props.cod_paciente);
          Sucesso.fire({
            icon: 'success',
            title: 'Tratamento criado'
          });
        }
        if (response1.status === 201) {
          Sucesso.fire({
            icon: 'warning',
            title: 'Registro já existe!!!'
          });
        }

      } catch (e) {
        console.error(e)
      }
    };


    return (
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          p: 1,
        }}
      >
        Total :
      </Box>
    );
  }

  

  // const CustomToolbar = (props) => {
  //   const { components } = props;
  //   return (
  //     <GridToolbar {...props}>
  //       {components.filter((component) => component.type !== 'Export') /* Remove o botão de exportação */}
  //     </GridToolbar>
  //   );
  // };


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          printOptions={{
            hideFooter: true,
            hideToolbar: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: '450px', width: '100%' }}>
      <DataGrid
       sx={{
        '& .MuiDataGrid-cell': {
          fontSize: '0.75rem',  // Tamanho da fonte das células
        },
        '& .MuiDataGrid-columnHeaders': {
          fontSize: '0.75rem',  // Tamanho da fonte dos cabeçalhos
        },
      }}
        autoHeight
        rowHeight={25}
        localeText={globalState.ptBRLocaleText}
        rows={tratamentos || []}
        // slots={{
        //   toolbar: GridToolbar,
        // }}
        componentsProps={{
          toolbar: {
            export: {
              csv: false,
              excel: false,
              pdf: false,
            },
          },
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible
              status: false,
              traderName: false,
            },
            //getTratamentos,
            //props.cod_paciente,
          },
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,

            quickFilterProps: { debounceMs: 500 },

          },
          //getTratamentos,
          //cod_paciente
        }}
        columns={columns}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
  //}

});

export default Table;

Table.displayName = "Table";

Table.displayName = "Table";