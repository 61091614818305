import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import 'bootstrap/dist/css/bootstrap.min.css';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Api from '../../Api';
import Swal from 'sweetalert2';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import globalState from '../Global';

const useFakeMutation = () => {
  return React.useCallback(
    (user) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          if (user.name?.trim() === '') {
            reject(new Error("Error while saving user: name can't be empty."));
          } else {
            resolve({ ...user, name: user.name?.toUpperCase() });
          }
        }, 200);
      }),
    [],
  );
};

const Modal1 = props => {
  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const closeOnEscapeKeyDown = e => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  const rmItem = async (id) => {
    var response = await Api.delete('itens_orcamento/' + id);
    await props.getItemsOrcamento(props.idorc)
  }

  const Mensagem = Swal.mixin({
    toast: true,
    // position: 'center',
    iconColor: 'green',
    customClass: {
      popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true
  });

  const Erro = Swal.mixin({
    toast: true,
    // position: 'center',
    iconColor: 'red',
    customClass: {
      popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true
  });

  function EditToolbar(props) {
    const handleAddRow = async (idorc) => {
      const { value: formValues } = await Swal.fire({
        html:
          '<label for="swal-input1">Item   ' +
          '<input id="swal-input1" class="swal2-input">' +
          '</label>' +
          '<label for="swal-input2">Valor' +
          '<input id="swal-input2" class="swal2-input" data-type="currency" placeholder="R$ 1.000,00">' +
          '</label>',
        focusConfirm: false,
        preConfirm: () => {
          return [
            document.getElementById('swal-input1').value,
            document.getElementById('swal-input2').value
          ]
        }
      })

      if (formValues) {
        try {

          let block = {
            "descricao": formValues[0],
            "cod_orcamento": idorc,
            "valor": formValues[1].replace(",", ".")
          };

          const response = await Api.post('itens_orcamento/', block, globalState.value);

          if (response.status === 200) {
            props.props.getItemsOrcamento(idorc);
            Mensagem.fire({
              icon: 'success',
              title: 'Item criado'
            });
          }

        } catch (e) {
          Erro.fire({
            icon: 'warning',
            title: 'Valor está errado'
          });
        }
      }
    };

    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => [...oldRows, { id, descricao: '', valor: '', isNew: true }]);

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'descricao' },
      }));
    };


    return (

      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          p: 1
        }}
      >
        <GridToolbarContainer props={props}>
          <div><b>Total do orçamento :</b> {props.props.total.total?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</div>
          <div>
            <Button color="primary" startIcon={<AddIcon />} onClick={() => {
              handleAddRow(props.idorc);
            }}>
            </Button>
          </div>
        </GridToolbarContainer>
      </Box>

    );
  }

  const currencyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  //const [rows, setRows] = React.useState(props.itemsOrcamento?.tabela);
  const mutateRow = useFakeMutation();

  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const [rows, setRows] = React.useState();
  const [editRow, setEditRow] = React.useState({});
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStop = async (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
    await props.getItemsOrcamento(params.row.cod_orcamento);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id, rows) => () => {
    setRowModesModel({ ...rowModesModel, [editRow.id]: { mode: GridRowModes.View } });
  };

  const [status, setStatus] = React.useState('connected');

  const handleDeleteClick = (id) => () => {

    Swal.fire({
      title: 'Deseja excluir o item?',
      text: "",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        rmItem(id);
        setRows(rows.filter((row) => row.id !== id));

        Mensagem.fire({
          icon: 'success',
          title: 'Orçamento excluído'
        });

      }
    })


  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      var response_data = await Api.put('itens_orcamento/' + newRow.id, newRow, globalState.value);
      const response = await mutateRow(newRow);
      setSnackbar({ children: 'Registro atualizado!', severity: 'success' });
      await props.getItemsOrcamento(newRow.cod_orcamento);
      return response;
    },

    [mutateRow],
  );

  const mySaveOnServerFunction = () => {

  }
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { field: 'id', headerName: 'Código', width: 90 },
    {
      field: 'descricao',
      headerName: 'Descrição',
      width: 300,
      editable: true,
    },
    {
      field: 'valor',
      headerName: 'Valor',
      width: 200,
      editable: true,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return currencyFormatter.format(value);
      },
    },
    {
      field: 'actions1', headerName: 'Excluir', width: 100, renderCell: (params) => {
        return (

          <IconButton color="error"
            onClick={handleDeleteClick(params.row.id)}
            variant="contained"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        );
      }
    },
  ];




  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  let test = false;
  let idorc = props.idorc;

  if (props.itemsOrcamento.tabela?.length > 0) {
    test = true;
  }

  return ReactDOM.createPortal(
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div className="modal" onClick={props.onClose}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <div className="modal-header">
            <h4 className="modal-title">Itens do orçamento</h4>
            <button type="button" className="close" onClick={props.onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">

            {test === true &&
              <Box
                sx={{
                  height: '500px',
                  width: '100%',
                  '& .actions': {
                    color: 'text.secondary',
                  },
                  '& .textPrimary': {
                    color: 'text.primary',
                  },
                }}
              >
                <DataGrid
                  rowHeight={25}
                  rows={props.itemsOrcamento?.tabela}
                  columns={columns}
                  editMode="row"
                  //onCellEditCommit={handleRowEditCommit}
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  processRowUpdate={processRowUpdate}
                  onProcessRowUpdateError={handleProcessRowUpdateError}
                  slots={{
                    toolbar: EditToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                      idorc,
                      props,
                      setRows,
                      setRowModesModel,
                      editRow,
                    },
                    footer1: { status, props },
                  }}
                  localeText={globalState.ptBRLocaleText}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                />
                {!!snackbar && (
                  <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                  >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                  </Snackbar>
                )}
              </Box>

            }
            {test === false &&
              <Box
                sx={{
                  'height': '500px',
                  'width': '100%',
                  '& .actions': {
                    color: 'text.secondary',
                  },
                  '& .textPrimary': {
                    color: 'text.primary',
                  },
                }}
              >
                <DataGrid
                  rowHeight={25}
                  rows=""
                  columns={columns}
                  editMode="row"
                  //onCellEditCommit={handleRowEditCommit}
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  processRowUpdate={processRowUpdate}
                  onProcessRowUpdateError={handleProcessRowUpdateError}
                  slots={{
                    toolbar: EditToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                      idorc,
                      props,
                      setRows,
                      setRowModesModel,
                      editRow,
                    },
                    footer1: { status, props },
                  }}
                  localeText={globalState.ptBRLocaleText}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                />
                {!!snackbar && (
                  <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                  >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                  </Snackbar>
                )}
              </Box>
            }
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById("root")
  );
};

export default Modal1;